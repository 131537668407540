export enum Country {
  COL = "Colombia" as any,
  PER = "Peru" as any,
  MEX = "Mexico" as any,
  BRA = "Brasil" as any,
  ECU = "Ecuador" as any,
}

export const countryByDescription = (description: string): string => {
  switch (description) {
    case "Colombia":
      return "COL";
    case "Peru":
      return "PER";
    case "Mexico":
      return "MEX";
    case "Brasil":
      return "BRA";
    case "Ecuador":
      return "ECU";
    default:
      return "COL";
  }
};

export const getCountryKey = (country: Country): string => {
  return Country[country];
};

export const getCountryName = (country: string): string => {
  return Country[country as keyof typeof Country].toString();
};
