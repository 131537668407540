import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import {
  OrganolepticsResultRequest,
  OrganolepticsResultResponse,
  ProductionApprovementResult,
  ProductionLogSearchResponse,
  ProductionLogSearchType,
  ProductionPayload,
} from "core/productions/entities/Productions";
import { ProductionsHTTPRepository } from "core/productions/repositories/http/productions";
import { ArchiveProductionUseCase } from "core/productions/useCases/archiveProductionUseCase";
import { CreateOrganolepticsResultsUseCase } from "core/productions/useCases/createOrganolepticsResults";
import { CreateProduction } from "core/productions/useCases/createProduction";
import { CreateProductionLabelUseCase } from "core/productions/useCases/createProductionLabelUseCase";
import { FinishProduction } from "core/productions/useCases/finishProduction";
import { GetOrganolepticsResultsUseCase } from "core/productions/useCases/getOrganolepticReultsUseCase";
import { GetProductionById } from "core/productions/useCases/getProductionById";
import { GetProductionInventory } from "core/productions/useCases/getProductionInventory";
import { GetProductionLabelUseCase } from "core/productions/useCases/getProductionLabelUseCase";
import { GetProductionLogByTypeUseCase } from "core/productions/useCases/getProductionLogByType";
import { GetProductionsByKitchen } from "core/productions/useCases/getProductionsByKitchen";
import { GetProductionsCatalogByKitchen } from "core/productions/useCases/getProductionsCatalogByKitchen";
import { GetProductionsRecipe } from "core/productions/useCases/getProductionsRecipe";
import { GetUsersByKitchenIdUseCase } from "core/productions/useCases/getUsersByKitchenIdUseCase";
import { LiberateProduction } from "core/productions/useCases/liberateProduction";
import { SendProductionsPrintEventUseCase } from "core/productions/useCases/sendProductionsPrintEvent";
import { SetProductionDetailUsedQuantity } from "core/productions/useCases/setProductionDetailUsedQuantity";
import { StartProduction } from "core/productions/useCases/startProduction";
import {
  UpdateProductionUseCase,
  updateProductionManagerUseCase,
} from "core/productions/useCases/updateProductionUseCase";

export const getProductionsCatalogByKitchen = createAsyncThunk(
  "productions/getProductionsCatalogByKitchen",
  async (payload: string) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const getProductionsCatalogByKitchenUseCase =
      new GetProductionsCatalogByKitchen(httpRepository);

    const getProductionsByKitchenResult =
      await getProductionsCatalogByKitchenUseCase.execute(payload);

    return getProductionsByKitchenResult;
  }
);

export const getProductionInventory = createAsyncThunk(
  "productions/getProductionInventory",
  async (payload: { kitchenId: string; sku: string }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const getProductInventoryUseCase = new GetProductionInventory(
      httpRepository
    );

    const getProductionInventoryResult =
      await getProductInventoryUseCase.execute(payload.kitchenId, payload.sku);

    return getProductionInventoryResult;
  }
);

export const getProductionsByKitchen = createAsyncThunk(
  "productions/getProductionsByKitchen",
  async (payload: {
    kitchenId: string;
    status: "ACTIVES" | "TO_APPROVE" | "FINISHED";
  }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const getProductionsByKitchenUseCase = new GetProductionsByKitchen(
      httpRepository
    );

    const getProductionsByKitchen =
      await getProductionsByKitchenUseCase.execute(
        payload.kitchenId,
        payload.status
      );

    return getProductionsByKitchen;
  }
);

export const getProductionById = createAsyncThunk(
  "productions/getProductionById",
  async (payload: { productionId: string; kitchenId: string }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const getProductionByIdUseCase = new GetProductionById(httpRepository);

    const getProductionById = await getProductionByIdUseCase.execute(
      payload.productionId,
      payload.kitchenId
    );

    return getProductionById;
  }
);

export const setProductionDetailUsedQuantity = createAsyncThunk(
  "productions/setProductionDetailUsedQuantity",
  async (payload: { productionDetailId: string; usedQuantity: number }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const setProductionDetailUsedQuantityUseCase =
      new SetProductionDetailUsedQuantity(httpRepository);

    const setProductionDetailUsedQuantity =
      await setProductionDetailUsedQuantityUseCase.execute(
        payload.productionDetailId,
        payload.usedQuantity
      );

    return setProductionDetailUsedQuantity;
  }
);

export const startProduction = createAsyncThunk(
  "productions/startProduction",
  async (payload: { productionId: string }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const startProductionUseCase = new StartProduction(httpRepository);

    const startProduction = await startProductionUseCase.execute(
      payload.productionId
    );

    return startProduction;
  }
);

export const finishProduction = createAsyncThunk(
  "productions/finishProduction",
  async (payload: {
    productionId: string;
    producedQuantity: number;
    comment: string;
  }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const finishProductionUseCase = new FinishProduction(httpRepository);

    const finishProduction = await finishProductionUseCase.execute(
      payload.productionId,
      payload.producedQuantity,
      payload.comment
    );

    return finishProduction;
  }
);

export const liberateProduction = createAsyncThunk(
  "productions/liberateProduction",
  async (payload: {
    productionId: string;
    result: ProductionApprovementResult;
    novelty: string;
  }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const liberateProductionUseCase = new LiberateProduction(httpRepository);

    const liberateProduction = await liberateProductionUseCase.execute(
      payload.productionId,
      payload.result,
      payload.novelty
    );

    return liberateProduction;
  }
);

export const createProduction = createAsyncThunk(
  "production/createProduction",
  async (payload: { production: ProductionPayload }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const createProductionUseCase = new CreateProduction(httpRepository);

    const createProduction = createProductionUseCase.execute(
      payload.production
    );

    return createProduction;
  }
);

export const updateProduction = createAsyncThunk(
  "production/updateProduction",
  async (payload: { production: ProductionPayload; productionId: string }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const updateProductionUseCase = new UpdateProductionUseCase(httpRepository);

    const updateProduction = updateProductionUseCase.execute(
      payload.production,
      payload.productionId
    );

    return updateProduction;
  }
);

export const getProductionRecipe = createAsyncThunk(
  "productions/getProductionRecipe",
  async (payload: { productionId: string; kitchenId: string }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);

    const getRecipeUseCase = new GetProductionsRecipe(httpRepository);

    const result = await getRecipeUseCase.execute(
      payload.productionId,
      payload.kitchenId
    );

    return result;
  }
);

export const archiveProduction = createAsyncThunk(
  "production/archiveProduction",
  async (payload: { productionId: string; comment: string }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const archiveProductionUseCase = new ArchiveProductionUseCase(
      httpRepository
    );

    const archiveProduction = archiveProductionUseCase.execute(
      payload.productionId,
      payload.comment
    );

    return archiveProduction;
  }
);

export const getProductionLabels = createAsyncThunk(
  "production/getProductionLabels",
  async (payload: { productionId: string }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const getLabelsuseCase = new GetProductionLabelUseCase(httpRepository);

    const labels = getLabelsuseCase.execute(payload.productionId);

    return labels;
  }
);

export const getUsersByKitchenId = createAsyncThunk(
  "production/getUsersByKitchenId",
  async (payload: { kitchenId: string }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const useCase = new GetUsersByKitchenIdUseCase(httpRepository);

    const labels = useCase.execute(payload.kitchenId);

    return labels;
  }
);

export const updateProductionManager = createAsyncThunk(
  "production/updateProductionManager",
  async (payload: {
    productionId: string;
    userId: string;
    userName: string;
  }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const useCase = new updateProductionManagerUseCase(httpRepository);

    const response = useCase.execute(
      payload.productionId,
      payload.userId,
      payload.userName
    );

    return response;
  }
);

export const createProductionLabels = createAsyncThunk(
  "production/createProductionLabels",
  async (payload: {
    productionId: string;
    quantity: number;
    quantityOfPacking: number;
  }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const createLabelsUseCase = new CreateProductionLabelUseCase(
      httpRepository
    );

    const labels = createLabelsUseCase.execute(
      payload.productionId,
      payload.quantity,
      payload.quantityOfPacking
    );

    return labels;
  }
);

export const sendProductionPrintEvent = createAsyncThunk(
  "production/sendProductionPrintEvent",
  async (payload: { productionId: string }) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const sendPrintEventUseCase = new SendProductionsPrintEventUseCase(
      httpRepository
    );

    const labels = sendPrintEventUseCase.execute(payload.productionId);

    return labels;
  }
);

export const getProductionLogByType = createAsyncThunk(
  "production/log/by-type",
  async (payload: {
    type: ProductionLogSearchType;
    searchTerm: string;
  }): Promise<ProductionLogSearchResponse> => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const useCase = new GetProductionLogByTypeUseCase(httpRepository);
    const useCaseResult = await useCase.execute(
      payload.type,
      payload.searchTerm
    );
    return useCaseResult;
  }
);

export const createOrganolepticsResults = createAsyncThunk(
  "production/createOrganolepticsResults",
  async (payload: OrganolepticsResultRequest) => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const createOrganolepticsResultsUseCase =
      new CreateOrganolepticsResultsUseCase(httpRepository);

    const result = await createOrganolepticsResultsUseCase.execute(payload);

    return result;
  }
);

export const getOrganolepticsResults = createAsyncThunk(
  "production/getOrganolepticsResults",
  async (payload: {
    productionId: string;
  }): Promise<OrganolepticsResultResponse[]> => {
    const httpRepository = new ProductionsHTTPRepository(appConfig);
    const getOrganolepticsresultsUseCase = new GetOrganolepticsResultsUseCase(
      httpRepository
    );
    const result = await getOrganolepticsresultsUseCase.execute(
      payload.productionId
    );
    return result;
  }
);
