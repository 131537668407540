import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import {
  Box,
  Button,
  Chip,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { commons } from "app/i18n/types";
import { convertToBase64 } from "app/utils/file";
import { AttachmentFile } from "core/attachment/entity";
import {
  NewInvoicePayload,
  VendorInfo,
  XmlInvoice,
} from "core/purchaseOrders/entities/Invoice";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";

interface Props {
  xmlInvoice: XmlInvoice;
  vendorInfo: VendorInfo;
  loading: boolean;
  open: boolean;
  xml: AttachmentFile;
  onClose: () => void;
  saveInvoice: (payload: NewInvoicePayload) => void;
}
export const PurchaseOrderInvoiceImportDetail = (props: Props) => {
  const { t } = useTranslation();
  const { xmlInvoice, loading, open, onClose, vendorInfo, xml, saveInvoice } =
    props;
  const [pdf, setPdf] = useState<AttachmentFile | null>(null);

  const maxSizeInMb = 5;
  const maxSize = maxSizeInMb * 1024 * 1024;

  const onUploadPdf = async (files: File[] | null) => {
    setPdf(null);
    if (files && files.length >= 1) {
      const file = files[0];
      const { name, type } = file;
      const base64 = await convertToBase64(file);
      setPdf({ name, type, base64, verified: false });
    }
  };

  const onSave = () => {
    if (pdf) {
      const payload = {
        legalDocumentNo: xmlInvoice.legalDocumentNo,
        vendorCode: vendorInfo.code,
        invoice: xmlInvoice.invoice,
        issueDate: xmlInvoice.issueDate.substring(0, 10),
        subtotal: xmlInvoice.subtotal,
        tax: xmlInvoice.tax,
        items: xmlInvoice.items,
        notes: xmlInvoice.notes,
        xml: xml,
        pdf: pdf,
      } as NewInvoicePayload;
      saveInvoice(payload);
    }
  };

  return (
    <ManagerDialog
      title={"Nueva Invoices"}
      open={open}
      onClose={onClose}
      content={
        <Grid container>
          {loading && (
            <Grid item xs={12}>
              <LinearProgress></LinearProgress>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.CODE)}
            </Typography>
            <Typography>
              {xmlInvoice.legalDocumentNo.substring(0, 40).toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.VENDOR)}
            </Typography>
            <Typography>{vendorInfo?.name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.INVOICE)}
            </Typography>
            <Typography>{xmlInvoice.invoice}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.DATE)}
            </Typography>
            <Typography>{xmlInvoice.issueDate}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.SUBTOTAL)}
            </Typography>
            <Typography>
              {formatPrice(xmlInvoice.subtotal, localeData[xmlInvoice.country])}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.UPLOAD_FILE)}
            </Typography>
            {pdf ? (
              <Chip label={pdf.name} onDelete={() => setPdf(null)}></Chip>
            ) : (
              <Button
                variant="outlined"
                startIcon={<UploadFileOutlinedIcon />}
                component="label"
              >
                {t(commons.SELECT_FILE)}
                <input
                  hidden
                  accept={".pdf"}
                  type="file"
                  onChange={(e) => {
                    const {
                      target: { files },
                    } = e;
                    const isTooBig =
                      !!maxSizeInMb &&
                      !!files?.length &&
                      files[0].size > maxSize;
                    if (isTooBig) return;
                    onUploadPdf(Array.from(files ?? []));
                  }}
                />
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box py={2}>
              <Box key={`invoice-${xmlInvoice.legalDocumentNo}`}>
                <Typography
                  fontWeight={700}
                  component="div"
                  bgcolor="#eeeeee"
                  color="primary.main"
                  width="100%"
                  pl={1}
                  mb={1}
                >
                  {t(commons.ITEMS)}
                </Typography>

                <Box>
                  {xmlInvoice.items.map((item, i) => (
                    <Box
                      key={`itemdetail-${item.id}`}
                      display="flex"
                      justifyContent="space-between"
                      px={0.5}
                      alignItems="center"
                      bgcolor={i % 2 === 0 ? "background.paper" : "#eeeeee"}
                    >
                      <Typography fontSize={14}>{item.description}</Typography>
                      <Typography
                        fontSize={14}
                        sx={{ flexShrink: 0 }}
                        fontWeight={700}
                      >
                        {item.invoicedQuantity}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <ManagerDialogFooter
              onCancel={onClose}
              mainButton={{
                children: t(commons.SAVE),
                onClick: () => onSave(),
                disabled: loading || !pdf,
              }}
              loading={loading}
            />
          </Grid>
        </Grid>
      }
    />
  );
};
