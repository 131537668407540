export enum VolumeUnits {
  l = "L",
  ml = "ML",
}

export enum WeightUnits {
  kg = "KG",
  g = "G",
  gr = "GR",
  uni = "UNI",
}

export type MeasureUnits = `${VolumeUnits}` | `${WeightUnits}`;

export type ProductionDetail = {
  id: string;
  productionId: string;
  sku: string;
  name: string;
  quantity: number;
  requiredQuantity: number;
  usedQuantity: number | null;
  unit: string;
  displayUnit: string;
  inventoryQuantity: number;
  validation: ProductValidation | null;
};

export type ProductValidation = {
  severity: string;
  group: string;
};

export type ProductionStatus =
  | "SCHEDULED"
  | "IN_PROCESS"
  | "FINISHED"
  | "ARCHIVED"
  | "REJECTED"
  | "APPROVED_WITH_NOVELTY"
  | "APPROVED";

export enum ProductionApprovementResult {
  APPROVED = "APPROVED",
  APPROVED_WITH_NOVELTY = "APPROVED_WITH_NOVELTY",
  REJECTED = "REJECTED",
}
export interface Production {
  sku: string;
  name: string;
  quantityToProduce: number;
  status: ProductionStatus;
  locationId: string;
  unit: string;
  producedQuantity: number;
  productionDetails: Array<ProductionDetail>;
  productionManager?: string;
  startDate?: Date;
  type?: string;
  productionManagerName?: string;
  finishedAt?: Date;
}

export type ProductionDetailPayload = {
  sku: string;
  name: string;
  quantity: number;
  requiredQuantity: number;
  unit: string;
  displayUnit: string;
};

export type ProductionPayload = {
  sku: string;
  name: string;
  quantityToProduce: number;
  locationId: string;
  unit: string;
  productionDetails: Array<ProductionDetailPayload>;
  userId: string;
};

export interface ProductionLabel {
  id: number;
  quantity: number;
  unit: string;
  createdAt: string;
  name: string;
  batch: string;
  sku: string;
  productionDate: string;
  expirationDate: string;
}

export interface ProductionLabelToShow {
  lineId: number;
  labels: ProductionLabel[];
}

export interface ProductionRecord extends Production {
  id: string;
  createdAt: string;
  labels: ProductionLabel[];
  scheduledDate: string;
  timeSlot: string;
}

export type ProductionsResponse = {
  kitchenId: string;
  products: Array<ProductionRecord>;
};

export interface Organoleptics {
  appearance: string;
  color: string;
  smell: string;
  taste: string;
  texture: string;
  viscosity: string;
}

export interface OrganolepticResults {
  appearance: boolean | null;
  color: boolean | null;
  smell: boolean | null;
  taste: boolean | null;
  texture: boolean | null;
  viscosity: boolean | null;
}

export interface OrganolepticsResultRequest {
  productionId: string;
  type: string;
  description: string;
  result: boolean;
}

export interface OrganolepticsResultResponse {
  productionId: string;
  type: string;
  description: string;
  result: boolean;
}

export interface ProductionLabelResponse {
  id: number;
  sku: string;
  technicalName: string;
  productName: string;
  quantity: number;
  measureUnit: string;
  productionDate: string;
  expirationDate?: string;
  conservation: string;
  maxDuration: string;
  userName: string;
  userDocument: string;
  ingredients: string;
  batch: string;
  address: string;
  week: number;
  createdAt: string;
  finishedAt: string;
}

export interface ProductionLabelgroup {
  [key: string]: ProductionLabelResponse[];
}

export type ProductionScannedtResponse = {
  sum: number;
  quantity: number;
  ok: boolean;
  sku: string;
  error: string | null;
  reasonId: number;
  unit: string;
  productName: string;
  labelId: number;
};

export enum ProductionLogSearchTypeEnum {
  BY_LABEL_ID = "BY_LABEL_ID",
  BY_BATCH = "BY_BATCH",
}

export type ProductionLogSearchType = `${ProductionLogSearchTypeEnum}`;

export interface ProductionLabelLogResponse {
  productionLabelId: number;
  reason: string;
  reasonId: number;
  createdAt: string;
  kitchenId: string;
  kitchenName: string;
}

export interface ProductionLogSearchResponse {
  ok: boolean;
  label?: ProductionLabel | null;
  logs: ProductionLabelLogResponse[];
}

export const productionLogSearchDefault: ProductionLogSearchResponse = {
  ok: false,
  label: null,
  logs: [],
};
