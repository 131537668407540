import { CancelOutlined, TaskAlt } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import GreenSwitch from "app/components/GreenSwitch";
import { useAlert } from "app/hooks/useAlert";
import {
  commons,
  dailyAdjustmentsModule,
  replacementModule,
} from "app/i18n/types";
import { Product as CatalogProduct } from "core/dailyAdjustments/entities/Catalog";
import {
  ProductReplacement,
  ProductToReplaceCreate,
} from "core/replacement/entities/Replacement";
import {
  getProductReplacements,
  registerProductReplacement,
} from "core/replacement/repositories/replacement";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ValidationError } from "yup";
import {
  BaseDialogProps,
  FormState,
  getValidationData,
  handleValidations,
  refinedCatalogProducts,
  refinedProductReplacements,
} from "./constants";
import { ProductQuantityAndUnit } from "./ProductQuantityAndUnit";

interface Props extends BaseDialogProps {
  products: CatalogProduct[];
  finishedProducts: CatalogProduct[];
}

export const HandleForm = (props: Props) => {
  const { onClose, onSuccess, products, finishedProducts, kitchenId } = props;
  const { t } = useTranslation();
  const alert = useAlert();

  const [formState, setFormState] = useState<FormState>({
    kitchenId,
  } as FormState);

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [replacementKey, setReplacementKey] = useState<string>("");
  const [replacements, setReplacements] = useState<ProductReplacement[]>([]);
  const [autocompleteSupplyKey, setAutocompleteSupplyKey] = useState<number>(
    new Date().getTime()
  );
  const [showFinishedProducts, setShowFinishedProducts] =
    useState<boolean>(false);

  const getProductReplacement = (country: string, sku: string) => {
    if (!country || !sku) return;
    getProductReplacements(country, sku).then((response) => {
      setReplacements(response);
    });
  };

  useEffect(() => {
    if (!replacementKey) return;
    getProductReplacement(formState?.country ?? "", formState?.sku ?? "");
  }, [replacementKey]);

  useEffect(() => {
    setErrors({});
    handleValidations
      .validate(getValidationData(formState), { abortEarly: false })
      .catch((error: ValidationError) => {
        let modifiedErrors: Record<string, boolean> = {};
        error.inner.forEach((errorDetail) => {
          if (errorDetail.path) modifiedErrors[errorDetail.path] = true;
        });
        setErrors(modifiedErrors);
      });

    if (formState?.sku && formState?.country) {
      setReplacementKey(`${formState.sku}-${formState.country}`);
    }
  }, [formState]);

  const getResponse = async (data: FormState) => {
    return await registerProductReplacement(
      formState as ProductToReplaceCreate
    );
  };

  const handleSubmit = useCallback(async () => {
    if (!handleValidations.isValidSync(getValidationData(formState))) {
      return;
    }
    setIsLoading(true);
    const data = formState as ProductToReplaceCreate;

    const response = await getResponse(data);

    const title = t(`common.${response.message}`);
    if (response.ok) {
      alert.successWithMsg({ title });
      response.data && onSuccess(response.data);
      onClose();
    } else {
      alert.errorWithMsg({ title });
    }

    setIsLoading(false);
  }, [formState]);

  const handleIcon = (isError: boolean) => {
    if (isError) {
      return <CancelOutlined color="warning" />;
    }

    return <TaskAlt color="success" />;
  };

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography>
            {t(dailyAdjustmentsModule.SHOW_FINISHED_PRODUCTS)}
          </Typography>
          <GreenSwitch
            value={showFinishedProducts}
            onChange={() => {
              setShowFinishedProducts(!showFinishedProducts);
              setFormState({ kitchenId });
              setAutocompleteSupplyKey(new Date().getTime());
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          key={autocompleteSupplyKey}
          options={refinedCatalogProducts(
            showFinishedProducts ? finishedProducts : products
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t(commons.SEEKER)} ${t(commons.OF)} ${t(
                showFinishedProducts
                  ? commons.FINISHED_PRODUCTS
                  : commons.SUPPLIES
              ).toLowerCase()}`}
            />
          )}
          onChange={(_, newValue, __) => {
            setReplacements([]);
            if (!newValue) {
              setFormState({ kitchenId });
              return;
            }

            const product: CatalogProduct = newValue.value;

            setFormState((prev) => ({
              ...prev,
              sku: product.sku,
              quantity: 0,
              name: product.name,
              unit: product.unit,
              country: product.country,
              toReplace: undefined,
            }));
          }}
          forcePopupIcon={!!handleIcon(errors["sku"])}
          popupIcon={handleIcon(errors["sku"])}
        />
      </Grid>

      {!!formState?.sku && (
        <>
          <ProductQuantityAndUnit
            label={replacementModule.QUANTITY_INITIAL}
            quantity={formState?.quantity ?? 0}
            unit={formState?.unit ?? ""}
            endAdornment={handleIcon(errors["quantity"])}
            onChangeQuantity={(newQuantity) => {
              let replacementQuantity = formState.toReplace?.quantity ?? 0;
              if (formState.unit === formState.toReplace?.unit) {
                replacementQuantity = newQuantity;
              }
              const toReplace = formState.toReplace
                ? {
                    ...formState.toReplace,
                    quantity: replacementQuantity,
                  }
                : undefined;

              setFormState((prev) => ({
                ...prev,
                quantity: newQuantity,
                toReplace,
              }));
            }}
          />

          <Grid item xs={12}>
            <Autocomplete
              key={`AutocompleteReplacement${formState.sku}`}
              options={refinedProductReplacements(replacements)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t(commons.SEEKER)} ${t(commons.OF)} ${t(
                    replacementModule.REPLACE
                  ).toLowerCase()}`}
                />
              )}
              onChange={(_, newValue, __) => {
                if (!newValue) {
                  setFormState((prev) => ({
                    ...prev,
                    toReplace: undefined,
                  }));
                  return;
                }

                const product: ProductReplacement = newValue.value;

                let replacementQuantity = 0;
                if (formState.unit === product.unit) {
                  replacementQuantity = formState.quantity ?? 0;
                }

                setFormState((prev) => ({
                  ...prev,
                  toReplace: {
                    sku: product.sku,
                    name: product.name,
                    unit: product.unit,
                    quantity: replacementQuantity,
                  },
                }));
              }}
              forcePopupIcon={!!handleIcon(errors["replacementSku"])}
              popupIcon={handleIcon(errors["replacementSku"])}
            />
          </Grid>

          {!!formState.toReplace?.sku && (
            <ProductQuantityAndUnit
              label={replacementModule.QUANTITY_FINAL}
              quantity={formState?.toReplace?.quantity ?? 0}
              unit={formState?.toReplace?.unit ?? ""}
              endAdornment={handleIcon(errors["replacementQuantity"])}
              disabled={formState.unit === formState.toReplace?.unit}
              onChangeQuantity={(newQuantity) => {
                let quantity = formState.quantity ?? 0;
                if (formState.unit === formState.toReplace?.unit) {
                  quantity = newQuantity;
                }
                setFormState((prev) => ({
                  ...prev,
                  quantity,
                  toReplace: {
                    ...prev?.toReplace!!,
                    quantity: newQuantity,
                  },
                }));
              }}
            />
          )}
        </>
      )}

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={props.onClose}
          mainButton={{
            children: t(commons.SAVE),
            onClick: handleSubmit,
            disabled: isLoading || !!Object.keys(errors).length,
          }}
          loading={isLoading}
        />
      </Grid>
    </Grid>
  );
};
