import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import { commons, purchaseOrderModule } from "app/i18n/types";
import {
  Purchase,
  PurchaseOrderStatus,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { updateMultiplePurchaseOrderStatus } from "core/purchaseOrders/repositories/http/purchase";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  selectedItems: Purchase[];
  newStatus: string;
}

export const PurchaseOrderAccountChangeStatusDialog = (props: Props) => {
  const { onClose, onSuccess, selectedItems, newStatus } = props;

  const isChangeToPaid = newStatus === PurchaseOrderStatus.PAID;

  const { t } = useTranslation();
  const [payDate, setPayDate] = useState<string | null>(null);

  const handleConfirm = async () => {
    await updateMultiplePurchaseOrderStatus(
      selectedItems.map((i) => Number(i.orderId)),
      newStatus,
      payDate
    );
    onSuccess();
  };

  return (
    <CommonDialog
      open={true}
      handleClose={onClose}
      handleConfirm={handleConfirm}
      disableEscapeKeyDown={false}
      bodyElement={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {isChangeToPaid
                ? t(purchaseOrderModule.CHANGE_TO_PAID_DESCRIPTION)
                : t(purchaseOrderModule.CHANGE_TO_ACCOUNTED_DESCRIPTION)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {selectedItems.map((item) => (
              <Typography
                key={`PurchaseOrderAccountChangeStatusDialog${item.orderId}`}
              >
                {`${item.code} -- ${item.kitchenId}`}
              </Typography>
            ))}
          </Grid>
          {isChangeToPaid && (
            <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
              <TextField
                type="date"
                value={payDate}
                onChange={(e) => {
                  setPayDate(e.target.value);
                }}
                InputLabelProps={{ shrink: true }}
                label={t(commons.PAYMENT_DATE)}
                fullWidth
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setPayDate(null)}>
                      <CancelOutlinedIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          )}
        </Grid>
      }
      title={
        isChangeToPaid
          ? t(purchaseOrderModule.CHANGE_TO_PAID_TITLE)
          : t(purchaseOrderModule.CHANGE_TO_ACCOUNTED_TITLE)
      }
      disabledConfirmButton={isChangeToPaid && !payDate}
      showCancelButton
    />
  );
};
