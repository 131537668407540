import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { Button, ButtonProps, CircularProgress, Grid } from "@mui/material";
import { commons } from "app/i18n/types";
import { useTranslation } from "react-i18next";

interface Props {
  onCancel: () => void;
  mainButton?: ButtonProps;
  loading: boolean;
}

export const ManagerDialogFooter = (props: Props) => {
  const { onCancel, mainButton, loading } = props;
  const { t } = useTranslation();
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Button
          endIcon={<CancelOutlined />}
          color="error"
          type="button"
          onClick={onCancel}
          variant="outlined"
          fullWidth
        >
          {t(commons.CANCEL)}
        </Button>
      </Grid>

      {mainButton && (
        <Grid item xs={6}>
          <Button
            {...mainButton}
            endIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                mainButton.endIcon ?? <CheckCircleOutline />
              )
            }
            variant="contained"
            fullWidth
            disabled={mainButton.disabled ?? loading}
          />
        </Grid>
      )}
    </Grid>
  );
};
