import { Box, Grid, Typography } from "@mui/material";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { commons } from "app/i18n/types";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import PurchaseOrderStatusChip from "../../Status/Chip";

interface Props {
  open: boolean;
  purchase: Purchase;
  onClose: () => void;
}
export const PurchaseOrderInvoicePurchaseDetail = (props: Props) => {
  const { t } = useTranslation();
  const { open, onClose, purchase } = props;

  return (
    <ManagerDialog
      title={t(commons.PURCHASE_ORDER)}
      open={open}
      onClose={onClose}
      content={
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.CODE)}
            </Typography>
            <Typography>{purchase.code}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.KITCHEN)}
            </Typography>
            <Typography>{purchase.kitchenId}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.INVOICE)}
            </Typography>
            <Typography>{purchase.invoiceNo}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.DATE)}
            </Typography>
            <Typography>{purchase.dateDocument.toString()}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.SUBTOTAL)}
            </Typography>
            <Typography>
              {formatPrice(purchase.totalNet, localeData[purchase.country])}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {t(commons.STATUS)}
            </Typography>
            <Typography>
              <PurchaseOrderStatusChip purchase={purchase} />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box py={2}>
              <Box key={`invoice-${purchase.code}`}>
                <Typography
                  fontWeight={700}
                  component="div"
                  bgcolor="#eeeeee"
                  color="primary.main"
                  width="100%"
                  pl={1}
                  mb={1}
                >
                  {t(commons.ITEMS)}
                </Typography>

                <Box>
                  {purchase.lines?.map((item, i) => (
                    <Box
                      key={`itemdetail-${item.id}`}
                      display="flex"
                      justifyContent="space-between"
                      px={0.5}
                      alignItems="center"
                      bgcolor={i % 2 === 0 ? "background.paper" : "#eeeeee"}
                    >
                      <Typography fontSize={14}>{item.description}</Typography>
                      <Typography
                        fontSize={14}
                        sx={{ flexShrink: 0 }}
                        fontWeight={700}
                      >
                        {item.quantity} {item.unit}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <ManagerDialogFooter onCancel={onClose} loading={false} />
          </Grid>
        </Grid>
      }
    />
  );
};
