import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import { transferModule } from "app/i18n/types";
import { ProductToReplaceDTO } from "core/replacement/entities/Replacement";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { getCommonHeader, resultHeader } from "../../util";

interface Props {
  items: ProductToReplaceDTO[];
  selectedItems: KOSSelectedResult[];
  setSelectedItems: (selectedItems: KOSSelectedResult[]) => void;
  loading?: boolean;
}

const AurhorizeReplacementTable: FunctionComponent<Props> = (props) => {
  const { items, loading, selectedItems, setSelectedItems } = props;

  const { t } = useTranslation();

  const getHeader = (): KOSBaseTableHeader[] => [
    ...getCommonHeader(t),
    {
      label: t(transferModule.USER_CREATE),
      field: "createdBy",
    },
  ];

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{
        data: items,
        total: items.length ?? 0,
        loading: !!loading,
      }}
      pagination={{ enable: true }}
      selectable={{
        resultHeader,
        items: selectedItems,
        onChange: setSelectedItems,
      }}
    />
  );
};

export default AurhorizeReplacementTable;
