import { Grid, TextField, Typography } from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import { commons } from "app/i18n/types";
import { CommentTypeEnum } from "core/comment/entity";
import { createComment as createCommentRepository } from "core/comment/repository/http";
import {
  Purchase,
  PurchaseOrderStatus,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { updateStatusOfPurchaseOrder } from "core/purchaseOrders/repositories/http/purchase";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PurchaseOrderStatusSelector from "../Selector";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  order: Purchase;
}

export const PurchaseOrderChangeStatusDialog = (props: Props) => {
  const { onClose, onSuccess, order } = props;

  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [reason, setReason] = useState<string>();
  const [enableButton, setEnableButton] = useState<boolean>(false);

  const errorStatuses: string[] = [
    PurchaseOrderStatus.ERROR_IN_PRICE,
    PurchaseOrderStatus.ERROR_IN_QUANTITY,
    PurchaseOrderStatus.ERROR_IN_PRICE_AND_QUANTITY,
  ];

  const createComment = async (content: string | undefined) => {
    if (content) {
      await createCommentRepository({
        sourceId: order.orderId,
        type: CommentTypeEnum.PURCHASE_ORDER,
        kitchenId: order.kitchenId,
        content,
      });
    }
  };

  const handleConfirm = async () => {
    if (selectedStatus) {
      await updateStatusOfPurchaseOrder(order.orderId, selectedStatus);
      await createComment(reason);
      onSuccess();
    }
  };

  useEffect(() => {
    if (!selectedStatus) return;
    if (errorStatuses.includes(selectedStatus)) {
      setEnableButton(!!reason);
      return;
    }
    setEnableButton(true);
  }, [selectedStatus, reason]);

  return (
    <CommonDialog
      open={true}
      handleClose={onClose}
      handleConfirm={handleConfirm}
      bodyElement={
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t(commons.SELECT_A_NEW_STATUS)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PurchaseOrderStatusSelector
              selected={selectedStatus}
              setSelected={setSelectedStatus}
              disabled={!order}
              isErrorsOnly
              isValidated
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t(commons.REASON)}
              value={reason}
              onChange={(event) => {
                const newValue = event.target.value;
                setReason(newValue);
              }}
              autoComplete="off"
              multiline
              fullWidth
              maxRows={3}
            />
          </Grid>
        </Grid>
      }
      title={t(commons.CHANGE_STATUS)}
      disabledConfirmButton={!enableButton}
      showCancelButton
    />
  );
};
