import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { Grid } from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import PageHeader from "app/components/PageHeader/PageHeader";
import { ReplacementRegisterDialog } from "app/components/Replacement/Register";
import ReplacementTable from "app/components/Replacement/Table";
import { useAlert } from "app/hooks/useAlert";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, replacementModule } from "app/i18n/types";
import { getListProducts } from "app/store/slices/dailyAdjustments/thunks";
import { ProductToReplaceDTO } from "core/replacement/entities/Replacement";
import {
  archiveProductReplacement,
  getReplacementsByKitchen,
} from "core/replacement/repositories/replacement";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";

interface Props {}

const ReplacementPage: FunctionComponent<Props> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();

  const { t } = useTranslation();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const [items, setItems] = useState<ProductToReplaceDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [archiveDialog, setArchiveDialog] = useState<{
    id: number;
    message: string;
  }>();

  const getData = () => {
    if (!kitchenId) return;
    if (loading) return;
    setLoading(true);
    getReplacementsByKitchen(kitchenId)
      .then((response) => {
        setItems(response);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
    if (!kitchenId) return;
    dispatch(getListProducts({ kitchenId }));
  }, [kitchenId]);

  const archive = useCallback(() => {
    if (!archiveDialog) return;
    const { id } = archiveDialog;
    archiveProductReplacement({ id })
      .then((response) => {
        if (response.data) {
          alert.success();
          setItems(items.filter((item) => item.id !== id));
        } else {
          alert.error();
        }
      })
      .finally(() => setArchiveDialog(undefined));
  }, [archiveDialog, items]);

  return (
    <PageBox>
      <PageHeader
        title={`${t(commons.RECORD)} ${t(commons.OF)} ${t(
          replacementModule.TITLE
        ).toLowerCase()}`}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            color: "info",
            startIcon: <CachedOutlinedIcon />,
            onClick: getData,
          },
          {
            children: t(commons.ADD),
            variant: "contained",
            startIcon: <AddCircleOutlineOutlinedIcon />,
            onClick: () => setOpenDialog(true),
          },
        ]}
      />

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <ReplacementTable
            items={items}
            loading={loading}
            onArchive={(id, message) => {
              setArchiveDialog({ id, message });
            }}
          />
        </Grid>
      </Grid>

      {archiveDialog && (
        <CommonDialog
          open
          showCancelButton
          title={t(commons.CONFIRM)}
          message={`${t(commons.ARCHIVE)} ${t(
            replacementModule.REPLACE
          ).toLowerCase()}: ${archiveDialog.message}`}
          handleConfirm={() => archive()}
          handleClose={() => setArchiveDialog(undefined)}
        />
      )}

      {openDialog && (
        <ReplacementRegisterDialog
          kitchenId={kitchenId ?? ""}
          onClose={() => setOpenDialog(false)}
          onSuccess={(dto) => {
            setItems([dto, ...items]);
          }}
        />
      )}
    </PageBox>
  );
};

export default ReplacementPage;
