import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Box,
  Button,
  Chip,
  Grid,
  LinearProgress,
  List,
  ListItem,
} from "@mui/material";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { commons, purchaseOrderModule } from "app/i18n/types";
import { PurchaseInvoice } from "core/purchaseOrders/entities/Invoice";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import {
  byLegalDocument,
  purchasesByInvoiceInfo,
} from "core/purchaseOrders/repositories/http/purchase";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import { InvoiceItemsDialog } from "../ItemsDialog";
import { PurchaseOrderInvoiceLinkList } from "../LinkList";
import { InvoiceNotesDialog } from "../NotesDialog";
import { PurchaseOrderInvoicePurchaseDetail } from "../PurchaseDetail";

interface Props {
  invoices: PurchaseInvoice[];
  loading: boolean;
  selectedItems: KOSSelectedResult[];
  setSelectedItems: (selectedItems: KOSSelectedResult[]) => void;
  onUpdate: () => void;
}
export const PurchaseOrderInvoiceListTable = (props: Props) => {
  const { t } = useTranslation();
  const { invoices, loading, selectedItems, setSelectedItems, onUpdate } =
    props;

  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [purchase, setPurchase] = useState<Purchase | null>(null);

  const [loadingPurchases, setLoadingPurchases] = useState<boolean>(false);
  const [purchasesByVendor, setPurchasesByVendor] = useState<Purchase[]>([]);
  const [openLinkDialog, setOpenLinkDialog] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] =
    useState<PurchaseInvoice | null>(null);

  const [openNotesDialog, setOpenNotesDialog] = useState<boolean>(false);
  const [openItemsDialog, setOpenItemsDialog] = useState<boolean>(false);

  const getPurchases = (item: PurchaseInvoice) => {
    setLoadingPurchases(true);
    if (item.synchronized) {
      byLegalDocument(item.id)
        .then((resp) => {
          setOpenDetail(true);
          setPurchase(resp);
        })
        .finally(() => {
          setLoadingPurchases(false);
        });
    } else {
      purchasesByInvoiceInfo(item.vendorCode, item.issueDate, item.country)
        .then((resp) => {
          setPurchasesByVendor(resp);
          setOpenLinkDialog(true);
          setSelectedInvoice(item);
        })
        .finally(() => {
          setLoadingPurchases(false);
        });
    }
  };

  const openItems = (item: PurchaseInvoice) => {
    setOpenItemsDialog(true);
    setSelectedInvoice(item);
  };

  const openNotes = (item: PurchaseInvoice) => {
    setOpenNotesDialog(true);
    setSelectedInvoice(item);
  };

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    const actionButton = (item: KOSRowData) => (
      <Box>
        <Button
          color="error"
          onClick={() => openInNewTab(item.pdfUrl)}
          startIcon={<PictureAsPdfIcon />}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          {t(commons.PDF)}
        </Button>
        <Button
          color={item.synchronized ? "success" : "info"}
          onClick={() => getPurchases(item as PurchaseInvoice)}
          startIcon={<ListAltIcon />}
          variant="outlined"
        >
          {item.synchronized
            ? t(purchaseOrderModule.WATCH_ORDER)
            : t(purchaseOrderModule.LINK_ORDER)}
        </Button>
      </Box>
    );

    const openInNewTab = (url: string) => {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    };

    const itemsChip = (item: KOSRowData) => (
      <Chip
        label={item.items.length}
        onClick={() => openItems(item as PurchaseInvoice)}
      />
    );

    const notesChip = (item: KOSRowData) =>
      item.notes.length > 0 ? (
        <Chip
          label={item.notes.length}
          onClick={() => openNotes(item as PurchaseInvoice)}
        />
      ) : (
        <></>
      );

    return [
      {
        label: t(purchaseOrderModule.INVOICE_ID),
        text: (item: KOSRowData) => item.id.substring(0, 8).toUpperCase(),
      },
      {
        label: t(commons.DATE),
        text: (item: KOSRowData) => item.issueDate,
      },
      {
        label: t(commons.INVOICE),
        text: (item: KOSRowData) => item.invoice,
      },
      {
        label: t(commons.SUBTOTAL),
        text: (item: KOSRowData) =>
          formatPrice(item.subtotal, localeData[item.country]),
      },
      {
        label: t(purchaseOrderModule.VENDOR_CODE),
        text: (item: KOSRowData) => `(${item.vendorCode}) ${item.vendorName}`,
      },
      {
        label: t(commons.ITEMS),
        component: (item: KOSRowData) => itemsChip(item),
      },
      {
        label: t(purchaseOrderModule.NOTES),
        component: (item: KOSRowData) => notesChip(item),
      },
      {
        label: t(commons.ACTIONS),
        align: "center",
        component: actionButton,
      },
    ];
  }, []);

  return (
    <Box>
      {loadingPurchases && <LinearProgress></LinearProgress>}
      <KOSBaseTable
        columns={getHeader()}
        rows={{
          data: invoices,
          total: invoices.length ?? 0,
          loading: !!loading,
        }}
        pagination={{ enable: true }}
        selectable={{
          resultHeader: [
            "id",
            "pdf",
            "pdfUrl",
            "xml",
            "externalId",
            "invoice",
            "issueDate",
            "subtotal",
            "tax",
            "vendorCode",
            "items",
            "notes",
            "createdAt",
            "country",
            "bucketName",
            "folder",
            "synchronized",
            "synchronizedAt",
          ],
          items: selectedItems,
          onChange: setSelectedItems,
          canSelectAll: true,
        }}
      />
      {purchase && (
        <PurchaseOrderInvoicePurchaseDetail
          open={openDetail}
          onClose={() => setOpenDetail(false)}
          purchase={purchase}
        />
      )}
      {selectedInvoice && (
        <PurchaseOrderInvoiceLinkList
          purchases={purchasesByVendor}
          open={openLinkDialog}
          onClose={() => setOpenLinkDialog(false)}
          invoice={selectedInvoice}
          onUpdate={onUpdate}
        />
      )}
      {selectedInvoice && (
        <Box>
          <InvoiceNotesDialog
            open={openNotesDialog}
            onClose={() => setOpenNotesDialog(false)}
            invoice={selectedInvoice}
          />
          <InvoiceItemsDialog
            open={openItemsDialog}
            onClose={() => setOpenItemsDialog(false)}
            invoice={selectedInvoice}
          />
        </Box>
      )}

      <Box>
        <ManagerDialog
          title={selectedInvoice?.invoice ?? ""}
          open={openNotesDialog}
          onClose={() => setOpenNotesDialog(false)}
          size="xs"
          content={
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <List dense>
                  {selectedInvoice?.notes.map((note) => (
                    <ListItem key={note}>{note}</ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          }
          actions={
            <ManagerDialogFooter
              onCancel={() => setOpenNotesDialog(false)}
              loading={false}
            />
          }
        />
      </Box>
    </Box>
  );
};
