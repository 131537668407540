import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Chip } from "@mui/material";
import { commons } from "app/i18n/types";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  purchaseOrder: Purchase;
}

const WithInvoiceChip: FunctionComponent<Props> = (props) => {
  const { purchaseOrder } = props;
  const { t } = useTranslation();

  return purchaseOrder.legalDocumentNo ? (
    <Chip
      label={t(commons.YES)}
      icon={<CheckIcon />}
      color="success"
      variant="outlined"
      sx={{ fontWeight: "bold" }}
    />
  ) : (
    <Chip
      label={t(commons.NO)}
      icon={<CloseIcon />}
      color="warning"
      variant="outlined"
      sx={{ fontWeight: "bold" }}
    />
  );
};

export default WithInvoiceChip;
