import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoveToInboxOutlinedIcon from "@mui/icons-material/MoveToInboxOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PendingIcon from "@mui/icons-material/Pending";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import Chip from "@mui/material/Chip";
import {
  Purchase,
  PurchaseOrderStatus,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ColorType } from "utils/generalUI";

interface Props {
  purchase: Purchase;
}

const PurchaseOrderStatusChip = (props: Props): React.ReactElement => {
  const { purchase } = props;

  const { t } = useTranslation();

  const chipProps: { icon: ReactElement; color: ColorType } = {
    icon: <ReportGmailerrorredOutlinedIcon />,
    color: "error",
  };

  switch (purchase.status) {
    case PurchaseOrderStatus.PENDING:
      chipProps.icon = <PendingIcon />;
      chipProps.color = "warning";
      break;
    case PurchaseOrderStatus.WITHOUT_INVOICE:
      chipProps.icon = <NotificationsIcon />;
      chipProps.color = "default";
      break;
    case PurchaseOrderStatus.RECEIVED:
      chipProps.icon = <MoveToInboxOutlinedIcon />;
      chipProps.color = "info";
      break;
    case PurchaseOrderStatus.ACCOUNTED:
      chipProps.icon = <RequestQuoteOutlinedIcon />;
      chipProps.color = "secondary";
      break;
    case PurchaseOrderStatus.COMPLETE:
    case PurchaseOrderStatus.VALIDATED:
    case PurchaseOrderStatus.CORRECTED:
    case PurchaseOrderStatus.PRE_VALIDATED:
      chipProps.icon = <CheckCircleIcon />;
      chipProps.color = "success";
      break;
    case PurchaseOrderStatus.PARTIAL_RECEIVED:
    case PurchaseOrderStatus.PARTIAL:
      chipProps.color = "warning";
      break;
    case PurchaseOrderStatus.PAID:
      chipProps.icon = <PriceCheckOutlinedIcon />;
      chipProps.color = "primary";
      break;
    default:
      break;
  }

  return (
    <Chip
      icon={chipProps.icon}
      label={t(`commons.${purchase.status}`)}
      color={chipProps.color}
      variant="filled"
      sx={{ fontWeight: "bold" }}
    />
  );
};

export default PurchaseOrderStatusChip;
