import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { commons } from "app/i18n/types";
import { PurchaseOrderLine } from "core/purchaseOrders/entities/PurchaseOrder";
import { updatePurchaseOrderLineUnitPrice } from "core/purchaseOrders/repositories/http/purchase";
import { useTranslation } from "react-i18next";
import NumberField from "../common/Field/Number";

interface Props {
  line: PurchaseOrderLine;
  setUnitPrice?: (id: number, unitPrice: number) => void;
  isSmall?: boolean;
  autoSave?: boolean;
  isWarn?: boolean;
}

const UpdateUnitPriceTextField = (props: Props) => {
  const {
    line,
    setUnitPrice: setUnitPriceParam,
    isSmall,
    autoSave,
    isWarn,
  } = props;

  const { t } = useTranslation();

  const save = (lineId: number, unitPriceNumber: number) => {
    if (setUnitPriceParam) {
      setUnitPriceParam(lineId, unitPriceNumber);
    }
  };

  const updateUnitPrice = async (
    unitPriceNumber: number,
    line: PurchaseOrderLine
  ) => {
    if (unitPriceNumber === line.unitPrice) {
      return;
    }
    if (autoSave) {
      await updatePurchaseOrderLineUnitPrice(line.id, unitPriceNumber);
    }
    save(line.id, unitPriceNumber);
  };

  return (
    <NumberField
      value={line.unitPrice}
      size={isSmall ? "small" : "medium"}
      label={t(commons.UNIT_PRICE)}
      toFixedNumber={5}
      onChangeValue={(newValue) => {
        updateUnitPrice(newValue, line);
      }}
      endAdornment={
        <>{isWarn && <ErrorOutlineOutlinedIcon color="warning" />}</>
      }
    />
  );
};

export default UpdateUnitPriceTextField;
