import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton } from "@mui/material";
import { commons } from "app/i18n/types";
import { ProductToReplaceDTO } from "core/replacement/entities/Replacement";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { getCommonHeader } from "../util";

interface Props {
  items: ProductToReplaceDTO[];
  loading?: boolean;
  onArchive: (archiveId: number, message: string) => void;
}

const ReplacementTable: FunctionComponent<Props> = (props) => {
  const { items, loading, onArchive: onArchiveId } = props;

  const { t } = useTranslation();

  const actions = (item: KOSRowData) => {
    return (
      <IconButton
        onClick={() => {
          const a = `
            ${item.quantity} ${item.unit} 
            ${t(commons.OF)} 
            ${item.name} (${item.sku})
          `;
          const b = `
            ${item.replaceQuantity} ${item.replaceUnit} 
            ${t(commons.OF)} 
            ${item.replaceName} (${item.replaceSku})
          `;
          onArchiveId(item.id, `${a} -> ${b}`);
        }}
        color="error"
        size="small"
        sx={{ height: "100%" }}
      >
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    );
  };

  const getHeader = (): KOSBaseTableHeader[] => [
    ...getCommonHeader(t),
    {
      label: t(commons.ACTIONS),
      align: "center",
      component: actions,
    },
  ];

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{
        data: items,
        total: items.length ?? 0,
        loading: !!loading,
      }}
      pagination={{ enable: true }}
    />
  );
};

export default ReplacementTable;
