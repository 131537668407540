import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { commons } from "app/i18n/types";
import { PurchaseOrderStatus } from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  options?: string[];
  selected: string | undefined;
  setSelected: (selected?: string) => void;
  isErrorsOnly?: boolean;
  isValidated?: boolean;
  isCorrected?: boolean;
  disabled?: boolean;
}

const PurchaseOrderStatusSelector: FunctionComponent<Props> = (props) => {
  const {
    options: optionsProp,
    selected,
    setSelected,
    isErrorsOnly,
    isValidated,
    isCorrected,
    disabled,
  } = props;

  const { t } = useTranslation();
  const [reset, setReset] = useState<number>(new Date().getTime());

  const options: string[] = [
    PurchaseOrderStatus.ERROR_IN_PRICE,
    PurchaseOrderStatus.ERROR_IN_QUANTITY,
    PurchaseOrderStatus.ERROR_IN_PRICE_AND_QUANTITY,
    PurchaseOrderStatus.WITHOUT_INVOICE,
    PurchaseOrderStatus.OPS_REVIEW,
    PurchaseOrderStatus.PRE_VALIDATED,
  ];

  if (!isErrorsOnly) options.unshift(PurchaseOrderStatus.RECEIVED);
  if (isValidated) options.push(PurchaseOrderStatus.VALIDATED);
  if (isCorrected) options.push(PurchaseOrderStatus.CORRECTED);

  useEffect(() => {
    if (!selected) {
      setReset(new Date().getTime());
    }
  }, [selected]);

  return (
    <Autocomplete
      key={reset}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(commons.STATUS)}
          sx={{ bgcolor: "white" }}
        />
      )}
      value={selected}
      options={optionsProp ?? options}
      getOptionLabel={(option) => t(`commons.${option}`)}
      onChange={(_, value) => {
        setSelected(value ?? undefined);
      }}
      disabled={disabled}
    />
  );
};

export default PurchaseOrderStatusSelector;
