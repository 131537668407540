import { Box, ButtonGroup } from "@mui/material";
import Button from "@mui/material/Button";
import { commons } from "app/i18n/types";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onUpdate: (timeSlot: string) => void;
}

const TimeSlotFilter = (props: Props): React.ReactElement => {
  const { onUpdate } = props;
  const { t } = useTranslation();

  const all = t(commons.ALL);

  const options = [all, "AM", "M", "PM"];

  const [timeSlot, setTimeSlot] = React.useState(all);

  return (
    <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
      <ButtonGroup variant="contained">
        {options.map((option) => (
          <Button
            sx={{ width: "70px", borderRadius: "4px" }}
            key={option}
            onClick={() => {
              setTimeSlot(option);
              onUpdate(option);
            }}
            variant={timeSlot === option ? "contained" : "outlined"}
          >
            {option}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default TimeSlotFilter;
