export enum FdlgyCountry {
  COL = "COL",
  PER = "PER",
  MEX = "MEX",
  BRA = "BRA",
  ECU = "ECU",
}

export type FoodologyCountry =
  | FdlgyCountry.COL
  | FdlgyCountry.MEX
  | FdlgyCountry.PER
  | FdlgyCountry.BRA
  | FdlgyCountry.ECU;

export enum SupplyUnit {
  G = "G",
  GR = "GR",
  ML = "ML",
  UN = "UN",
}

export type Unit = `${SupplyUnit}`;

export interface InventoryAccuracyExceptions {
  skus: string[];
  groups: string[];
}

export interface InventoryAccuracyTolerance {
  unit: Unit;
  percentage: { min: number; max: number };
}

export interface InventoryAccuracyConfig {
  country: FoodologyCountry;
  exceptions: InventoryAccuracyExceptions;
  tolerance: InventoryAccuracyTolerance[];
}

export const toleranceDefault: InventoryAccuracyTolerance[] = [
  { unit: SupplyUnit.ML, percentage: { min: -10, max: 10 } },
  { unit: SupplyUnit.G, percentage: { min: -10, max: 10 } },
  { unit: SupplyUnit.GR, percentage: { min: -10, max: 10 } },
  { unit: SupplyUnit.UN, percentage: { min: -5, max: 5 } },
];

export const inventoryAccuracyConfig: InventoryAccuracyConfig[] = [
  {
    country: FdlgyCountry.COL,
    exceptions: {
      skus: ["463"],
      groups: [
        "EMPAQUES AJUSTABLES",
        "EMPAQUES_AJUSTABLES",
        "ADJUSTABLE_PACKAGING",
      ],
    },
    tolerance: toleranceDefault,
  },
  {
    country: FdlgyCountry.BRA,
    exceptions: {
      skus: ["117"],
      groups: [
        "EMPAQUES AJUSTABLES",
        "EMPAQUES_AJUSTABLES",
        "ADJUSTABLE_PACKAGING",
      ],
    },
    tolerance: toleranceDefault,
  },
  {
    country: FdlgyCountry.MEX,
    exceptions: {
      skus: ["1316"],
      groups: [
        "EMPAQUES AJUSTABLES",
        "EMPAQUES_AJUSTABLES",
        "ADJUSTABLE_PACKAGING",
      ],
    },
    tolerance: toleranceDefault,
  },
  {
    country: FdlgyCountry.PER,
    exceptions: {
      skus: ["179"],
      groups: [
        "EMPAQUES AJUSTABLES",
        "EMPAQUES_AJUSTABLES",
        "ADJUSTABLE_PACKAGING",
      ],
    },
    tolerance: toleranceDefault,
  },
];
