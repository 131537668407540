import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import { CheckCircleOutline } from "@mui/icons-material";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import { Alert, Button, LinearProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import UploadArea from "app/components/UploadArea";
import { bulkLoadAction, bulkLoadModule, commons } from "app/i18n/types";
import {
  BulkLoadAction,
  BulkLoadActionType,
  BulkLoadLineSave,
} from "core/bulkLoad/entities/BulkLoad";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { openUrl } from "utils/general";
import BulkLoadActionSelect from "./ActionSelect";
import BulkLoadError from "./Error";
import BulkLoadResult from "./Result";

interface Props {
  loading: boolean;
  responseHeaders: KOSBaseTableHeader[];
  responseLines: KOSRowData[] | undefined;
  responseSave: BulkLoadLineSave[] | undefined;
  templateUrl: string;
  onValidator: (files: File[] | null) => void;
  onSave: () => void;
  onChangeAction: (index: number, action: BulkLoadActionType) => void;
  onReset: () => void;
}

const BulkLoad = (props: Props) => {
  const {
    loading,
    onValidator,
    responseLines,
    responseHeaders,
    responseSave,
    templateUrl,
    onReset,
    onChangeAction,
    onSave,
  } = props;

  const { t } = useTranslation();

  const showTable = !!responseLines?.length;
  const saveCompleted = !!responseSave?.length;
  const allRowsWithError = responseLines?.every((el) => !!el.error);

  const getActionsText = (item: KOSRowData) => {
    if (saveCompleted) return t(`bulkLoadAction.${item.action}`);
    if (item.action === BulkLoadAction.NOTHING_BY_ERROR) {
      return t(bulkLoadAction.NOTHING_BY_ERROR);
    }
    return undefined;
  };

  const getActionsComponent = useCallback(
    (item: KOSRowData) => {
      if (saveCompleted) return undefined;
      if (item.action === BulkLoadAction.NOTHING_BY_ERROR) {
        return undefined;
      }
      return (
        <BulkLoadActionSelect
          index={item.index}
          action={item.action}
          onChangeAction={onChangeAction}
          disabled={loading}
        />
      );
    },
    [loading]
  );

  const getStatusComponent = (item: KOSRowData) => {
    return item.error ? (
      <BulkLoadError message={item.error} />
    ) : (
      <BulkLoadResult
        saveCompleted={saveCompleted}
        response={responseSave?.find((el) => el.index === item.index)}
      />
    );
  };

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    return [
      ...responseHeaders,
      {
        label: t(commons.ACTIONS),
        align: "center",
        text: getActionsText,
        component: getActionsComponent,
      },
      {
        label: t(commons.STATUS),
        align: "center",
        component: getStatusComponent,
      },
    ];
  }, [responseHeaders, responseSave, saveCompleted, loading]);

  return (
    <Grid container spacing={2}>
      {!showTable && (
        <>
          <Grid item xs={12}>
            <Button
              endIcon={<InsertLinkOutlinedIcon />}
              color="info"
              onClick={() => openUrl(templateUrl)}
              variant="outlined"
              fullWidth
            >
              {t(commons.TEMPLATE_VIEW)}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <UploadArea
              accept=".xlsx, .xls"
              onUpload={onValidator}
              label={t(commons.SELECT_TEMPLATE)}
              loading={loading}
              width="100%"
            />
          </Grid>
        </>
      )}
      {showTable && (
        <>
          <Grid item xs={12}>
            <Alert severity="info">{t(bulkLoadModule.TABLE_INFO)}</Alert>
          </Grid>

          <Grid item xs={saveCompleted || allRowsWithError ? 12 : 6}>
            <Button
              endIcon={<RestartAltOutlinedIcon />}
              color="error"
              onClick={onReset}
              variant="outlined"
              disabled={loading}
              fullWidth
            >
              {t(commons.RESET)}
            </Button>
          </Grid>

          {!saveCompleted && !allRowsWithError && (
            <Grid item xs={6}>
              <Button
                endIcon={<CheckCircleOutline />}
                variant="contained"
                disabled={loading}
                onClick={onSave}
                fullWidth
              >
                {t(commons.SAVE_CHANGES)}
              </Button>
            </Grid>
          )}

          {loading && (
            <Grid item xs={12}>
              <LinearProgress
                sx={{ height: 12, borderRadius: 2 }}
                color="info"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <KOSBaseTable
              columns={getHeader()}
              rows={{
                data: responseLines,
                total: responseLines.length ?? 0,
                showNumber: true,
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default BulkLoad;
