import { Box, Grid, List, ListItem } from "@mui/material";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { PurchaseInvoice } from "core/purchaseOrders/entities/Invoice";

interface Props {
  invoice: PurchaseInvoice;
  open: boolean;
  onClose: () => void;
}
export const InvoiceNotesDialog = (props: Props) => {
  const { open, invoice, onClose } = props;

  return (
    <Box>
      <ManagerDialog
        title={invoice?.invoice ?? ""}
        open={open}
        onClose={onClose}
        size="xs"
        content={
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
              }}
            >
              <List dense>
                {invoice?.notes.map((note) => (
                  <ListItem key={note}>{note}</ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        }
        actions={<ManagerDialogFooter onCancel={onClose} loading={false} />}
      />
    </Box>
  );
};
