/* eslint-disable no-template-curly-in-string */
import {
  EventReasonType,
  Locales,
  adjustmentType,
  adjustmentsModule,
  bcIntegrations,
  bulkLoadAction,
  bulkLoadError,
  bulkLoadModule,
  commentArea,
  commons,
  countingTypeSelector,
  dailyAdjustmentsModule,
  dailyAdjustmentsReport,
  errorCode,
  inventoryGroups,
  inventoryMovement,
  inventoryMovementType,
  kitchenInventoryManagement,
  massiveTransfer,
  measureUnits,
  missingReason,
  organoleptics,
  physicalCountModule,
  physicalCountSchedule,
  productionDialog,
  productionLiberation,
  productionLogReason,
  productionManagement,
  productionPreparation,
  productionScannedError,
  productionSchedule,
  purchaseOrderConciliation,
  purchaseOrderCreation,
  purchaseOrderLine,
  purchaseOrderModule,
  receiptType,
  replacementModule,
  status,
  suppliesManagerModule,
  supplyReception,
  supplyingManagement,
  takePhotoLocale,
  theoricalInventoryModule,
  transferModule,
  transferOrderDetails,
  transferOrderModule,
  transferOrdersPackedReviewTable,
  transferPackingDialog,
  vendor,
  vendorMeasureUnit,
} from "./types";

export const esLocale: Locales = {
  [status.IN_PROCESS]: "EN PROCESO",
  [status.ARCHIVED]: "ARCHIVADA",
  [status.RELEASED]: "LANZADA",
  [status.PACKED]: "EMPACADA",
  [status.IN_TRANSIT]: "EN TRANSITO",
  [status.RECEIVED]: "RECIBIDA",
  [status.RETURNED]: "DEVUELTA",
  [status.PARTIAL_RECEIVED]: "RECIBIDA PARCIAL",
  [status.ALL]: "TODOS",

  [inventoryGroups.BEER]: "CERVEZA",
  [inventoryGroups.ADJUSTABLE_PACKAGING]: "EMPAQUES AJUSTABLES",
  [inventoryGroups.ALCOHOLIC_DRINK]: "BEBIDAS ALCOHOLICAS",
  [inventoryGroups.BAKERY]: "PANADERIA",
  [inventoryGroups.CIGARETTES]: "CIGARRILLOS",
  [inventoryGroups.COMBO]: "COMBO",
  [inventoryGroups.DAIRY]: "LACTEOS",
  [inventoryGroups.DRINK]: "BEBIDAS",
  [inventoryGroups.FINISHED_PRODUCT]: "PRODUCTO TERMINADO",
  [inventoryGroups.FRUITS]: "FRUVER",
  [inventoryGroups.GROCERY]: "ABARROTES",
  [inventoryGroups.MAINTENANCE]: "MANTENIMIENTO",
  [inventoryGroups.MEAT]: "CARNICOS",
  [inventoryGroups.PACKAGING]: "EMPAQUES",
  [inventoryGroups.PRODUCTION]: "PRODUCCION",
  [inventoryGroups.SUPPLY]: "INSUMO",
  [inventoryGroups.UTENSILS]: "UTENSILIOS",

  [commons.DAILY_INDIVIDUAL]: "Diario individual",
  [commons.DAILY_MASSIVE]: "Diario masivo",
  [commons.DAILY]: "Diario",
  [commons.WEEKLY]: "Semanal",
  [commons.MONTHLY]: "Mensual",
  [commons.RE_COUNT]: "Reconteo",
  [commons.OTHER]: "Otro",
  [commons.OTHER_INDIVIDUAL]: "Otro individual",
  [commons.OTHER_MASSIVE]: "Otro masivo",
  [commons.LOADING]: "Cargando",
  [commons.ACCEPT]: "Aceptar",
  [commons.INVENTORY]: "Registro de inventario",
  [commons.PURCHASE_ORDER]: "Recepción de Órdenes",
  [commons.FILTERS]: "Filtros",
  [commons.RETURN]: "Regresar",
  [commons.RETURN2]: "Devolver",
  [commons.FINISH]: "Finalizar",
  [commons.START]: "Iniciar",
  [commons.FINISHED]: "Finalizado",
  [commons.NAME]: "Nombre",
  [commons.MEASURE_UNIT]: "Unidad de medida",
  [commons.SKU]: "SKU",
  [commons.STATUS]: "Estatus",
  [commons.GROUP]: "Grupo",
  [commons.DOES_NOT_APPLY]: "No aplica",
  [commons.DETAILS]: "Más detalles",
  [commons.DETAIL]: "Detalle",
  [commons.SHOW_DETAILS]: "Ver detalles",
  [commons.CATEGORY]: "Categoría",
  [commons.CATEGORIES]: "Categorías",
  [commons.CONTINUE]: "Continuar",
  [commons.RECEIVE]: "Recibir",
  [commons.PROGRESS]: "Progreso",
  [commons.DELIVERY_DATE]: "Fecha de entrega",
  [commons.ORDER_DATE]: "Fecha de orden",
  [commons.SUPPLIER]: "Proveedor",
  [commons.ORDER_ID]: "ID de orden",
  [commons.RECEIPT_ID]: "ID de Recepción",
  [commons.PRODUCT_NAME]: "Nombre de producto",
  [commons.REQUESTED]: "Solicitado",
  [commons.RECEIVED]: "Recibido",
  [commons.UNIT]: "Unidad",
  [commons.UNITS]: "Unidades",
  [commons.UNIT_PRICE]: "Precio unitario",
  [commons.TOTAL_PRICE]: "Precio total",
  [commons.ACTIONS]: "Acciones",
  [commons.ACTION]: "Acción",
  [commons.INIT_DATE]: "Fecha Inicio",
  [commons.FINAL_DATE]: "Fecha Fin",
  [commons.SEARCH]: "Buscar",
  [commons.EXPORT]: "Exportar",
  [commons.CANCEL]: "Cancelar",
  [commons.DELETE]: "Eliminar",
  [commons.MARK_RECEIVED]: "Orden Recibida",
  [commons.SAVE]: "Guardar",
  [commons.BACK]: "Volver",
  [commons.KITCHENS]: "Cocinas",
  [commons.KITCHEN]: "Cocina",
  [commons.COUNTRIES]: "Paises",
  [commons.TYPE]: "Tipo",
  [commons.SELECT_FILE]: "Seleccionar archivo",
  [commons.SELECT_TEMPLATE]: "Debes seleccionar la plantilla",
  [commons.SELECT_MOVE_FILE]: "Selecciona o arrastra el archivo",
  [commons.SELECT_MOVE_TEMPLATE]: "Selecciona o arrastra la plantilla",
  [commons.SELECTED_FILE]: "Archivo seleccionado",
  [commons.UPLOAD_FILE]: "Cargar archivo",
  [commons.UPLOAD_EVIDENCE]: "Cargar evidencia",
  [commons.MAKE_ADJUSTMENT]: "Realizar Ajuste",
  [commons.QUANTITY]: "Cantidad",
  [commons.LAST_MODIFIED]: "Ultima Modificación",
  [commons.PERSON_IN_CHARGE]: "Responsable",
  [commons.REASON]: "Motivo",
  [commons.RETRY]: "Reintentar",
  [commons.DATETIME]: "Fecha / Hora",
  [commons.AUTHORIZE]: "Autorizar",
  [commons.EMPTY_RESULT]: "No se encontraron resultados intente de nuevo",
  [commons.BACK_BUTTON]: "Atras",
  [commons.FIND_INPUT]: "Buscador de Insumos",
  [commons.FIND_LOCATION_TITLE]: "Buscador de Cocinas",
  [commons.FIND_LOCATION_INPUT]: "Buscar Cocina",
  [commons.CONFIRM]: "Confirmar",
  [commons.ENTER_QUANTITY]: "Ingresar cantidad",
  [commons.CONFIRMATION]: "CONFIRMAR",
  [commons.VALIDATE]: "VALIDAR",
  [commons.SHOW_ALL]: "MOSTRAR TODOS",
  [commons.ALL]: "TODOS",
  [commons.NEW_PRODUCTION]: "NUEVA PRODUCCIÓN",
  [commons.PRODUCTION_QUANTITY]: "Cantidad a Producir",
  [commons.INGREDIENT_NAME]: "NOMBRE DEL INGREDIENTE",
  [commons.REQUIRED_QUANTITY]: "CANTIDAD REQUERIDA",
  [commons.EXISTING_QUANTITY]: "CANTIDAD EXISTENTE",
  [commons.USED_QUANTITY]: "Cantidad utilizada",
  [commons.FINAL_QUANTITY]: "Cantidad Final",
  [commons.EXISTENCE]: "Existencia",
  [commons.ENTER]: "INGRESAR",
  [commons.UPDATE]: "Actualizar",
  [commons.SELECT_COUNTRY]: "Pais",
  [commons.SELECT_CITY]: "Ciudad",
  [commons.SELECT_LOCATION]: "Ubicación",
  [commons.SELECT_LOCATIONS]: "Seleccione la ubicación",
  [commons.INGREDIENTS]: "Ingredientes",
  [commons.SELECTED]: "Seleccionados",
  [commons.SCHEDULE_DATE]: "Fecha programada",
  [commons.VALIDATED]: "Validado",
  [commons.WITHOUT_INVOICE]: "Sin factura",
  [commons.ERROR_IN_PRICE]: "Error en precio",
  [commons.ERROR_IN_QUANTITY]: "Error en cantidad",
  [commons.ERROR_IN_PRICE_AND_QUANTITY]: "Error en cantidad y precio",
  [commons.ACCOUNTED]: "Contabilizada",
  [commons.PRE_VALIDATED]: "Pre validado",
  [commons.RECIVED_QUANTITY]: "Cantidad recibida",
  [commons.CORRECTED]: "Corregida",
  [commons.HOUR]: "Hora",
  [commons.TABLE_EMPTY]: "No hay registros que mostrar",
  [commons.PARTIAL]: "Parcial",
  [commons.PARTIAL_RECEIVED]: "Parcial",
  [commons.PENDING]: "Pendiente",
  [commons.COMPLETE]: "Completo",
  [commons.PARTIAL_PLURAL]: "Parciales",
  [commons.PENDING_PLURAL]: "Pendientes",
  [commons.COMPLETE_PLURAL]: "Completos",
  [commons.REMAINING]: "Restante",
  [commons.SENT]: "Enviado",
  [commons.SEND_DATE]: "Fecha de envio",
  [commons.SUPPLIES]: "Insumos",
  [commons.SUPPLY]: "Insumo",
  [commons.ACCURACY]: "Accuracy",
  [commons.FIND_SUPPLY_LABEL]: "Buscar Insumo",
  [commons.SHOWING]: "Mostrando",
  [commons.SELECT_OPTION]: "Selecciona la información que deseas consultar",
  [commons.SELECT_COUNTRY_CITY_KITCHEN]:
    "Utiliza los filtro para seleccionar la información del país, ciudad o cocina que deseas consultar.",
  [commons.DATE]: "Fecha",
  [commons.REPORTED_AMOUNT]: "Cantidad reportada",
  [commons.SEND_TO_VALIDATION]: "Solicitar validación",
  [commons.TO_AUTHORIZE]: "Por autorizar",
  [commons.REPORT]: "Reportar",
  [commons.FOR_VALIDATE]: "Por validar",
  [commons.CLOSE]: "Cerrar",
  [commons.SAVE_CHANGES]: "Guardar cambios",
  [commons.WEEK]: "Semana",
  [commons.AMOUNT_SET_ASIDE]: "Cantidad apartada",
  [commons.TRANSFER_DATE]: "Fecha de Traslado",
  [commons.PROGRAMMED_KITCHENS]: "Cocinas Programadas",
  [commons.TOTAL_SUPPLIES]: "Insumos Totales",
  [commons.PROGRAMMED_BY]: "Programado por",
  [commons.PACKING_PROGRESS]: "Progreso de empacado",
  [commons.PACKING_PROGRESS_SUPPLIES]:
    "{{packing}} de {{total}} insumos ({{percentage}})",
  [commons.PACKING_PROGRESS_KITCHENS]:
    "{{packing}} de {{total}} cocinas ({{percentage}})",
  [commons.SCANNED]: "Escaneado",
  [commons.SCANNED_SUCCESSFULLY]: "Escaneado correctamente",
  [commons.SCANNING_ERROR]: "Error en el escaneo",
  [commons.SEARCH_RESULT]: "Resultado de búsqueda",
  [commons.RESULTS_FOR]: "Resultados para",
  [commons.READING_BARCODE_SCANNER]: "Lectura del escaner de código de barras",
  [commons.PRODUCTION_ALREADY_ENTERED]: "Ya se ingresó esta producción",
  [commons.EMAIL_LABEL]: "Correo electrónico",

  [commons.ERROR_TITLE]: "La página no existe o no tiene acceso...",
  [commons.ERROR_SUBTITLE]: "Porfavor contáctese con el administrador.",
  [commons.ADD]: "Agregar",
  [commons.DOCUMENT_CODE]: "Código de documento",
  [commons.PRINT_LABELS]: "Imprimir etiquetas",
  [commons.PACK]: "Empacar",
  [commons.PRINT]: "Imprimir",
  [commons.SUPPLIES_FILTER]: "Filtrar insumos",
  [commons.VIEW_MODES]: "Modos de vista",
  [commons.FOR_SUPPLY]: "Por insumo",
  [commons.FOR_KITCHEN]: "Por cocina",
  [commons.ORDER_DETAILS]: "Detalles de la orden",
  [commons.ENTER_INVOICE_OR_REMISSION_NO]: "Ingresar No. Factura / Remisión",
  [commons.DECLINE]: "Rechazar",
  [commons.FILE_TOO_BIG]: "Archivo muy grande (max {{size}}MB)",
  [commons.ORDER_RECEIPT]: "Recibir orden",
  [commons.RECEIPT]: "Recepción",
  [commons.CHANGE_STATUS]: "Cambiar estado",
  [commons.SELECT_A_NEW_STATUS]: "Seleccione el nuevo estado para esta orden",
  [commons.VIEW_PDF]: "Ver PDF",
  [commons.PDF]: "PDF",
  [commons.MODIFY]: "Modificar",
  [commons.COMMENT]: "Comentario",
  [commons.PRODUCTIONS]: "Producciones",
  [commons.PRODUCTION]: "Producción",
  [commons.MONDAY]: "Lunes",
  [commons.TUESDAY]: "Martes",
  [commons.WEDNESDAY]: "Miércoles",
  [commons.THURSDAY]: "Jueves",
  [commons.FRIDAY]: "Viernes",
  [commons.SATURDAY]: "Sábado",
  [commons.SUNDAY]: "Domingo",
  [commons.TODAY]: "Hoy",
  [commons.TO_THE]: "al",
  [commons.JANUARY]: "Enero",
  [commons.FEBRUARY]: "Febrero",
  [commons.MARCH]: "Marzo",
  [commons.APRIL]: "Abril",
  [commons.MAY]: "Mayo",
  [commons.JUNE]: "Junio",
  [commons.JULY]: "Julio",
  [commons.AUGUST]: "Agosto",
  [commons.SEPTEMBER]: "Septiembre",
  [commons.OCTOBER]: "Octubre",
  [commons.NOVEMBER]: "Noviembre",
  [commons.DECEMBER]: "Diciembre",
  [commons.IMPORT]: "Importar",
  [commons.MASSIVE]: "Masivo",
  [commons.INDIVIDUAL]: "Individual",
  [commons.TIME]: "Tiempo",
  [commons.NO_SEARCH_RESULTS]:
    "No se han encontrado coincidencias para: {{search}}",
  [commons.ID]: "ID",
  [commons.PREPARE]: "Alistar",
  [commons.QUANTITY_ERROR]: "La cantidad debe ser mayor a 0",
  [commons.CODE]: "Código",
  [commons.ERROR]: "Error",
  [commons.ACTIVATE]: "Activar",
  [commons.INACTIVATE]: "Desactivar",
  [commons.EXIT]: "Salir",
  [commons.ARCHIVE]: "Archivar",
  [commons.RESOURCES]: "Recursos",
  [commons.ROUTES]: "Rutas",
  [commons.COUNTRY]: "País",
  [commons.CITY]: "Ciudad",
  [commons.FILTER_BY_CITY]: "Filtrar por ciudad",
  [commons.LOCATION]: "Ubicación",
  [commons.LABELS]: "Etiquetas",
  [commons.VIEW_LABELS]: "Ver Etiquetas",
  [commons.RETURNED]: "Devolución",
  [commons.ADDRESS]: "Dirección",
  [commons.PHONE]: "Teléfono",
  [commons.YES]: "Si",
  [commons.NO]: "No",
  [commons.REQUIRED_FIELD]: "Valor obligatorio",
  [commons.SUCCESSFUL_PROCESS]: "Proceso realizado exitosamente.",
  [commons.FAILED_PROCESS]: "Ha ocurrido un error en el proceso.",
  [commons.SKU_TYPE_CITY_ALREADY_EXIST]:
    "La categorización para este insumo, ya existe en esta ciudad",
  [commons.CONTENT_IS_EMPTY]: "El contenido está vacío",
  [commons.SEND]: "Enviar",
  [commons.BETTER]: "Mejor",
  [commons.BUYER]: "Comprador",
  [commons.DOWNLOAD_PDFS]: "Descargar PDFs",
  [commons.THE_PROCESS_CONTINUES]: "Continue con el proceso",
  [commons.A_SIMILAR_RECORD_ALREADY_EXIST]: "Un registro similar, ya existe",
  [commons.BULK_LOAD]: "Carga masiva",
  [commons.TEMPLATE_VIEW]: "Ver/Descargar plantilla",
  [commons.ERROR_LINE]: "Error en la línea {{index}}: {{error}}",
  [commons.EMPTY_ERROR]: "Hay campos vacíos",
  [commons.RESET]: "Reiniciar",
  [commons.SUCCESSFUL]: "Exitoso",
  [commons.FAILED]: "Fallido",
  [commons.RESULT]: "Resultado",
  [commons.ONLY_FILE_TYPE]: "Sólo archivos tipo: {{type}}",
  [commons.DOCUMENT]: "Documento",
  [commons.VENDOR_INFO]: "Información del proveedor",
  [commons.RECORD]: "Registro",
  [commons.OF]: "de",
  [commons.SEEKER]: "Buscador",
  [commons.FINISHED_PRODUCTS]: "Productos terminados",
  [commons.AUTOGENERATE]: "Se auto-genera",
  [commons.LINK]: "Asociar",
  [commons.SUBTOTAL]: "Subtotal",
  [commons.INVOICE]: "Factura",
  [commons.WITH_INVOICE]: "Con Factura",
  [commons.ITEMS]: "Items",
  [commons.UNLINK]: "Desvincular",
  [commons.VENDOR]: "Proveedor",
  [commons.OPS_REVIEW]: "Revisar OPS",
  [commons.PAID]: "Pagada",
  [commons.PAYMENT_DATE]: "Fecha de pago",
  [commons.LINKED]: "Asociada",
  [commons.FREEZING]: "Congelación ( -18ºC )",
  [commons.REFRIGERATION]: "Refrigeración (1 ºC - 5ºC)",
  [commons.ROOM_TEMPERATURE]: "Ambiente (15ºC - 30ºC)",

  [measureUnits.UN]: "Unidad",
  [measureUnits.G]: "Gramos",
  [measureUnits.GR]: "Gramos",
  [measureUnits.ML]: "Mililitros",
  [measureUnits.KG]: "Kilogramos",

  [receiptType.ALL]: "Todas",
  [receiptType.PURCHASE_ORDER]: "Orden de Compra",
  [receiptType.INBOUND_TRANSFER]: "Traslado",
  [receiptType.PENDING]: "Pendientes",
  [receiptType.RECEIVED]: "Recibidas",
  [receiptType.DETAIL_TITLE]: "Detalle de recepción",

  [adjustmentType.LOSS]: "Baja",
  [adjustmentType.LOSSES]: "Bajas",
  [adjustmentType.DISCARDED]: "Merma",
  [adjustmentType.DISCARDEDS]: "Mermas",
  [adjustmentType.INTERNAL_CONSUMPTION]: "Consumo interno",
  [adjustmentType.FINISHED_PRODUCT]: "Producto terminado",
  [adjustmentType.PRODUCTION_REJECTED]: "Producción rechazada",
  [adjustmentType.EVENT]: "Evento",
  [adjustmentType.EVENTS]: "Eventos",
  [adjustmentType.SALE]: "Venta",
  [adjustmentType.PRODUCTION]: "Producción",
  [adjustmentType.PURCHASE_ORDER]: "Orden de compra",
  [adjustmentType.COUNTING]: "Conteo",
  [adjustmentType.INITIAL]: "Inicial",
  [adjustmentType.ADJUSTMENT]: "Ajustes",
  [adjustmentType.MANUAL_ADJUSTMENT]: "Ajuste Manual",
  [adjustmentType.VALIDATION_ADJUSTMENT]: "Ajuste por Validación",
  [adjustmentType.COUNTING_ADJUSTMENT]: "Ajuste por Conteo",
  [adjustmentType.SALE_CANCELLATION]: "Venta cancelada",
  [adjustmentType.UPDATE_PURCHASE_ORDER_LINE]: "Ajuste de orden de compra",
  [adjustmentType.DAILY_COUNTING_ADJUSTMENT]: "Ajuste por conteo diario",
  [adjustmentType.TRANSFER]: "Orden de translado",
  [adjustmentType.POSITIVE]: "Positivo",
  [adjustmentType.NEGATIVE]: "Negativo",
  [adjustmentType.TRANSFORM]: "Transformación",

  [inventoryMovementType.EGRESS]: "Egreso",
  [inventoryMovementType.ENTRY]: "Ingreso",

  [EventReasonType.DAMAGED]: "Producto dañado",
  [EventReasonType.EXPIRED]: "Producto vencido",
  [EventReasonType.OBSOLETE]: "Producto obsoleto",
  [EventReasonType.TRAINING]: "Entrenamiento / Capacitación",
  [EventReasonType.MARKETING]: "Proceso Marketing",
  [EventReasonType.QUALITY_TESTS]: "Pruebas de calidad",

  [physicalCountModule.TITLE]: "Registro de Inventario",
  [physicalCountModule.SUBTITLE]:
    "Ingresa la cantidad de artículos disponibles en el inventario.",
  [physicalCountModule.HISTORY_SUBTITLE]:
    "Seleccione el conteo físico que desea continuar o cree uno nuevo.",
  [physicalCountModule.CONFIRMATION_FINISHED_TITLE]: "Desviación Detectada",
  [physicalCountModule.CONFIRMATION_MODAL_DESCRIPTION]:
    "La cantidad ingresada tiene una desviación muy alta contra el inventario teórico. Por favor valida el conteo o confirma para continuar.",
  [physicalCountModule.COUNTING_FINISHED_TITLE]: "Conteo físico finalizado.",
  [physicalCountModule.COUNTING_FINISHED_SUBTITLE]:
    "El conteo físico ha sido finalizado de manera exitosa.",
  [physicalCountModule.FILTER_SEARCH_PLACEHOLDER]: "Nombre del artículo",
  [physicalCountModule.DISARD_ITEM_MODAL_TITLE]: "Descartar artículo",
  [physicalCountModule.DISARD_ITEM_MODAL_DESCRIPTION]:
    "El artículo dejará de enlistarse en tu inventario. Por favor confirma si deseas continuar.",
  [physicalCountModule.EMPTY_COUNTINGS]:
    "No tienes conteos de inventario programados",
  [physicalCountModule.CONSOLIDATED_EXPORT_TITLE]: "Reporte Consolidado",
  [physicalCountModule.CONSOLIDATED_EXPORT_SUBTITLE]:
    "Ingrese las fechas para consultar el reporte consolidado.",
  [physicalCountModule.FINISH_ITEM_MODAL_TITLE]: "Finalizar Conteo",
  [physicalCountModule.FINISH_ITEM_MODAL_DESCRIPTION]:
    "Se terminará el conteo de este artículo. Por favor confirma si deseas continuar. ",
  [physicalCountModule.CONFIRM_FINISH_COUNTING_TITLE]: "Finalizar conteo",
  [physicalCountModule.CONFIRM_FINISH_COUNTING_MESSAGE]:
    "¿Desea finalizar el conteo? despues de finalizado no podrá modificar las cantidades.",
  [physicalCountModule.SCHEDULED]: "Programado",
  [physicalCountModule.IN_PROCESS]: "En proceso",
  [physicalCountModule.FINISHED]: "Finalizado",

  [purchaseOrderModule.PROGRESS]: "Progreso de Recepción",
  [purchaseOrderModule.RECORDS_PAGE_TITLE]: "Recepción de Órdenes",
  [purchaseOrderModule.RECORDS_PAGE_SUBTITLE]:
    "Lista de órdenes pendientes por recibir.",
  [purchaseOrderModule.DETAIL_PAGE_TITLE]: "Líneas de órdenes de compra",
  [purchaseOrderModule.FIND_ORDERS]: "Buscar orden de compra",

  [purchaseOrderModule.ORDER_ID]: "No. Orden",
  [purchaseOrderModule.RECEPTION]: "No. Recepción",
  [purchaseOrderModule.TYPE]: "Tipo de orden",
  [purchaseOrderModule.REQUEST_DATE]: "Fecha de Solicitud",
  [purchaseOrderModule.ITMES]: "Artículos Solicitados",
  [purchaseOrderModule.UNIT]: "UNIDAD",
  [purchaseOrderModule.FIND_ITEM]: "Buscar Artículo",
  [purchaseOrderModule.STATUS_RECEIPT]: "Estatus de recepción",
  [purchaseOrderModule.ADD_DOCUMENTS]: "Agregar documentos",
  [purchaseOrderModule.DOCUMENTS_LIST_TITLE]: "Documentos cargados",
  [purchaseOrderModule.DOCUMENTS_LIST_INFO]:
    "Visualiza o descarga el documento que necesitas.",
  [purchaseOrderModule.MARK_ASRECEIVED_TITLE]: "Orden de compra recibida",
  [purchaseOrderModule.MARK_ASRECEIVED_MESSAGE]:
    "La orden de compra sera marcada como recibida y quedara pendiente para conciliación",
  [purchaseOrderModule.DELETE_RECEIPT_TITLE]: "Eliminar orden de Recepción",
  [purchaseOrderModule.DELETE_RECEIPT_MESSAGE]:
    "Desea eliminar la orden de recepción número {{receiptNo}}",
  [purchaseOrderModule.RECEIPT]: "Recibir orden de compra",
  [purchaseOrderModule.TITLE]: "Revisar órdenes de compra",
  [purchaseOrderModule.DESCRIPTION]:
    "Consulta de órdenes de compra solicitadas a provedores.",
  [purchaseOrderModule.FILTER_ORDERS]: "Filtrar órdenes de compra",
  [purchaseOrderModule.FIND_ORDER_LABEL]: "ID orden / Nombre proveedor",
  [purchaseOrderModule.PARTIAL_ORDERS]: "Órdenes parciales",
  [purchaseOrderModule.PENDING_ORDERS]: "Órdenes pendientes",
  [purchaseOrderModule.COMPLETE_ORDERS]: "Órdenes completas",
  [purchaseOrderModule.PERCENTAGE_TO_ORDERS]:
    "{{percentage}}% de las órdenes totales",
  [purchaseOrderModule.ADD_DOCUMENTS_LABEL_INFO]:
    "Toma una foto o selecciona el documento",
  [purchaseOrderModule.INVOICES]: "Faturas",
  [purchaseOrderModule.DOWNLOAD_OCS]: "Ordenes de compra",
  [purchaseOrderModule.DOWNLOAD_LINES]: "Detalle de ordenes de compra",
  [purchaseOrderModule.INVOICE_ID]: "ID factura",
  [purchaseOrderModule.VENDOR_CODE]: "Codigo proveedor",
  [purchaseOrderModule.NOTES]: "Notas",
  [purchaseOrderModule.INVOICE_TITLE]: "Gestionar facturas",
  [purchaseOrderModule.INVOICE_DESCRIPTION]:
    "Gestione las facturas recibidas y asocie las ordenes de compra",
  [purchaseOrderModule.FILTER_INVOICES]: "Filtrar facturas",
  [purchaseOrderModule.IMPORT_TITLE]: "Importar xml factura",
  [purchaseOrderModule.WATCH_ORDER]: "Ver Orden",
  [purchaseOrderModule.LINK_ORDER]: "Asociar",
  [purchaseOrderModule.INVOICE_REPORT]: "Facturas",
  [purchaseOrderModule.INVOICE_REPORT_DETAILED]: "Detalle de facturas",
  [purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT]: "Generar ajuste",
  [purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT_DESCRIPTION]:
    "Digita el valor total de la factura para calcular el valor del ajuste",
  [purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT_MAX]:
    "El porcentaje maximo permitido de ajuste es {{percentage}}%",
  [purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT_VALUE]:
    "Se generara un ajuste para la orden de compra de {{value}}",
  [purchaseOrderModule.ACCOUNT_TITLE]: "Contabilizar ordenes de compra",
  [purchaseOrderModule.ACCOUNT_DESCRIPTION]:
    "Contabiliza y paga las ordenes de compra",
  [purchaseOrderModule.CHANGE_TO_PAID_TITLE]: "Orden de compra pagada",
  [purchaseOrderModule.CHANGE_TO_PAID_DESCRIPTION]:
    "Se marcaran las siguientes ordenes de compra como pagadas",
  [purchaseOrderModule.CHANGE_TO_ACCOUNTED_TITLE]:
    "Orden de compra contabilizada",
  [purchaseOrderModule.CHANGE_TO_ACCOUNTED_DESCRIPTION]:
    "Se marcaran las siguientes ordenes de compra como contabilizadas",

  [suppliesManagerModule.TITLE]: "Gestión de Insumos para Inventario",
  [suppliesManagerModule.SUBTITLE]:
    "Activa o desactiva los insumos para conteo de inventarios.",
  [suppliesManagerModule.MASSIVE_SUCCESS_TITLE]: "Gestión de insumos",
  [suppliesManagerModule.MASSIVE_SUCCESS_MESSAGE]:
    "Insumos actualizados correctamente.",

  [adjustmentsModule.TITLE]: "Ajuste de Inventarios",
  [adjustmentsModule.SUBTITLE]: "Cargue masivo de ajuste de inventario.",
  [adjustmentsModule.INSTRUCTIONS]: "Instrucciones",
  [adjustmentsModule.INSTRUCTIONS_DETAILS]:
    "Importa la plantilla con los ajustes correspondientes para actualizar el inventario real.",
  [adjustmentsModule.BOX_DETAILS]:
    "Selecciona o arrastra la plantilla con los ajustes",
  [adjustmentsModule.MSG_OK_START_PROCCESS]: "Proceso correctamente iniciado",
  [adjustmentsModule.MSG_FAIL_START_PROCCESS]: "Proceso fallido",
  [adjustmentsModule.SUCCESSFULLY_SAVE]: "Ajuste guardado correctamente",

  [dailyAdjustmentsModule.TITLE]: "Registro de Ajustes",
  [dailyAdjustmentsModule.TITLE_DISCARDED]: "Registro de Mermas",
  [dailyAdjustmentsModule.SUBTITLE_DISCARDED]:
    "Registra las mermas generadas en los productos de la cocina.",
  [dailyAdjustmentsModule.INSTRUCTIONS_DISCARDED]:
    "Busca el producto al que deseas reportar la merma generada.",

  [dailyAdjustmentsModule.TITLE_LOSS]: "Registro de Bajas",
  [dailyAdjustmentsModule.SUBTITLE_LOSS]:
    "Registra las  bajas en los productos de la cocina.",
  [dailyAdjustmentsModule.INSTRUCTIONS_LOSS]:
    "Busca el producto al que deseas reportar la baja generada.",

  [dailyAdjustmentsModule.TITLE_CONSUMPTION]: "Registro de Consumos",
  [dailyAdjustmentsModule.SUBTITLE_CONSUMPTION]:
    "Registra los consumos generados de productos de la cocina.",
  [dailyAdjustmentsModule.INSTRUCTIONS_CONSUMPTION]:
    "Busca el producto al que deseas reportar el consumo generado.",

  [dailyAdjustmentsModule.TITLE_EVENT]: "Registro de Eventos",
  [dailyAdjustmentsModule.SUBTITLE_EVENT]:
    "Registra los eventos generados de productos de la cocina.",
  [dailyAdjustmentsModule.INSTRUCTIONS_EVENT]:
    "Busca el producto al que deseas reportar el evento generado.",

  [dailyAdjustmentsModule.TITLE_FINISHED_PRODUCT]:
    "Registro de productos finalizados",
  [dailyAdjustmentsModule.SUBTITLE_FINISHED_PRODUCT]:
    "Registra los productos finalizados generados de productos de la cocina.",
  [dailyAdjustmentsModule.INSTRUCTIONS_FINISHED_PRODUCT]:
    "Busca el producto al que deseas reportar el productos finalizado generado.",

  [dailyAdjustmentsModule.REGISTER_SUPPLY_OR_PRODUCT_INSTRUCTION]:
    "Busca el imsumo o producto que deseas reportar",

  [dailyAdjustmentsModule.MOVEMENTS_AUTHORIZATION]: "Autorizar Movimentos",
  [dailyAdjustmentsModule.VALIDATE_AUTHORIZATION]:
    "Validar los registros de movimientos generados por el personal de cocina",
  [dailyAdjustmentsModule.CONFIRM_MESSAGE]:
    "Se registrarán {{quantity}} {{unit}} como {{type}} para este insumo. Confirma si deseas continuar.",
  [dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE]:
    "Se validará los movimientos seleccionados, por favor verifique la cantidad enviada, esta no podrá ser modificada posteriormente y su inventario se vera afectado inmediatamente.",
  [dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE_PLURAL]:
    "Se validará los {{supplyQuantity}} movimientos seleccionados, por favor verifique la cantidad enviada, esta no podrá ser modificada posteriormente y su inventario se vera afectado inmediatamente.",
  [dailyAdjustmentsModule.AUTHORIZE_DELETE_TITLE]: "Eliminar Movimientos",
  [dailyAdjustmentsModule.AUTHORIZE_DELETE_MESSAGE]:
    "Se eliminará el movimiento seleccionado. Por favor confirma si deseas continuar.",
  [dailyAdjustmentsModule.AUTHORIZE_DELETE_MESSAGE_PLURAL]:
    "Se eliminarán los {{supplyQuantity}} movimientos seleccionados. Por favor confirma si deseas continuar.",
  [dailyAdjustmentsModule.AUTHORIZE_VALIDATE_TITLE]: "Validar Movimientos",
  [dailyAdjustmentsModule.AUTHORIZE_VALIDATE_MESSAGE]:
    "Se validará el movimiento seleccionado. Por favor confirma si deseas continuar.",
  [dailyAdjustmentsModule.AUTHORIZE_VALIDATE_MESSAGE_PLURAL]:
    "Se validarán los {{supplyQuantity}} movimientos seleccionados. Por favor confirma si deseas continuar.",
  [dailyAdjustmentsModule.REPORT_EVENT_TITLE]: "Reportar Eventos",
  [dailyAdjustmentsModule.SHOW_FINISHED_PRODUCTS]:
    "Mostrar productos terminados",
  [dailyAdjustmentsModule.PRODUCT_DRAWER_INFO]: "Valida los insumos utilizados",
  [dailyAdjustmentsModule.PRE_AUTHORIZE_ADJUSMENT_BUTTON]: "Pre-autorizar",
  [theoricalInventoryModule.TITLE]: "Inventario Teorico",
  [theoricalInventoryModule.NO_ELEMENTS]: "No hay elementos",
  [theoricalInventoryModule.SUBTITLE]:
    "Consulta de inventario teorico por cocina",
  [theoricalInventoryModule.SUPPLIES_EXCLUDED]: "Insumos Excluídos",
  [theoricalInventoryModule.DEVIATION_ZERO]: "Sin Desviación",
  [theoricalInventoryModule.DEVIATION_LOW]: "Desviación Tolerable",
  [theoricalInventoryModule.DEVIATION_HIGH]: "Desviación Alta",
  [theoricalInventoryModule.REPORT_TITLE]: "Reporte de inventario",
  [theoricalInventoryModule.REPORT_SUBTITLE]:
    "Valida el inventario teórico vs el conteo físico.",
  [theoricalInventoryModule.REPORT_FILTER]: "Filtrar reporte",
  [theoricalInventoryModule.ERROR_DATA]:
    "Ocurrió un error al obtener los datos",
  [theoricalInventoryModule.ERROR_EXPORT]:
    "No se encontraron registros para exportar",
  [theoricalInventoryModule.THEORETICAL_AMOUNT]: "Cantidad teórica",
  [theoricalInventoryModule.ACTUAL_AMOUNT]: "Cantidad real",
  [theoricalInventoryModule.DIFFERENCE]: "Diferencia",
  [theoricalInventoryModule.DEVIATION]: "Desviación",
  [theoricalInventoryModule.HIGH_ACCURACY]: "Accuracy Alto",
  [theoricalInventoryModule.LOW_ACCURACY]: "Accuracy Bajo",
  [theoricalInventoryModule.MEDIUM_ACCURACY]: "Accuracy Medio",
  [theoricalInventoryModule.LAST_COUNTING_LABEL]:
    "Último conteo realizado el {{date}} a las {{time}}",
  [theoricalInventoryModule.RESULT]: "Resultado",
  [theoricalInventoryModule.ACCURACY_RESULT]: "Accuracy del {{accuracy}}%",
  [theoricalInventoryModule.DETAILS]: "",

  [kitchenInventoryManagement.INVENTORY_MODULE]:
    "Gestión de Inventarios para Cocinas",
  [kitchenInventoryManagement.INVENTORY_MODULE_DESCRIPTION]:
    "Selecciona la herramienta que deseas utilizar para la gestión de inventario en tu cocina.",

  [kitchenInventoryManagement.INVENTORY_ADMIN_MODULE]: "Admin de Inventarios",
  [kitchenInventoryManagement.INVENTORY_ADMIN_MODULE_DESCRIPTION]:
    "Selecciona la herramienta que deseas utilizar para administrar la configuración de los inventarios.",

  [kitchenInventoryManagement.SUPPLY_ADMIN_MODULE]: "Admin de Compras",
  [kitchenInventoryManagement.SUPPLY_ADMIN_MODULE_DESCRIPTION]: "",

  [kitchenInventoryManagement.KITCHEN_INVENTORY_TITLE]: "Inventario",
  [kitchenInventoryManagement.KITCHEN_INVENTORY_DESCRIPTION]:
    "Conteos programados para registro de inventario.",

  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE]: "Movimientos diarios",
  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_DESCRIPTION]:
    "Reporte de ajustes por mermas, bajas, consumos y eventos.",

  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_CP]:
    "Gestión de Inventarios para C.P.",
  [kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE_DESCRIPTION_CP]:
    "Selecciona la herramienta que deseas utilizar para la gestión de inventario en tu centro de producción.",

  [kitchenInventoryManagement.RECEPTION_INVENTORY_TITLE]:
    "Recepción de órdenes",
  [kitchenInventoryManagement.RECEPTION_INVENTORY_DESCRIPTION]:
    "Recepción de traslados y órdenes de compra.",

  [kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_TITLE]:
    "Autorizar reconteos",
  [kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_DESCRIPTION]:
    "Autorización de reconteos solicitados por ubicaciones.",

  [kitchenInventoryManagement.AUTHORIZATION_INVENTORY_TITLE]:
    "Autorización de movimientos",
  [kitchenInventoryManagement.AUTHORIZATION_INVENTORY_DESCRIPTION]:
    "Revisión de ajustes de inventario por movimientos diarios.",

  [kitchenInventoryManagement.PRODUCT_INVENTORY_TITLE]:
    "Registro de producciones",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_DESCRIPTION]:
    "Programación y generación de producciones.",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_DESCRIPTION_2]:
    "Creación de producciones para traslados",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_MODULE]:
    "Lista de producciones activas en el Centro de Producción.",

  [kitchenInventoryManagement.PRODUCT_INVENTORY_MODAL_TITLE]:
    "Lista de producciones activas en el Centro de Producción.",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_MODAL_INSTRUCTION]:
    "Comienza una nueva producción para continuar",

  [kitchenInventoryManagement.PRODUCT_INVENTORY_SCHEDULE_PRODUCTIONS]:
    "Producciones programadas",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_ACTIVE_PRODUCTIONS]:
    "Producciones activas",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_ENDED_PRODUCTIONS]:
    "Producciones finalizadas",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_NEW_PRODUCTIONS]:
    "NUEVA PRODUCCIÓN",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_ADD_PRODUCTIONS]:
    "AGREGAR PRODUCCIÓN",
  [kitchenInventoryManagement.PRODUCT_INVENTORY_NAME_PRODUCTIONS]:
    "Nombre de Producción",

  [kitchenInventoryManagement.TRANSFER_LOAD_FILE_TITLE]:
    "Programación de traslados",
  [kitchenInventoryManagement.TRANSFER_LOAD_FILE_DESCRIPTION]:
    "Programación de intercambio de productos entre ubicaciones.",
  [kitchenInventoryManagement.TRANSFER_PICKING_PACKING_TITLE]:
    "Preparación de traslado",
  [kitchenInventoryManagement.TRANSFER_PICKING_PACKING_DESCRIPTION]:
    "Selección y empaquetado de insumos para el envío de traslados.",
  [kitchenInventoryManagement.TRANSFER_AUTHORIZATION_TITLE]:
    "Autorización de traslados",
  [kitchenInventoryManagement.TRANSFER_AUTHORIZATION_DESCRIPTION]:
    "Revisión de programación de traslados reportados entre ubicaciones.",

  [kitchenInventoryManagement.INVENTORYREVIEW_MODULE]: "Revisar inventarios",
  [kitchenInventoryManagement.COUNTDOWNLOAD_MODULE]: "Descargar conteos",
  [kitchenInventoryManagement.SUPPLIESMANAGEMENT_MODULE]: "Controlar insumos",
  [kitchenInventoryManagement.MASSIVEADJUSTS_MODULE]: "Ajuste masivo",
  [kitchenInventoryManagement.THEORETICALINVENTORYCOMPARISON_MODULE]:
    "Comparar inventario",
  [kitchenInventoryManagement.SINGLEADJUSTS_MODULE]: "Ajuste individual",
  [kitchenInventoryManagement.DAILYMOVS_MODULE]:
    "Actualización individual de ajuste de inventario.",

  [kitchenInventoryManagement.INVENTORYREVIEW_MODULE_DESCRIPTION]:
    "Revisión y análisis de inventario teórico.",
  [kitchenInventoryManagement.COUNTDOWNLOAD_MODULE_DESCRIPTION]:
    "Descarga de conteos de inventario por ubicación.",
  [kitchenInventoryManagement.SUPPLIESMANAGEMENT_MODULE_DESCRIPTION]:
    "Activación y desactivación de insumos para inventarios.",
  [kitchenInventoryManagement.MASSIVEADJUSTS_MODULE_DESCRIPTION]:
    "Actualización masiva de ajustes de inventario.",
  [kitchenInventoryManagement.THEORETICALINVENTORYCOMPARISON_MODULE_DESCRIPTION]:
    "Análisis y validación de inventario teórico vs físico.",
  [kitchenInventoryManagement.SINGLEADJUSTS_MODULE_DESCRIPTION]:
    "Actualización individual de ajustes de inventario.",
  [kitchenInventoryManagement.DAILYMOVS_MODULE_DESCRIPTION]:
    "Reporte de movimientos diarios reportados por ubicación",

  [kitchenInventoryManagement.SELECT_MODULE_TITLE]: "Seleccione el módulo",
  [kitchenInventoryManagement.SELECT_MODULE_SUBTITLE]:
    "Seleccione la herramienta",
  [kitchenInventoryManagement.FOR_SUPPLY_INFO]:
    "Selecciona el insumo que deseas comenzar a alistar los traslados a las ubicaciones solicitadas",
  [kitchenInventoryManagement.FOR_KITCHEN_INFO]:
    "Escanea los empaques o ingresa la cantidad  de cada insumo que deseas empacar para la cocina",
  [kitchenInventoryManagement.PRODUCTION_SUPPLY_RECEPTION_TITLE]:
    "Recepción de Abastecimiento",
  [kitchenInventoryManagement.PRODUCTION_SUPPLY_RECEPTION_DESCRIPTION]:
    "Preparación de insumos para ubicaciones.",
  [kitchenInventoryManagement.PRODUCTION_LIBERATION_TITLE]:
    "Liberación de producciones",
  [kitchenInventoryManagement.PRODUCTION_LIBERATION_DESCRIPTION]:
    "Validación y liberación de producciones generadas.",
  [kitchenInventoryManagement.BC_INTEGRATION_MODULE]: "Integraciones",
  [kitchenInventoryManagement.BC_INTEGRATION_MODULE_DESCRIPTION]:
    "Integraciones Bussiness Central",

  [productionManagement.PRODUCT_INSTRUCTION]:
    "Selecciona una producción para ver los ingredientes necesarios.",
  [productionManagement.START_PRODUCTION]: "Comenzar Producción",
  [productionManagement.FINISH_PRODUCTION]: "Finalizar Producción",
  [productionManagement.FINISH_PRODUCTION_MODAL]:
    "Ingresa el rendimiento real de la producción realizada.",
  [productionManagement.FINISH_REAL_PERFORMANCE]: "Rendimiento real",
  [productionManagement.PRODUCTION_USER]: "Encargado de la producción",
  [productionManagement.PRODUCTION_PACKED_QUANTITY]: "Cantidad por empaque",
  [productionManagement.PRODUCTION_CALCULATOR_TITLE]: "Calcular Producción",
  [productionManagement.PRODUCTION_CALCULATOR_DESCRIPTION]:
    "Ingresa el insumo con el que deseas realizar la producción para calcular el rendimiento",
  [productionManagement.PRODUCTION_CALCULATOR_ESTIMATED]: "Producción Estimada",
  [productionManagement.EDIT_PRODUCTION]: "Modificar producción",
  [productionManagement.EDIT_PRODUCTION_MODAL_SUCCESS_TITLE]:
    "Producción modificada",
  [productionManagement.EDIT_PRODUCTION_MODAL_SUCCESS_MESSAGE]:
    "La produccion de {{productionName}} se actualizó con éxito.",
  [productionManagement.REPORT_PRODUCTION_ERROR_MESSAGE_HIGH]:
    "El rendimiento real {{producedQuantity}} {{unit}} para la producción {{productionName}} es muy ALTO para la cantidad a producir {{quantityToProduce}} {{unit}}",
  [productionManagement.REPORT_PRODUCTION_ERROR_MESSAGE_LOW]:
    "El rendimiento real {{producedQuantity}} {{unit}} para la producción {{productionName}} es muy BAJO para la cantidad a producir {{quantityToProduce}} {{unit}}",
  [productionManagement.REPORT_PRODUCTION_ERROR_VALIDATION_MESSAGE]:
    "La producción de <strong>{{productionName}}</strong> requiere un producción previa de <strong>{{itemName}} </strong>.",
  [productionManagement.REPORT_PRODUCTION_ERROR_PRODUCTION_VALIDATION_MESSAGE]:
    "La producción de <strong>{{productionName}}</strong> requiere un producción previa de <strong>{{itemName}} </strong>. <strong>Por favor genera primero la producción de {{itemName}} para continuar</strong>.",
  [productionManagement.DELETE_DIALOG_TITLE]: "Eliminar Producción",
  [productionManagement.DELETE_DIALOG_MESSAGE]:
    "La producción de {{productionName}} se eliminará de la lista de producciones activas. ¿Por favor confirma si deseas continuar?",
  [productionManagement.PACKAGING_PARTIAL]: "Por empacar",
  [productionManagement.PACKAGING_COMPLETE]: "Producción empacada",
  [productionManagement.LABELS_HISTORY]: "Historial de empaques",
  [productionManagement.LABELS_HISTORY_EMPTY]:
    "Aún no se ha comenzado con el empaquetado de esta producción.",
  [productionManagement.PACK_PRODUCTION]: "Empacar producción",
  [productionManagement.SELECT_LABELS_TO_PRINT]:
    "Selecciona las etiquetas que deseas reimprimir",
  [productionManagement.REPRINT_LABELS]: "Reimprimir etiquetas",
  [productionManagement.LABELS_OF]: "Empaques de {{quantity}} {{unit}}",
  [productionManagement.LABELS_GENERATED]: "Etiquetas generadas",
  [productionManagement.LABEL]: "Etiqueta",
  [productionManagement.USER_CREATED_LABELS]: "Encargado de la producción",
  [productionManagement.SELECT_USER_CREATED_LABELS]: "Seleccione el encargado",
  [productionManagement.LABELS_GENERATED_INFO]:
    "Agrega la información  para generar las etiquetas de empaque",
  [productionManagement.TRIGGER]: "Generar",
  [productionManagement.QUANTITY_TO_PACK]: "Cantidad a empacar",
  [productionManagement.QUANTITY_OF_PACKAGING]: "Nro. empaques",
  [productionManagement.LOG_TITLE]: "Rastrear producciones",
  [productionManagement.LOG_DESCRIPTION]:
    "Revisión de historial de movimientos de empaques.",
  [productionManagement.LOG_TITLE_INPUT]: "Buscar o escanear producción",
  [productionManagement.LOG_LABEL_INPUT]: "Ingresa el lote de la producción",
  [productionManagement.LOG_TRACK]: "Rastrear",
  [productionManagement.LOG_EMPTY_LABEL_1]: "Rastreo de producciones",
  [productionManagement.LOG_EMPTY_LABEL_2]:
    "Ingresa o escanea una producción para validar sus movimientos registrados",
  [productionManagement.LOG_DETECTED_MOVS]: "Movimientos detectados",
  [productionManagement.LOG_MOV]: "Movimiento",
  [productionManagement.LOG_VALIDITY_LABEL]:
    "Vigencia: {{days}} días restantes",
  [productionManagement.LOG_INVALIDITY_LABEL]: "Vencido hace {{days}} días",
  [productionManagement.SCHEDULED]: "Programada",
  [productionManagement.IN_PROCESS]: "Produciendo",
  [productionManagement.FINISHED]: "Finalizada",
  [productionManagement.APPROVED]: "Aprobada",
  [productionManagement.APPROVED_WITH_NOVELTY]: "Aprobado con novedad",
  [productionManagement.PRODUCTION_AND_QUANTITY]: "Producción y cantidad",
  [productionManagement.CHRONOMETER]: "Cronómetro",
  [productionManagement.RESPONSIBLE]: "Responsable",
  [productionManagement.SCHEDULED_QUANTITY]: "Cantidad programada",
  [productionManagement.PRODUCED_QUANTITY]: "Cantidad producida",
  [productionManagement.STARTED_PRODUCTION]: "Inicio de producción",
  [productionManagement.FINISHED_PRODUCTION]: "Fin de producción",
  [productionManagement.PRODUCTION_TIME]: "Tiempo de producción",
  [productionManagement.PRODUCTION_USER_ASSIGNED_REQUIRED]:
    "El encargado de la producción es requerido",
  [productionManagement.PRODUCTION_SCHEDULED_DATE_REQUIRED]:
    "La fecha de produccion es requerida",
  [productionManagement.PRODUCTION_QUANTITY_REQUIRED]:
    "La cantidad a producir es requerida",
  [productionManagement.PRODUCTION_QUANTITY_TYPE_ERROR]:
    "La cantidad a producir debe ser un número",
  [productionManagement.PRODUCTION_SKU_REQUIRED]: "El SKU es requerido",
  [productionManagement.DOWNLOAD_EXAMPLE_TEMPLATE]:
    "Puedes descargar nuestra plantilla haciendo click aqui",
  [productionManagement.INVALID_DOCUMENT_TITLES]:
    "Los títulos del documento no son válidos.",
  [productionManagement.INVALID_DOCUMENT_DATA]:
    "Los datos del documento no son válidos.",
  [productionManagement.DRAG_AND_DROP_FILE]:
    "Seleccione o arrastre la plantilla con las producciones.",
  [productionManagement.FILL_IN_THE_FORM]:
    "Ingresa los datos de la producción que desea agregar.",
  [productionManagement.ADD_PRODUCTION_TITLE]: "Agregar producción",
  [productionManagement.EDIT_PRODUCTION_TITLE]: "Editar producción",
  [productionManagement.VALIDATE_ADDED_DATA]:
    "Valida los datos de la producción agregada.",
  [productionManagement.PRODUCTION_NAME]: "Nombre de la producción",
  [productionManagement.QUANTITY]: "Cantidad a producir",
  [productionManagement.SCHEDULED_DATE]: "Fecha de producción",
  [productionManagement.TIME_SLOT]: "Franja horaria",
  [productionManagement.USER_ASSIGNED]: "Responsable de producción",
  [productionManagement.PRODUCING_FOR]: "Produciendo desde hace",
  [productionManagement.PRINT_INGREDIENTS]: "Imprimir Ingredientes",
  [productionManagement.PPRODUCTION_ORDER]: "Orden de producción",
  [productionManagement.GENERATED_QUANTITY]: "Cantidad generada",
  [productionManagement.VALIDATION_REQUIRED_INFO]:
    "Producción pendiente de validación por parte de Quality",

  [transferModule.CONFIRM]: "Confirmar traslado",
  [transferModule.NUMBER_OF_BASKETS]: "Cantidad de canastas",
  [transferModule.SUCCESS_AUTHORIZE_TITLE]: "Traslado Confirmado",
  [transferModule.SUCCESS_AUTHORIZE_DESCRIPTION]:
    "El traslado  se confirmó con éxito en el sistema de inventarios.",
  [transferModule.ERROR_AUTHORIZE_TITLE]: "No se pudo confirmar el traslado",
  [transferModule.NUMBER_OF_BASKETS_QUESTION]:
    "¿Cúantas canastas se van a enviar?",
  [transferModule.SUCCESS]: "Traslado completado exitosamente",
  [transferModule.DELETE_LABELS]: "Eliminar empaques",
  [transferModule.ERROR_SKU]: "El número de producto esta vacío o es inválido",
  [transferModule.ERROR_MEASURE_UNIT]: "La unidad de medida esta vacía",
  [transferModule.ERROR_LINE]: "Error en la línea",
  [transferModule.DELETE_TRANSFER]: "Eliminar Traslado",
  [transferModule.DELETE_TRANSFER_CONFIRM]:
    "Desea eliminar el traslado para la cocina",
  [transferModule.USER_CREATE]: "Creado por",
  [transferModule.USER_RECIBE]: "Recibido por",
  [transferModule.DESTINATION]: "Destino",
  [transferModule.STORAGE]: "Almacenamiento",
  [transferModule.EDIT_TRANSFER]: "Editar",
  [transferModule.PREPARE_TRANSFER]: "Alistar",
  [transferModule.ENLISTED]: "Alistado",
  [transferModule.PENDING]: "Pendiente",
  [transferModule.UNSPECIFIED]: "Sin especificar",
  [transferModule.RECEIPT_NO]: "Número Recepción",
  [transferModule.ORDER_NO]: "Número Transferencia",
  [transferModule.RECEIVED_AT]: "Fecha recibido",
  [transferModule.RECEIVED_BY]: "Recibido por",
  [transferModule.CREATED_AT]: "Fecha Creación",
  [transferModule.STATUS]: "Estado",
  [transferModule.NOT_PENDIG]: "No tienes traslados pendientes por enviar.",
  [transferModule.ERROR_SINGLE_TRANSFER]:
    "El destino del traslado debe ser diferente al orígen.",
  [transferModule.TITLE_ERROR_PENDING_TRANSFER]:
    "Tiene los siguientes traslados pendientes por sincronizar:",
  [transferModule.DESCRIPCTION_ERROR_PENDING_TRANSFER]:
    "Por favor revisa si tienes producciones pendientes por reportar u órdenes de recepción pendientes si no es el caso comunícate con el Administrador de inventario.",
  [transferModule.SCHEDULE]: "Programar traslado",
  [transferModule.MASSIVE]: "Traslado masivo",
  [transferModule.INDIVIDUAL]: "Traslado individual",
  [transferModule.TO_SEND]: "Traslados Pendientes",
  [transferModule.MADE]: "Traslados Autorizados",

  [transferModule.RETURN_MESSAGE]:
    "Los siguientes productos seran devueltos a ",
  [transferModule.RECEIPT_CONFIRM_MESSAGE]:
    "La transferencia ha sido recibida con exito",
  [transferModule.RECEIPT_CONFIRM_TITLE]: "Recepción existosa",
  [transferModule.RECEIPT_MESSAGE]:
    "Confirma la recepción de la orden de transferencia",
  [transferModule.RECEIPT_TITLE]: "Recibir transferencia",
  [transferModule.EXTRA]: "EXTRA",
  [transferModule.TRANSFER]: "TRANSFERENCIA",
  [transferModule.RETURN]: "DEVOLUCIÓN",
  [transferModule.HAVE_EXTRA_OR_LEFTOVER_QUESTION]:
    "¿En esta transferencia llegaron insumos que no estaban en la orden de recepción o con cantidades sobrantes?",
  [transferModule.HAVE_EXTRA_OR_LEFTOVER_COMMENT]:
    "Indicar los insumos y cantidades",
  [transferModule.RECEIPT]: "Recibir traslado",

  [transferPackingDialog.SUBTITLE]:
    "Selecciona la cocina y comienza a escanear los empaques que deseas agregar.",
  [transferPackingDialog.SUBTITLE_FOR_KITCHEN]:
    "Valida los empaques escaneados.",
  [transferPackingDialog.SUBTITLE_DELETE_LABELS]:
    "Escanea los empaques que deseas eliminar.",
  [transferPackingDialog.RESUME]: "Resumen de traslados",
  [transferPackingDialog.SUPPLY]: "Insumo",
  [transferPackingDialog.TOTAL_QUANTITY]: "Cantidad total",
  [transferPackingDialog.CONTINUE]: "Continuar",
  [transferPackingDialog.CANCEL]: "Cancelar",
  [transferPackingDialog.REQUESTED_QUANTITY]: "Cantidad solicitada",
  [transferPackingDialog.SERVICE_LEVEL]: "Nivel de servicio",
  [transferPackingDialog.QUANTITY_SENT]: "Cantidad enviada",
  [transferPackingDialog.CONFIRMED_TRANSFERS_TITLE]: "Traslados Confirmados",
  [transferPackingDialog.CONFIRMED_TRANSFERS_MESSAGE]:
    "Las cantidades enviadas a las cocinas se confirmaron con éxito en el sistema de inventarios.",

  [transferOrdersPackedReviewTable.SKU]: "Sku",
  [transferOrdersPackedReviewTable.NAME]: "Nombre",
  [transferOrdersPackedReviewTable.UNIT]: "Unidad",
  [transferOrdersPackedReviewTable.ESTIMATED_QUANTITY]: "Cantidad estimada",
  [transferOrdersPackedReviewTable.PACKED_QUANTITY]: "Cantidad empacada",
  [transferOrdersPackedReviewTable.QUANTITY_TO_SEND]: "Cantidad a enviar",
  [transferOrdersPackedReviewTable.SERVICE_LEVEL]: "Nivel de servicio",
  [transferOrdersPackedReviewTable.STATUS]: "Estatus",
  [transferOrdersPackedReviewTable.COMPLETE]: "Completo",
  [transferOrdersPackedReviewTable.PARTIAL]: "Parcial",
  [transferOrdersPackedReviewTable.NO_SENT]: "No enviado",
  [transferOrdersPackedReviewTable.ALL_TITLE]: "Mostrar Todos",
  [transferOrdersPackedReviewTable.COMPLETE_TITLE]: "Insumos Completos",
  [transferOrdersPackedReviewTable.PARTIAL_TITLE]: "Insumos Parciales",
  [transferOrdersPackedReviewTable.NO_SENT_TITLE]: "Insumos no Enviados",

  [countingTypeSelector.LABEL]: "Tipo de conteo",

  [physicalCountSchedule.HEADER_PAGE_TITLE]: "Programar inventarios",
  [physicalCountSchedule.HEADER_PAGE_SUBTITLE]:
    "Programación de de conteos de inventario por ubicación",
  [physicalCountSchedule.STEP_LABEL_1]: "Tipo de conteo",
  [physicalCountSchedule.STEP_LABEL_2]: "Ubicación",
  [physicalCountSchedule.IMPORT]: "Importar",
  [physicalCountSchedule.STEP_LABEL_3]: "Fechas",
  [physicalCountSchedule.STEP_LABEL_4]: "Insumos",
  [physicalCountSchedule.STEP_LABEL_FINAL]: "Confirmar",
  [physicalCountSchedule.PREVIOUS]: "Anterior",
  [physicalCountSchedule.CONTINUE]: "Continuar",
  [physicalCountSchedule.FINISH]: "Finalizar",
  [physicalCountSchedule.ADD_LOCATION]: "Agregar Ubicación",
  [physicalCountSchedule.ACTIONS]: "Acciones",
  [physicalCountSchedule.STEP_2_TABLE_EMPTY]:
    "Aùn no se han agregado ubicaciones",
  [physicalCountSchedule.SINGLE_DATE]: "Fecha única",
  [physicalCountSchedule.DATE_RANGE]: "Rango de fechas",
  [physicalCountSchedule.COUNT_DATE]: "Fecha del conteo",
  [physicalCountSchedule.START_DATE]: "Fecha inicio",
  [physicalCountSchedule.END_DATE]: "Fecha fin",
  [physicalCountSchedule.SCHEDULE_DATE]: "Programar fecha",
  [physicalCountSchedule.SCHEDULE_DATES]: "Programar fechas",
  [physicalCountSchedule.DATE]: "Fecha",
  [physicalCountSchedule.STEP_3_TABLE_EMPTY]: "Aùn no se han agregado fechas",
  [physicalCountSchedule.STEPS_RESET]: "Reiniciar",
  [physicalCountSchedule.STEPS_COMPLETED]:
    "Todos los pasos han sido completados",
  [physicalCountSchedule.STEP_1_INFO]:
    "Selecciona el tipo de conteo físico que deseas programar.",
  [physicalCountSchedule.STEP_2_INFO]:
    "Selecciona las ubicaciones a las que deseas programar el conteo físico.",
  [physicalCountSchedule.STEP_IMPORT_INFO]:
    "Selecciona o arrastra la plantilla de reconteo",
  [physicalCountSchedule.STEP_3_INFO]:
    "Selecciona las fechas que deseas programar para el conteo de inventario físico.",
  [physicalCountSchedule.STEP_4_INFO]:
    "Selecciona los insumos que deseas programar el conteo físico.",
  [physicalCountSchedule.STEP_FINAL_INFO]:
    "Valida los datos antes de finalizar la programación de conteo físico.",
  [physicalCountSchedule.STEP_4_TABLE_EMPTY]: "No hay insumos que mostrar",
  [physicalCountSchedule.SUCCESS_TITLE]: "Conteo programado",
  [physicalCountSchedule.SUCCESS_SUBTITLE]:
    "El conteo físico se ha programado con éxito.",
  [physicalCountSchedule.ERROR_TITLE]: "Conteo no programado",
  [physicalCountSchedule.ERROR_SUBTITLE]:
    "Ups... Ha ocurrido un error. El conteo físico no se ha programado.",
  [physicalCountSchedule.ARCHIVE_ERROR]: "Error al archivar conteo(s)",
  [physicalCountSchedule.ARCHIVE_SUCCESS]:
    "Conteo(s) archivado(s) exitosamente",

  [productionPreparation.INSTRUCTIONS_FOR_PREPARATION]:
    "Instrucciones de preparación",
  [productionPreparation.NO_PREPARATION_INSTRUCTIONS_FOUND]:
    "No se han encontrado instrucción de preparación",
  [productionPreparation.PRINT_PREAPARATION]: "Imprimir preparación",

  [purchaseOrderLine.TITLE]: "Detalle de orden de compra",
  [purchaseOrderLine.DELETE_RECEIPT]: "Eliminar recepción",
  [purchaseOrderLine.DIALOG_CONFIRM_TITLE]: "Eliminar recepción",
  [purchaseOrderLine.DIALOG_CONFIRM_MESSAGE]:
    "Se eliminará la recepción y los productos parciales y pendientes ya no serán entregados en la ubicación. Por favor confirma si deseas continuar.",
  [purchaseOrderLine.DIALOG_SUCCESS_TITLE]: "Recepción eliminada",
  [purchaseOrderLine.DIALOG_SUCCESS_MESSAGE]:
    "La recepción se eliminó correctamente. Los productos faltantes ya no serán recibidos por la ubicación asignada a la orden de compra.",
  [purchaseOrderLine.DIALOG_ERROR_TITLE]: "Recepción no eliminada",
  [purchaseOrderLine.DIALOG_ERROR_MESSAGE]: "Ha ocurrido un error",
  [purchaseOrderLine.FIND_PRODUCT_TITLE]: "Buscar producto",
  [purchaseOrderLine.FIND_PRODUCT_LABEL]: "ID producto / Nombre producto",
  [purchaseOrderLine.RECEIPT_PENDING]: "Por Recibir: {{pendingLines}} Insumos",
  [purchaseOrderLine.RECEIPT_COMPLETE]: "Recepción Completa",
  [purchaseOrderLine.UPDATE_RECEIVED_QUANTITY_ERROR]:
    "Supera por {{num}} a la solicitada",

  [purchaseOrderCreation.TITLE]: "Generar órdenes de compra",
  [purchaseOrderCreation.DESCRIPTION]:
    "Generación y envìo de órdenes de compra a proveedores",
  [purchaseOrderCreation.CREATE_TITLE]: "Crear orden de compra",
  [purchaseOrderCreation.CREATE_MASSIVE_BUTTON]: "Carga masiva",
  [purchaseOrderCreation.CREATE_INDIVIDUAL_BUTTON]: "Carga individual",
  [purchaseOrderCreation.CONFIRM_BUTTON]: "Confirmar órdenes de compra",
  [purchaseOrderCreation.DETECTED_ORDERS_TITLE]: "Órdenes detectadas",
  [purchaseOrderCreation.NO_ORDENES_DETECTED]:
    "No se han detectado órdenes de compra",
  [purchaseOrderCreation.NO_ORDERS_FOR_SEARCH]:
    "No se encontraron concidencias para la búsqueda",
  [purchaseOrderCreation.CREATE_MASSIVE_MODAL_TITLE]:
    "Crear orden de compra masivamente",
  [purchaseOrderCreation.CONFIRM_MULTIPLE_MODAL_TITLE]:
    "Confirmar órdenes de compras",
  [purchaseOrderCreation.CONFIRM_MULTIPLE_MODAL_SUBTITLE]:
    "Confirmar las siguientes ordenes de compra:",
  [purchaseOrderCreation.ESTIMATED_DELIVERY_DATE_LABEL]: "Entrega estimada",
  [purchaseOrderCreation.VALIDATE_ITEM_MESSAGE]:
    "Agrega o elimina productos para esta orden de compra",
  [purchaseOrderCreation.CONFIRM_AND_SEND_BUTTON]: "Confirmar y enviar",
  [purchaseOrderCreation.DELETE_ORDER]: "Eliminar orden",
  [purchaseOrderCreation.UPDATE_ORDER_MODAL_TITLE]: "Cambiar fecha de entrega",
  [purchaseOrderCreation.UPDATE_ORDER_MODAL_INFO]:
    "Selecciona la fecha estimada para la orden de compra",
  [purchaseOrderCreation.UPDATE_ORDER_MODAL_BUTTON]:
    "Actualizar la fecha estimada",
  [purchaseOrderCreation.ESTIMATED_DATE_LABEL]: "Fecha estimada",
  [purchaseOrderCreation.INITIAL_ESTIMATED_DATE_LABEL]:
    "Fecha estimada inicial",
  [purchaseOrderCreation.RECEIPT_DATE_LABEL]: "Fecha de recepción",
  [purchaseOrderCreation.DELETE_LINE_MODAL_TITLE]: "Eliminar producto",
  [purchaseOrderCreation.DELETE_PURCHASE_ORDER_TITLE]:
    "Eliminar pedido de compra",
  [purchaseOrderCreation.DELETE_PURCHASE_ORDER_MESSAGE]:
    "Desea eliminar el pedido de compra para el proveedor ",
  [purchaseOrderCreation.DELETE_LINE_MODAL_SUBTITLE]:
    "Eliminar productos de la orden de compra:",
  [purchaseOrderCreation.ADD_LINE_MODAL_TITLE]: "Agregar producto",
  [purchaseOrderCreation.ADD_LINE_MODAL_INFO]:
    "Agrega la informacion para actualizar la orden de compra",
  [purchaseOrderCreation.ADD_LINE_MODAL_BUTTON]: "Agregar a la orden de compra",
  [purchaseOrderCreation.SEND_ORDER_MODAL_TITLE]: "Enviar Orden de compra",
  [purchaseOrderCreation.SEND_ORDER_MODAL_SUBTITLE]:
    "Confirmar la siguiente ordenes de compra:",
  [purchaseOrderCreation.SEND_ORDER_MODAL_INFO]:
    "Agrega la informacion para enviar la orden de compra",
  [purchaseOrderCreation.SEND_LOADING_MODAL]: "Confirmando orden de compra",
  [purchaseOrderCreation.SEND_ORDER_MODAL_BUTTON]: "Enviar orden de compra",
  [purchaseOrderCreation.ORDER_CONFIRMED]: "Orden confirmada",
  [purchaseOrderCreation.ORDERD_CONFIRMED_OK]: "Ok",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_TITLE]: "Agregar Orden de Compra",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_INFO]:
    "Agrega la informacion para crear la orden de compra",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_BUTTON]: "Agregar",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_LOCATION_LABEL]:
    "Ubicación de entrega",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_VENDOR_LABEL]:
    "Código / Nombre del proveedor",
  [purchaseOrderCreation.CREATE_ORDER_MODAL_INVOICE_LABEL]: "Número de factura",
  [purchaseOrderCreation.DOCUMENT_LIST_BUTTON_LABEL]: "Ver documentos",
  [purchaseOrderCreation.RESULT_DIALOG_TITLE]:
    "Resultado de la creación de la orden de compra",
  [purchaseOrderCreation.RESULT_DIALOG_ERROR_VENDOR]:
    "No se encontró el proveedor con código {{code}} en la orden con número {{invoiceNo}}",
  [purchaseOrderCreation.RESULT_DIALOG_ERROR_SKU]:
    "No se encontró el insumo con SKU {{code}} en la orden con número {{invoiceNo}}",
  [purchaseOrderCreation.RESULT_NO_ERRORS]:
    "Todas las órdenes de compra se crearon exitosamente",

  [productionDialog.WEIGHT]: "PESO",
  [productionDialog.BATCH]: "LOTE",
  [productionDialog.TECHNICAL_NAME]: "NOMBRE TÉCNICO",
  [productionDialog.NAME]: "PRODUCTO",
  [productionDialog.PRODUCTION_DATE]: "FECHA DE PRODUCCIÓN",
  [productionDialog.EXPIRATION_DATE]: "FECHA DE VENCIMIENTO",
  [productionDialog.CONSERVATION]: "CONSERVACIÓN",
  [productionDialog.MAXIMUM_DURATION]: "DURACIÓN MÁXIMA",
  [productionDialog.RESPONSIBLE]: "ENCARGADO",
  [productionDialog.MANUFACTURER]: "FABRICANTE",
  [productionDialog.ADDRESS]: "DIRECCIÓN",
  [productionDialog.WEEK]: "SEMANA",
  [productionDialog.DAYS]: "Días",

  [errorCode.TRANSFER_WITHOUT_STOCK]:
    "No existe suficiente inventario para los siguientes items:",
  [errorCode.PARSING_EXCEPTION]: "Error procesando el xml intente de nuevo",
  [errorCode.VENDOR_NOT_FOUND]: "el proveedor no se encuentra registrado",

  [massiveTransfer.SELECT_MOVE_FILE]:
    "Selecciona o arrastra la plantilla con los traslados.",
  [massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_BUTTON]:
    "Revisar traslado pendiente",
  [massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_TITLE]: "Traslado Pendiente",
  [massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_SUBTITLE]:
    "Tienes una revisión de traslado pendiente. Para generar un traslado nuevo primero finaliza el actual.",
  [massiveTransfer.LOAD_TRANSFER_SUCCESS_TITLE]: "Transferencias creadas",
  [massiveTransfer.LOAD_TRANSFER_SUCCESS_MESSAGE]:
    "Las ordenes de transferencia han sido creadas exitosamente",

  [dailyAdjustmentsReport.FILTERS_LABEL]: "Filtrar movimientos diarios",
  [dailyAdjustmentsReport.SEND_TO_VALIDATION_MESSAGE]:
    "Se enviarán {{supplyQuantity}} insumos a revisión con el Jefe de Cocina para recibir una confirmación de la cantidad reportada. Por favor confirma si deseas continuar.",
  [dailyAdjustmentsReport.APPROVED]: "Autorizado",
  [dailyAdjustmentsReport.VALIDATED]: "Validado",

  [productionScannedError.LABEL_NOT_FOUND]: "Etiqueta no encontrada",
  [productionScannedError.ALREADY_SCANNED]: "Etiqueta ya escaneada",
  [productionScannedError.INTERNAL_ERROR]: "Error al escanear",
  [productionScannedError.WITHOUT_HISTORIAL]: "Sin historial",
  [productionScannedError.CAN_NOT_DELETE]: "No se puede eliminar",
  [productionScannedError.SELECT_THE_KITCHEN]: "Debes seleccionar la cocina",
  [productionScannedError.SELECT_THE_COUNTING]: "Debes seleccionar el conteo",
  [productionScannedError.SELECT_ORDER_AND_KITCHEN]:
    "Debes seleccionar la orden y la cocina",

  [productionLogReason.CREATED]: "Produccion empacada",
  [productionLogReason.COUNTING]: "Producción inventariada",
  [productionLogReason.TRANSFER_PACKING]: "Producción transferida",
  [productionLogReason.TRANSFER_RECEIPT]: "Producción recepcionada",
  [transferOrderModule.TITLE]: "Gestionar traslados",
  [transferOrderModule.DESCRIPTION]:
    "Revisión de traslados programados entre ubicaciones.",
  [transferOrderModule.FILTER_ORDERS]: "Filtrar traslados",
  [transferOrderModule.FIND_ORDERS]: "Buscar traslado",
  [transferOrderModule.FIND_ORDER_LABEL]: "ID traslado / Nombre ubicación",
  [transferOrderModule.TRANSFER_ID]: "ID traslado",
  [transferOrderModule.DESTINATION_NAME]: "Nombre ubicación",
  [transferOrderModule.ALL]: "TODOS",
  [transferOrderModule.RELEASED]: "ALISTAMIENTO",
  [transferOrderModule.IN_PROCESS]: "ALISTAMIENTO",
  [transferOrderModule.PACKED]: "EMPACADO",
  [transferOrderModule.IN_TRANSIT]: "EN TRÁNSITO",
  [transferOrderModule.RECEIVED]: "RECIBIDO",
  [transferOrderDetails.TITLE]: "Detalle de traslado",
  [transferOrderDetails.FIND_SUPPLY]: "Buscar insumo",
  [transferOrderDetails.FIND_SUPPLY_LABEL]: "ID insumo / Nombre insumo",
  [takePhotoLocale.RETRY]: "Volver a intentar",
  [takePhotoLocale.CONTINUE]: "Continuar",
  [takePhotoLocale.TAKE_PHOTO]: "Toma foto",
  [takePhotoLocale.TAKE_PHOTO_AGAIN]: "Volver a Tomar Foto",
  [takePhotoLocale.BAG_PRODUCTS]: "Productos en la bolsa",
  [takePhotoLocale.TITLE]: "Tomar foto",

  [purchaseOrderConciliation.TITLE]: "Conciliar Órdenes de Compra",
  [purchaseOrderConciliation.DESCRIPTION]:
    "Conciliación de órdenes de compra solicitadas a proveedores.",

  [productionSchedule.EMPTY_TITLE]: "No tienes producciones programadas",
  [productionSchedule.EMPTY_DESCRIPTION]:
    "Agrega una producción para esta franja horaria",
  [productionSchedule.DELETE_TITLE]: "Eliminar producción",
  [productionSchedule.DELETE_MESSAGE]:
    "{{productionName}} se eliminará del plan de producción. ¿Por favor confirma si deseas continuar?.",
  [productionSchedule.TITLE_FINISHED]: "Producción finalizada",
  [productionSchedule.TITLE_IN_PROCESS]: "Producción en proceso",
  [productionSchedule.TITLE_SCHEDULED]: "Producción programada",

  [supplyingManagement.MODULE_TITLE]: "Abastecimiento de CP",
  [supplyingManagement.MODULE_DESCRIPTION]:
    "Preparación de insumos para ubicaciones.",
  [supplyingManagement.RESUME]: "Resumen de requisiciones",
  [supplyingManagement.TOTAL]: "Totales",
  [supplyingManagement.PARTIAL]: "Parciales",
  [supplyingManagement.COMPLETED]: "Completadas",
  [supplyingManagement.SEARCH]: "Buscar requisición",
  [supplyingManagement.SEARCH_LABEL]: "No. de requisición",
  [supplyingManagement.COMPLETE]: "Completada",
  [supplyingManagement.PENDING]: "Pendiente",
  [supplyingManagement.CP_NAME]: "Nombre del CP",
  [supplyingManagement.SUPPLY_VIEW]: "Vista por insumos",
  [supplyingManagement.SLOT_VIEW]: "Vista por franjas horarias",
  [supplyingManagement.ALL_SLOTS]: "Mostrar todas",
  [supplyingManagement.ALL_ITEMS]: "Mostrar Todos",
  [supplyingManagement.ADD_EXTRA_SUPPLY]: "Enviar insumo extra",
  [supplyingManagement.ADD_EXTRA_SUPPLY_INFO]:
    "Ingresa los datos del insumo que deseas agregar.",
  [supplyingManagement.OVER_QUANTITY]: "Cantidad sobrante",
  [supplyingManagement.SUGGESTED_QUANTITY]: "Cantidad sugerida",

  [supplyReception.TITLE]: "Recepción de abastecimiento",
  [supplyReception.RECEIVE_SUPPLIES_TAB]: "Recibir insumos",
  [supplyReception.REQUEST_SUPPLIES_TAB]: "Solicitar insumos",
  [supplyReception.REQUISITION_NUMBER_LABEL]: "No. Requisición",
  [supplyReception.TOTAL_REQUISITION_LABEL]: "Requisiciones Totales",
  [supplyReception.PACKED_REQUISITION_LABEL]: "Requisiciones Empacadas",
  [supplyReception.COMPLETED_REQUISITION_LABEL]: "Requisiciones Completadas",
  [supplyReception.LOCATION_NAME_LABEL]: "Nombre del Cedis",
  [supplyReception.DATE_LABEL]: "Fecha Requisición",
  [supplyReception.PACKED]: "EMPACADO",
  [supplyReception.SENT_QUANTITY]: "Cantidad enviada",
  [supplyReception.RECEIVED_QUANTITY]: "Cantidad recibida",
  [supplyReception.REQUESTED_QUANTITY]: "Cantidad solicitada",
  [supplyReception.NOVELTY]: "Novedad",
  [supplyReception.CONFIRM_SUPPLY]: "Confirmar Abastecimiento",
  [supplyReception.NOVELTY_MODAL_TITLE]: "Actualizar Insumo",
  [supplyReception.NOVELTY_MODAL_INFO]:
    "Actualiza los detalles del insumo que estás recibiendo.",
  [supplyReception.MISSING_REASON_LABEL]: "Motivo de Faltante",
  [supplyReception.CONFIRM_SUPPLY_MODAL_TITLE]: "Resumen de Abastecimiento",
  [supplyReception.CONFIRM_SUPPLY_MODAL_SUBTITLE]:
    "Valida el resumen del abastecimiento para continuar.",
  [supplyReception.ITEMS]: "Artículos",
  [supplyReception.PARTIAL_PRODUCTS]: "Productos Parciales",
  [supplyReception.MISSING_PRODUCTS]: "Productos Faltantes",
  [supplyReception.SPARE_PRODUCTS]: "Productos Sobrantes",
  [supplyReception.COMPLETE_PRODUCTS]: "Productos Completos",
  [supplyReception.NO_SUPPLIES]:
    "No hay insumos ingresados para hacer la solicitud",
  [supplyReception.NO_SUPPLIES_INFO]:
    "Selecciona los insumos y agrega la cantidad que deseas solicitar.",
  [supplyReception.CONFIRM_SUPPLY_CREATION_INFO]:
    "Confimrar la solicitud de insumos",

  [missingReason.DAMAGED_PRODUCT]: "Producto dañado",
  [missingReason.MISSING_PRODUCT]: "Producto faltante",
  [missingReason.PRODUCT_EXPIRED]: "Producto vencido",
  [missingReason.SPARE_PRODUCT]: "Producto sobrante",

  [productionLiberation.TITLE]: "Liberación de producciones",
  [productionLiberation.FINISH_DATE]: "Fecha de finalización",
  [productionLiberation.LIBERATE_ACTION_BUTTON]: "Liberar",
  [productionLiberation.RESULT_APPROVEMENT_LABEL]: "Resultado de Validación",
  [productionLiberation.APPROVED]: "Aprobado",
  [productionLiberation.APPROVED_WITH_NOVELTY]: "Aprobado con novedad",
  [productionLiberation.REJECTED]: "Rechazado",
  [productionLiberation.NOVELTY_LABEL]:
    "Ingresa los comentarios o novedades de la validación.",
  [productionLiberation.EVALUATE_BUTTON]: "Evaluar",
  [productionLiberation.ORGANOLEPTIC_MODAL_TITLE]: "Evaluación de calidad",
  [productionLiberation.ORGANOLEPTIC_MODAL_SUBTITLE]:
    "Selecciona si el organoléptico cumple o no con las características de calidad",
  [productionLiberation.ORGANOLEPTIC_MODAL_SUBTITLE_2]:
    "Indica los motivos por los cuales no se cumplieron los siguientes organolepticos de esta producción.",
  [productionLiberation.PRODUCTION_ORGANOLEPTIC_ERROR]:
    "No se encontraron los detalles de la producción",

  [organoleptics.APPEARENCE]: "Aspecto",
  [organoleptics.COLOR]: "Color",
  [organoleptics.SMELL]: "Olor",
  [organoleptics.TASTE]: "Sabor",
  [organoleptics.TEXTURE]: "Textura",
  [organoleptics.VISCOSITY]: "Viscosidad",

  [bcIntegrations.INVENTORY_ADJUSTMENTS]: "Ajustes de inventario",
  [bcIntegrations.BATCHES]: "Batches",
  [bcIntegrations.PURCHASE_ORDERS]: "Ordenes de compra",
  [bcIntegrations.PURCHASE_ORDERS_RECEIPT]: "Recepcion de ordenes de compra",
  [bcIntegrations.TRANSFER]: "Transferencias",
  [bcIntegrations.TRANSFER_RECEIPT]: "Recepción de transferencias",
  [bcIntegrations.SALES]: "Ventas",
  [bcIntegrations.CONCILIATION]: "Conciliación",
  [bcIntegrations.PURCHASE_ORDER_MODIFY]: "Actualización de PO",
  [bcIntegrations.VENDORS]: "Proveedores",

  [vendor.VENDOR_SUPPLY_MODULE_TITLE]: "Control de Proveedores",
  [vendor.VENDOR_SUPPLY_MODULE_DESCRIPTION]:
    "Gestión de proveedores registrados por país.",
  [vendor.PAYMENT_DAYS]: "Días de crédito",
  [vendor.PAYMENT_METHOD]: "Método de pago",
  [vendor.DOCUMENT_TYPE]: "Tipo de documento",
  [vendor.SUPPLIES_QUANTITY]: "Cantidad de insumos",
  [vendor.VENDORS_QUANTITY]: "Cantidad de proveedores",
  [vendor.SHOW_SUPPLIES]: "Ver insumos",
  [vendor.SHOW_VENDORS]: "Ver proveedores",
  [vendor.VENDOR_SUPPLIES_TITLE]: "Insumos por proveedor",
  [vendor.SUPPLY_VENDORS_TITLE]: "Proveedores por insumo",
  [vendor.SUPPLY_NAME]: "Nombre del insumo",
  [vendor.VENDOR_SUPPLY_NAME]: "Descripción del proveedor",
  [vendor.VENDOR_UNIT]: "Und. proveedor",
  [vendor.SUPPLY_QUANTITY]: "Presentación",
  [vendor.SUPPLY_UNIT]: "Und. KIS",
  [vendor.VENDOR_UNIT_PRICE]: "Costo und. prov.",
  [vendor.SUPPLY_UNIT_PRICE]: "Costo und. KIS",
  [vendor.VENDOR_MIN_ORDER_QUANTITY]: "Cant. min. para ordenar",
  [vendor.VENDOR_ORDER_PER_WEEK]: "Pedidos por semana",
  [vendor.CATEGORY]: "Clasificación",
  [vendor.VIEW_BY_VENDORS]: "Mostrar proveedores",
  [vendor.VIEW_BY_SUPPLIES]: "Mostrar insumos",
  [vendor.LEAD_TIME]: "Tiempo de espera",
  [vendor.SUPPLY_VALIDATOR_LABEL_LONG]:
    "El precio unitario ({{price}}) del insumo {{sku}} (SKU), se encuentra fuera del rango | Rango entre {{minPrice}} y {{maxPrice}} | El precio acordado es {{vendorPrice}}",
  [vendor.SUPPLY_VALIDATOR_LABEL_SHORT]:
    "El precio acordado es {{vendorPrice}} | Rango entre {{minPrice}} y {{maxPrice}}",
  [vendor.SUPPLY_VALIDATOR_LABEL_SHORT_2]:
    "El precio acordado es {{vendorPrice}}",
  [vendor.SUPPLY_VALIDATOR_CHIP_LABEL]:
    "Hay {{number}} variacion(es) de precio que debe validar.",
  [vendor.PURCHASE_EMAIL]: "Email de compras",
  [vendor.ACCOUNTING_EMAIL]: "Email de contabilidad",

  [vendorMeasureUnit.KG]: "KILOGRAMO",
  [vendorMeasureUnit.PZ]: "PIEZA",
  [vendorMeasureUnit.BOX]: "CAJA",
  [vendorMeasureUnit.BAG]: "BULTO",
  [vendorMeasureUnit.BUCKET]: "BALDE",
  [vendorMeasureUnit.CAN]: "BOTE",
  [vendorMeasureUnit.GALLON]: "GALÓN",
  [vendorMeasureUnit.PACKAGE]: "PAQUETE",
  [vendorMeasureUnit.DRUM]: "BIDÓN",
  [vendorMeasureUnit.TIN]: "LATA",
  [vendorMeasureUnit.ML]: "MILILITRO",
  [vendorMeasureUnit.LITER]: "LITRO",
  [vendorMeasureUnit.BAG_SMALL]: "BOLSA",
  [vendorMeasureUnit.UN]: "UNIDAD",
  [vendorMeasureUnit.BED]: "CAMA",
  [vendorMeasureUnit.THOUSAND]: "MILLAR",
  [vendorMeasureUnit.G]: "GRAMO",
  [vendorMeasureUnit.ROLL]: "ROLLO",
  [vendorMeasureUnit.JUG]: "GARRAFÓN",
  [vendorMeasureUnit.JAR]: "FRASCO",
  [vendorMeasureUnit.SACK]: "COSTAL",
  [vendorMeasureUnit.BOTTLE]: "BOTELLA",
  [vendorMeasureUnit.CRATE]: "REJA",
  [vendorMeasureUnit.DOME]: "DOMO",
  [vendorMeasureUnit.COVER]: "TAPA",
  [vendorMeasureUnit.TETRAPACK]: "TETRAPACK",
  [vendorMeasureUnit.ATADO]: "ATADO",

  [commentArea.TITLE]: "Sección de Comentarios",
  [commentArea.SHOW_ANSWER]: "Mostrar respuestas",
  [commentArea.HIDE_ANSWER]: "Ocultar respuestas",
  [commentArea.COMMENT_LABEL]: "Comentario",
  [commentArea.ANSWER_LABEL]: "Respuesta",

  [bulkLoadAction.CREATE]: "Insertar",
  [bulkLoadAction.MODIFY]: "Modificar",
  [bulkLoadAction.NOTHING]: "Sin acción",
  [bulkLoadAction.NOTHING_BY_ERROR]: "Sin acción por error",

  [bulkLoadModule.TABLE_INFO]:
    "Vista previa de importación de registros. Por favor confirme los datos y guarde.",
  [bulkLoadModule.REQUIRED_COLUMNS]:
    "Las {{num}} primeras columnas son obligatorias",

  [bulkLoadError.VENDOR_CODE_NOT_FOUND]: "Código del proveedor no encontrado.",
  [bulkLoadError.PRODUCT_NOT_FOUND]: "SKU no encontrado.",
  [bulkLoadError.KITCHEN_NOT_FOUND]: "Cocina no encontrada.",
  [bulkLoadError.VENDOR_TYPE_NOT_FOUND]: "Tipo no encontrado.",
  [bulkLoadError.ALL_FIELDS_MUST_BE_FILLED_TO_INSERT]:
    "Para insertar, todos los campos deben de estar llenos.",
  [bulkLoadError.UNIDENTIFIED]: "Error no identificado.",
  [bulkLoadError.VENDOR_UNIT_NOT_FOUND]: "Unidad del proveedor no encontrada.",

  [replacementModule.TITLE]: "Transformaciones",
  [replacementModule.REPLACE]: "Reemplazo",
  [replacementModule.QUANTITY_FINAL]: "Cantidad final",
  [replacementModule.QUANTITY_INITIAL]: "Cantidad inicial",
  [replacementModule.AUTHORIZE_TITLE]: "Autorizar transformaciones",
  [replacementModule.DESCRIPTION]: "Registro de solicitudes de transformación",
  [replacementModule.AUTHORIZE_DESCRIPTION]:
    "Autorizar solicitudes de transformación",

  [inventoryMovement.EMPTY_TABLE]: "No se encontraron movimientos",
};
