import {
  convertDateUtc,
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import ListAltIcon from "@mui/icons-material/ListAlt";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { commons, transferModule } from "app/i18n/types";
import { ReceivedOrder } from "core/purchaseOrders/entities/ReceiptOrder";
import { receivedOrders } from "core/purchaseOrders/repositories/http/purchaseOrders";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText, getUserImage } from "utils/general";
import { ReceiptReceivedLinesModal } from "../ReceivedLines";
import ReceiptTypeSelector from "../TypeSelector";

interface Props {
  kitchenId: string;
  filter: string;
}

export const ReceiptReceivedOrders: FunctionComponent<Props> = (props) => {
  const { kitchenId, filter } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ReceivedOrder[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<ReceivedOrder | null>(
    null
  );
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [filteredItems, setFilteredItems] = useState<ReceivedOrder[]>([]);

  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));

  const [initDate, setInitDate] = useState<Date>(yesterday);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectedType, setSelectedType] = useState<string>("ALL");

  const filterValidator = (el: ReceivedOrder): boolean => {
    if (!filter) return true;
    return (
      compareSearchText(el.code, filter) ||
      compareSearchText(el.type, filter) ||
      compareSearchText(el.vendorName, filter)
    );
  };

  useEffect(() => {
    setFilteredItems(data?.filter((el) => filterValidator(el)) ?? []);
  }, [data, filter]);

  const handleOpenDetail = (item: KOSRowData) => {
    setSelectedOrder(item as ReceivedOrder);
    setOpenDetail(true);
  };

  const onClose = () => {
    setOpenDetail(false);
  };

  const getData = () => {
    if (!kitchenId) return;
    setLoading(true);
    receivedOrders(kitchenId, initDate, endDate)
      .then((response: ReceivedOrder[]) => {
        setData(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [kitchenId, initDate, endDate]);

  const handleDate = (newValue: Date | null, type: "init" | "end") => {
    setSelectedType("ALL");
    if (newValue) {
      if (type === "init") {
        setInitDate(newValue);
      } else {
        setEndDate(newValue);
      }
    }
  };

  const filterReceiptType = (type: string) => {
    setSelectedType(type);
    if (type !== "ALL") {
      setFilteredItems(data?.filter((el) => el.type === type) ?? []);
    } else {
      setFilteredItems(data?.filter((el) => filterValidator(el)) ?? []);
    }
  };

  const getHeaders = (): KOSBaseTableHeader[] => {
    const receivedBy = (item: KOSRowData) => {
      if (!item.receivedById) {
        return <>--</>;
      }
      return (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Avatar
            alt={item.receivedByName}
            sx={{ width: 32, height: 32, mr: 1 }}
            src={getUserImage(item.receivedById)}
          />
          <Typography>{item.receivedByName}</Typography>
        </Box>
      );
    };

    const detailButton = (item: KOSRowData) => (
      <Button
        onClick={() => handleOpenDetail(item)}
        variant="outlined"
        startIcon={<ListAltIcon />}
        sx={{
          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
        }}
      >
        {t(commons.SHOW_DETAILS)}
      </Button>
    );
    return [
      {
        label: t(commons.CODE),
        field: "code",
      },
      {
        label: t(commons.SUPPLIER),
        field: "vendorName",
      },
      {
        label: t(commons.TYPE),
        text: (item: KOSRowData) => t("commons.receiptType." + item.type),
      },
      {
        label: t(transferModule.RECEIVED_AT),
        text: (item: KOSRowData) =>
          convertDateUtc(item.receivedAt, "MM/dd/yyyy hh:mm a"),
      },
      {
        label: t(transferModule.RECEIVED_BY),
        align: "left",
        component: receivedBy,
      },
      {
        label: t(commons.DETAILS),
        component: detailButton,
      },
    ];
  };

  return (
    <Box>
      {selectedOrder && (
        <ReceiptReceivedLinesModal
          kitchenId={kitchenId}
          orderId={selectedOrder.id}
          onClose={onClose}
          open={openDetail}
          receiptOrder={selectedOrder}
        />
      )}
      <Grid container spacing={2} pb={3} alignItems="center">
        <Grid item md={6} lg={4} xs={12}>
          <Box display="flex" gap={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label={t(commons.INIT_DATE)}
                inputFormat="dd/MM/yyyy"
                value={initDate}
                maxDate={endDate}
                onChange={(val) => handleDate(val, "init")}
                renderInput={(params: any) => (
                  <TextField fullWidth={true} {...params} />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label={t(commons.FINAL_DATE)}
                inputFormat="dd/MM/yyyy"
                value={endDate}
                maxDate={new Date()}
                minDate={initDate}
                onChange={(val) => handleDate(val, "end")}
                renderInput={(params: any) => (
                  <TextField fullWidth={true} {...params} />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid item md={6} lg={4} xs={12}>
          <ReceiptTypeSelector
            setSelected={filterReceiptType}
            selected={selectedType}
          />
        </Grid>
      </Grid>

      <KOSBaseTable
        columns={getHeaders()}
        rows={{
          data: filteredItems ?? [],
          total: filteredItems.length,
          loading,
        }}
        pagination={{
          enable: true,
        }}
      />
    </Box>
  );
};
