import appConfig from "config/app";
import { InventoryResponse } from "core/physicalCount/entities/Counting";
import {
  CreatePurchaseLineResponse,
  CreatePurchaseOrderResponse,
  NewIndividualPurchaseOrderLine,
  NewPurchaseOrder,
  Purchase,
  PurchaseOrder,
  UpdateInvoiceNroOfReceiptResponse,
} from "core/purchaseOrders/entities/PurchaseOrder";

import {
  ExternalInvoice,
  ExternalInvoiceRequest,
  ImportInvoiceResponse,
  InvoiceResponse,
  NewInvoicePayload,
  PurchaseInvoice,
} from "core/purchaseOrders/entities/Invoice";

import { LocationType } from "utils/general";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export const getPurchaseOrders = async (
  locationType: LocationType,
  location: string
): Promise<Purchase[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/list/location/${location}/type/${locationType}`
    );
    const result: Purchase[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getPurchaseOrders | ${ex.message}`);
    return [];
  }
};

export const getPurchaseOrdersForConciliation = async (
  locationType: LocationType,
  location: string
): Promise<Purchase[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/conciliation/list/location/${location}/type/${locationType}`
    );
    const result: Purchase[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getPurchaseOrdersForConciliation | ${ex.message}`);
    return [];
  }
};

export const purchasesForAccount = async (
  locationType: LocationType,
  location: string
): Promise<Purchase[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/account/location/${location}/type/${locationType}`
    );
    const result: Purchase[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error purchasesForAccount | ${ex.message}`);
    return [];
  }
};

export const getPurchaseOrderLines = async (
  kitchenId: string,
  orderId: string,
  origin: string
): Promise<Purchase | undefined> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/line/list/${kitchenId}/${orderId}/${origin}`
    );
    const result: Purchase = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getPurchaseOrderLines | ${ex.message}`);
    return undefined;
  }
};

export const updateStatusOfPurchaseOrder = async (
  orderId: string,
  status: string
): Promise<Purchase | undefined> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/update/status`,
      { orderId, status }
    );
    const result: Purchase = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error updateStatusOfPurchaseOrder | ${ex.message}`);
    return undefined;
  }
};

export const updateMultiplePurchaseOrderStatus = async (
  orders: Array<number>,
  status: string,
  paymentDate: string | null
): Promise<Purchase | undefined> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/update/status/orders`,
      { orderIds: orders, status, paymentDate }
    );
    const result: Purchase = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error updateMultiplePurchaseOrderStatus | ${ex.message}`);
    return undefined;
  }
};

export const deletePurchaseOrderReceipt = async (
  kitchenId: string,
  receiptNo: string
): Promise<InventoryResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `receipts/delete`,
      { receiptNo, kitchenId, noSendAlert: true }
    );
    const result: InventoryResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error deletePurchaseOrder | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const createPurchaseOrders = async (
  purchaseOrders: NewPurchaseOrder[]
): Promise<CreatePurchaseOrderResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/create/many`,
      purchaseOrders
    );
    const result: CreatePurchaseOrderResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error createPurchaseOrders | ${ex.message}`);
    return { ok: false, ordersCreated: 0 };
  }
};

export const getPendingPurchaseOrderByUserId = async (): Promise<
  PurchaseOrder[]
> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/pending`
    );
    const result: PurchaseOrder[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getPurchaseOrderByUserIdAndStatus | ${ex.message}`);
    return [];
  }
};

export const updatePurchaseOrderEstimatedDate = async (
  purchaseOrderId: number,
  expectedReceiptAt: string
): Promise<InventoryResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/update/expected-receipt-at`,
      {
        purchaseOrderId,
        expectedReceiptAt,
      }
    );
    const result: InventoryResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error updatePurchaseOrder | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const releasePurchaseOrders = async (
  orders: Array<{ purchaseOrderId: number; email?: string }>
): Promise<InventoryResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/release`,
      {
        orders,
      }
    );
    const result: InventoryResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error updatePurchaseOrder | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const updatePurchaseOrderLineUnitPrice = async (
  purchaseOrderLineId: number,
  unitPrice: number
): Promise<InventoryResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/line/update-unit-price`,
      {
        purchaseOrderLineId,
        unitPrice,
      }
    );
    const result: InventoryResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error updatePurchaseOrderLineUnitPrice | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const updatePurchaseOrderLineQuantity = async (
  purchaseOrderLineId: number,
  quantity: number
): Promise<InventoryResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/line/update-quantity`,
      {
        purchaseOrderLineId,
        quantity,
      }
    );
    const result: InventoryResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error updatePurchaseOrderLineQuantity | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const deletePurchaseOrderLines = async (
  purchaseOrderLineIds: Array<number>
): Promise<InventoryResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/line/delete/many`,
      {
        purchaseOrderLineIds,
      }
    );
    const result: InventoryResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error deletePurchaseOrderLine | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const createPurchaseOrderLine = async (
  purchaseOrderLine: NewIndividualPurchaseOrderLine
): Promise<CreatePurchaseLineResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/line/create`,
      purchaseOrderLine
    );
    const result: CreatePurchaseLineResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error createPurchaseOrderLine | ${ex.message}`);
    return { ok: false, id: -1 };
  }
};

export const deletePurchase = async (
  purchaseOrderId: number
): Promise<InventoryResponse> => {
  const response = await postHttp(
    appConfig.kitchenInventory.apiBaseUrl,
    ApiVersion.V1,
    `purchase-order/delete/${purchaseOrderId}`
  );
  return await response.json();
};

export const updateLineReceivedQuantity = async (
  purchaseOrderLineId: number,
  quantity: number
): Promise<InventoryResponse> => {
  const response = await postHttp(
    appConfig.kitchenInventory.apiBaseUrl,
    ApiVersion.V1,
    `purchase-order/line/update-received-quantity`,
    { purchaseOrderLineId, quantity }
  );
  return await response.json();
};

export const updateInvoiceNroOfReceipt = async (
  purchaseOrderId: number,
  invoiceNo: string
): Promise<UpdateInvoiceNroOfReceiptResponse> => {
  const response = await postHttp(
    appConfig.kitchenInventory.apiBaseUrl,
    ApiVersion.V1,
    `purchase-order/update/invoice-of-receipt`,
    { id: purchaseOrderId, invoiceNo }
  );
  return await response.json();
};

export const searchInvoices = async (
  purchaseOrderId: string
): Promise<InvoiceResponse> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/invoice/order/${purchaseOrderId}/find`
    );
    const result: InvoiceResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error searchInvoices | ${ex.message}`);
    throw ex;
  }
};

export const linkInvoice = async (
  purchaseOrderId: string,
  invoice: ExternalInvoice
): Promise<ExternalInvoice> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/invoice/order/${purchaseOrderId}/link`,
      invoice
    );
    const result: ExternalInvoice = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error linkInvoice | ${ex.message}`);
    throw ex;
  }
};

export const unLinkInvoice = async (
  purchaseOrderId: string,
  legalDocumentNo: string
): Promise<ExternalInvoiceRequest> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/invoice/order/${purchaseOrderId}/unlink`,
      { legalDocumentNo }
    );
    const result: ExternalInvoiceRequest = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error unLinkInvoice | ${ex.message}`);
    throw ex;
  }
};

export const getByExternalId = async (
  externalId: string
): Promise<ExternalInvoice> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/invoice/order/external/${externalId}`
    );
    const result: ExternalInvoice = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getByExternalId | ${ex.message}`);
    throw ex;
  }
};

export const getByIds = async (ids: string): Promise<Purchase[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/ids/${ids}`
    );
    const result: Purchase[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getByIds | ${ex.message}`);
    throw ex;
  }
};

export const byLegalDocument = async (
  legalDocument: string
): Promise<Purchase> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/legal-document/${legalDocument}`
    );
    const result: Purchase = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getting by legal document | ${ex.message}`);
    throw ex;
  }
};

export const purchasesByInvoiceInfo = async (
  vendorCode: string,
  invoiceDate: Date,
  country: string
): Promise<Purchase[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/country/${country}/by-invoice-info`,
      {
        vendorCode,
        invoiceDate: `${invoiceDate}`,
      }
    );
    const result: Purchase[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getting by purchasesByInvoiceInfo | ${ex.message}`);
    throw ex;
  }
};

export const invoicesByQuery = async (
  country: string,
  vendorCode?: string,
  startDate?: string,
  endDate?: string,
  linked?: boolean
): Promise<PurchaseInvoice[]> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/invoice/query`,
      {
        country,
        vendorCode,
        startDate,
        endDate,
        linked,
      }
    );
    const result: PurchaseInvoice[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error invoiceByCountry | ${ex.message}`);
    throw ex;
  }
};

export const importInvoice = async (
  country: string,
  xml: string
): Promise<ImportInvoiceResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/invoice/import`,
      { country, xml }
    );
    const result: ImportInvoiceResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error importInvoice | ${ex.message}`);
    throw ex;
  }
};

export const saveInvoice = async (
  country: string,
  payload: NewInvoicePayload
): Promise<unknown> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/invoice/country/${country}/save`,
      payload
    );
    return await response.json();
  } catch (ex: any) {
    console.error(`Error saveInvoice | ${ex.message}`);
    throw ex;
  }
};

export const savePurchaseOrderAdjustment = async (
  amount: number,
  purchaseId: string
): Promise<unknown> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/id/${purchaseId}/amount/adjustment/${amount}`
    );
    return await response.json();
  } catch (ex: any) {
    console.error(`Error in savePurchaseOrderAdjustment | ${ex.message}`);
    throw ex;
  }
};
