import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Chip } from "@mui/material";
import { commons } from "app/i18n/types";
import {
  Purchase,
  PurchaseLine,
  PurchaseOrderStatus,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { updatePurchaseOrderLineUnitPrice } from "core/purchaseOrders/repositories/http/purchase";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import {
  OnChange,
  getPurchaseLineStatus,
  onChangeDefault,
} from "utils/general";
import { ColorType } from "utils/generalUI";
import {
  converterLineToItem,
  converterLineToPurchaseOrderLine,
  getIsEditionMode,
  unreceivedStatuses,
} from "utils/purchase";
import UpdateQuantityTextField from "../PurchaseLinesTable/UpdateQuantityTextField";
import UpdateRecivedQuantityTextField from "../PurchaseLinesTable/UpdateRecivedQuantityTextField";
import UpdateUnitPriceTextField from "../PurchaseLinesTable/UpdateUnitPriceTextField";
import NumberField from "../common/Field/Number";

interface Props {
  order: Purchase;
  items: PurchaseLine[];
  loading: boolean;
  setOrder: (order: Purchase) => void;
  selectedItems: KOSSelectedResult[];
  setSelectedItems: (selectedItems: KOSSelectedResult[]) => void;
  editionMode?: boolean;
  isConciliation?: boolean;
  orderId: string;
  kitchenId: string;
}

const PurchaseLineTable: FunctionComponent<Props> = (props) => {
  const {
    order,
    items,
    loading,
    isConciliation,
    selectedItems,
    setSelectedItems,
    editionMode,
    setOrder,
  } = props;
  const { kitchenId, orderId } = props;

  const origin = "KIS";
  const { t } = useTranslation();

  const [change, setChange] = useState<OnChange>(onChangeDefault);
  const isReceived = !unreceivedStatuses.includes(order.status);

  useEffect(() => {
    if (change.id) {
      const linesTmp = items.map((line) => {
        const valueToChange = Number(change.value ?? 0);
        if (line.id === change.id) {
          switch (change.label) {
            case "unitCost":
              return { ...line, unitCost: valueToChange };
            case "quantityReceive":
              return { ...line, quantityReceive: valueToChange };
            case "quantity":
              return { ...line, quantity: valueToChange };
          }
        }
        return line;
      });
      setOrder({ ...order, lines: linesTmp });
    }
  }, [change]);

  const updateQuantityReceive = [
    PurchaseOrderStatus.ERROR_IN_QUANTITY,
    PurchaseOrderStatus.ERROR_IN_PRICE,
    PurchaseOrderStatus.ERROR_IN_PRICE_AND_QUANTITY,
    PurchaseOrderStatus.PARTIAL,
    PurchaseOrderStatus.COMPLETE,
    PurchaseOrderStatus.WITHOUT_INVOICE,
    PurchaseOrderStatus.RECEIVED,
  ] as string[];
  const canUpdateQuantityReceive = updateQuantityReceive.includes(order.status);

  const updatePrice = [
    PurchaseOrderStatus.ERROR_IN_QUANTITY,
    PurchaseOrderStatus.ERROR_IN_PRICE,
    PurchaseOrderStatus.ERROR_IN_PRICE_AND_QUANTITY,
    PurchaseOrderStatus.PARTIAL,
    PurchaseOrderStatus.COMPLETE,
    PurchaseOrderStatus.WITHOUT_INVOICE,
    PurchaseOrderStatus.RECEIVED,
  ] as string[];
  const canUpdatePrice = updatePrice.includes(order.status);

  const canUpdateError = canUpdateQuantityReceive || canUpdatePrice;

  const getIsEdition = (editionMode: boolean, line: PurchaseLine) => {
    return editionMode && getIsEditionMode(line.status);
  };

  const statusChip = (line: PurchaseLine) => {
    const status = getPurchaseLineStatus(line);
    const chipProps = {
      icon: <CheckCircleOutlineOutlinedIcon />,
      color: "success",
    };
    switch (status) {
      case commons.PENDING:
        chipProps.icon = <ArchiveOutlinedIcon />;
        chipProps.color = "info";
        break;
      case commons.PARTIAL:
        chipProps.icon = <ErrorOutlineOutlinedIcon />;
        chipProps.color = "warning";
        break;
      default:
        break;
    }
    return (
      <Chip
        icon={chipProps.icon}
        label={t(status)}
        color={chipProps.color as ColorType}
        variant="filled"
        sx={{ color: "white", fontWeight: "bold" }}
      />
    );
  };

  const setQuantity = (id: number, quantity: number) => {
    setChange({ id, label: "quantity", value: quantity });
  };

  const setQuantityReceive = (id: number, quantityReceive: number) => {
    setChange({ id, label: "quantityReceive", value: quantityReceive });
  };

  const setUnitCost = (id: number, unitCost: number) => {
    setChange({ id, label: "unitCost", value: unitCost });
  };

  const setTotal = (item: PurchaseLine, unitCost: number) => {
    if (!editionMode && item.unitCost !== unitCost) {
      updatePurchaseOrderLineUnitPrice(item.id, unitCost);
    }
    setChange({ id: item.id, label: "unitCost", value: unitCost });
  };

  const getQuantityComponent = (item: PurchaseLine, editionMode: boolean) => {
    const isEdition = getIsEdition(editionMode, item) && !isReceived;
    if (!isEdition) return undefined;
    return (
      <UpdateQuantityTextField
        item={converterLineToPurchaseOrderLine(item)}
        setQuantity={setQuantity}
      />
    );
  };

  const getRecievedQuantityComponent = (
    item: PurchaseLine,
    canUpdateQuantityReceive: boolean,
    isConciliation: boolean
  ) => {
    const isEdition = !!editionMode && isReceived;
    const canUpdate =
      (canUpdateQuantityReceive && !isConciliation) || isEdition;
    if (!canUpdate) return undefined;
    return (
      <UpdateRecivedQuantityTextField
        item={converterLineToItem(item)}
        kitchenId={kitchenId ?? ""}
        origin={origin ?? ""}
        orderId={orderId ?? ""}
        setQuantityReceive={setQuantityReceive}
        inReview
        autoSave={!editionMode}
      />
    );
  };

  const getUnitPriceComponent = (
    item: PurchaseLine,
    editionMode: boolean,
    canUpdatePrice: boolean,
    isConciliation: boolean
  ) => {
    const isEdition = getIsEdition(editionMode, item);
    const canUpdate = (canUpdatePrice && !isConciliation) || isEdition;
    if (!canUpdate) return undefined;
    return (
      <UpdateUnitPriceTextField
        line={converterLineToPurchaseOrderLine(item)}
        setUnitPrice={setUnitCost}
        autoSave={!isEdition}
        isSmall
      />
    );
  };

  const getTotalComponent = (item: PurchaseLine, editionMode: boolean) => {
    const canUpdate = (canUpdateError && !isConciliation) || editionMode;
    if (!canUpdate) return undefined;
    const { unitCost, quantityReceive: received, quantity: requested } = item;
    const quantity = received > 0 ? received : requested;
    return (
      <NumberField
        value={quantity * unitCost}
        size="small"
        label={t(commons.TOTAL_PRICE)}
        toFixedNumber={3}
        onChangeValue={(newValue) => {
          const price = newValue / quantity;
          setTotal(item, price);
        }}
      />
    );
  };

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    const extra: KOSBaseTableHeader[] = isConciliation
      ? []
      : [
          {
            label: t(commons.STATUS),
            align: "center",
            component: (item: KOSRowData) => statusChip(item as PurchaseLine),
          },
        ];

    return [
      {
        label: t(commons.SKU),
        field: "sku",
      },
      {
        label: t(commons.PRODUCT_NAME),
        field: "description",
      },
      {
        label: t(commons.REQUESTED),
        align: "right",
        type: "number",
        text: (item: KOSRowData) => {
          const isEdition =
            getIsEdition(!!editionMode, item as PurchaseLine) && !isReceived;
          if (isEdition) return undefined;
          return item.quantity;
        },
        component: (item: KOSRowData) =>
          getQuantityComponent(item as PurchaseLine, !!editionMode),
      },
      {
        label: t(commons.REMAINING),
        align: "right",
        type: "number",
        text: (item: KOSRowData) => {
          const remaining = item.quantity - item.quantityReceive;
          return (remaining < 0 ? 0 : remaining).toString();
        },
      },
      {
        label: t(commons.RECEIVED),
        align: "right",
        type: "number",
        text: (item: KOSRowData) => {
          const isEdition = !!editionMode && isReceived;
          const canUpdate =
            (canUpdateQuantityReceive && !isConciliation) || isEdition;
          if (canUpdate) return undefined;
          return item.quantityReceive;
        },
        component: (item: KOSRowData) =>
          getRecievedQuantityComponent(
            item as PurchaseLine,
            canUpdateQuantityReceive,
            !!isConciliation
          ),
      },
      {
        label: t(commons.UNITS),
        align: "center",
        field: "unit",
      },
      {
        label: t(commons.UNIT_PRICE),
        align: "right",
        text: (item: KOSRowData) => {
          const isEdition = getIsEdition(!!editionMode, item as PurchaseLine);
          const canUpdate = (canUpdatePrice && !isConciliation) || isEdition;
          if (canUpdate) return undefined;
          return formatPrice(item.unitCost, localeData[order.country]);
        },
        component: (item: KOSRowData) =>
          getUnitPriceComponent(
            item as PurchaseLine,
            !!editionMode,
            canUpdatePrice,
            !!isConciliation
          ),
      },
      {
        label: t(commons.TOTAL_PRICE),
        align: "right",
        text: (item: KOSRowData) => {
          const canUpdate =
            (canUpdateError && !isConciliation) || !!editionMode;
          if (canUpdate) return undefined;
          const {
            unitCost,
            quantityReceive: received,
            quantity: requested,
          } = item;
          const quantity = isReceived ? received : requested;
          return formatPrice(unitCost * quantity, localeData[order.country]);
        },
        component: (item: KOSRowData) =>
          getTotalComponent(item as PurchaseLine, !!editionMode),
      },
      ...extra,
    ];
  }, [
    order,
    isConciliation,
    editionMode,
    canUpdateQuantityReceive,
    canUpdatePrice,
    canUpdateError,
  ]);

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{
        data: items,
        total: items.length ?? 0,
        loading: !!loading,
      }}
      selectable={{
        resultHeader: ["id"],
        items: selectedItems,
        onChange: setSelectedItems,
        hide: !editionMode,
      }}
    />
  );
};

export default PurchaseLineTable;
