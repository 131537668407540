/* eslint-disable react/no-array-index-key */
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { Alert, Button, Grid } from "@mui/material";
import { commons, purchaseOrderModule, takePhotoLocale } from "app/i18n/types";
import { convertToBase64 } from "app/utils/file";
import { AttachmentFile } from "core/attachment/entity";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TakePhotoDialog from "../TakePhotoDialog";
import UploadArea from "../UploadArea";
import AttachmentCard from "./AttachmentCard";

interface Props {
  attachments: AttachmentFile[];
  setAttachments: (attachments: AttachmentFile[]) => void;
  openDocument: (name: string) => void;
}

const AttachmentContent = (props: Props): React.ReactElement => {
  const { attachments, setAttachments, openDocument } = props;
  const { t } = useTranslation();

  const [resetUploadArea, setResetUploadArea] = useState<number>(
    new Date().getTime()
  );
  const [openTakePhoto, setOpenTakePhoto] = useState<boolean>(false);

  const onContinue = (base64: string) => {
    setAttachments([
      ...attachments,
      {
        name: `${new Date().getTime()}.jpeg`,
        type: "image/jpeg",
        base64,
        verified: false,
      },
    ]);
  };

  const onUpload = (files: File[] | null) => {
    files?.forEach(async (file) => {
      const { name, type } = file;
      const found = attachments.find((el) => el.name === name);
      if (!found) {
        const base64 = await convertToBase64(file);
        setAttachments([
          ...attachments,
          { name, type, base64, verified: false },
        ]);
      }
    });
    setResetUploadArea(new Date().getTime());
  };

  const onDelete = (attachment: AttachmentFile) => {
    setAttachments(attachments.filter((el) => el.name !== attachment.name));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info">
          {t(purchaseOrderModule.ADD_DOCUMENTS_LABEL_INFO)}
        </Alert>
      </Grid>
      {!!attachments.length && (
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", gap: 1, overflowX: "auto" }}
            >
              {attachments.map((attachment) => (
                <AttachmentCard
                  key={`${attachment.id} ${attachment.name}`}
                  attachment={attachment}
                  onDelete={onDelete}
                  openDocument={openDocument}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={6} sx={{ display: "flex" }}>
        <UploadArea
          key={resetUploadArea}
          accept=".pdf, .jpg, .jpeg, .png, .xml"
          onUpload={onUpload}
          label={commons.SELECT_MOVE_FILE}
          maxSizeInMb={5}
          autoUpload
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          onClick={() => setOpenTakePhoto(true)}
          variant="outlined"
          startIcon={<CameraAltOutlinedIcon />}
          fullWidth
          sx={{ height: "100%" }}
        >
          {t(takePhotoLocale.TITLE)}
        </Button>
        {openTakePhoto && (
          <TakePhotoDialog
            onCloseDialog={() => setOpenTakePhoto(false)}
            onContinue={onContinue}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AttachmentContent;
