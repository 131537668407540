import { KOSSelectedResult } from "@foodology-co/alejandria";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import CommentDrawerWithFloatButton from "app/components/CommentArea/DrawerWithFloatButton";
import DocumentsViewer from "app/components/DocumentViewer";
import PageHeader from "app/components/PageHeader/PageHeader";

import { PurchaseOrderAccountChangeStatusDialog } from "app/components/PurchaseOrder/Account/ChangeStatusDialog";
import PurchaseLineTable from "app/components/PurchaseRecord/PurchaseLineTable";
import SearchOnFullDialog from "app/components/common/SearchOnFullDialog";
import {
  commons,
  purchaseOrderCreation,
  purchaseOrderLine,
} from "app/i18n/types";
import PurchaseOrderLinePrincipal from "app/pages/PurchaseOrderLine/Principal";
import { AttachmentTypeEnum } from "core/attachment/entity";
import { CommentTypeEnum } from "core/comment/entity";
import {
  OriginType,
  Purchase,
  PurchaseLine,
  PurchaseOrderStatus,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { getPurchaseOrderLines } from "core/purchaseOrders/repositories/http/purchase";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import { PageBox } from "utils/generalUI";

interface Props {
  kitchenId: string;
  orderId: string;
  onUpdate: () => void;
}

const PurchaseOrderAccountDetail: FunctionComponent<Props> = (props) => {
  const { kitchenId, orderId, onUpdate } = props;
  const { t } = useTranslation();

  const [order, setOrder] = useState<Purchase>();
  const [lines, setLines] = useState<PurchaseLine[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [openDocumentList, setOpenDocumentList] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [newStatus, setNewStatus] = useState<string>("");

  const openChangeStatus = () => {
    if (order) {
      if (order.status === PurchaseOrderStatus.VALIDATED) {
        setNewStatus(PurchaseOrderStatus.ACCOUNTED);
      } else if (order.status === PurchaseOrderStatus.ACCOUNTED) {
        setNewStatus(PurchaseOrderStatus.PAID);
      }
    }
    setOpen(true);
  };

  const getOrders = async (kitchenId: string, orderId: string) => {
    setLoading(true);
    const result = await getPurchaseOrderLines(
      kitchenId,
      orderId,
      OriginType.KIS
    );
    setOrder(result);
    setLoading(false);
  };

  useEffect(() => {
    if (kitchenId && orderId) {
      getOrders(kitchenId, orderId);
    }
  }, [kitchenId, orderId]);

  const searchValidate = (line: PurchaseLine, toSearch: string): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(line.sku, toSearch) ||
      compareSearchText(line.description, toSearch)
    );
  };

  useEffect(() => {
    setLines(
      order?.lines?.filter((line) => {
        return searchValidate(line, search);
      }) ?? []
    );
  }, [order, search]);

  const sourceId =
    order?.origin === OriginType.KIS ? order?.code : order?.orderId;

  const reload = () => {
    if (kitchenId && orderId) {
      getOrders(kitchenId, orderId);
    }
  };

  return (
    <PageBox>
      <PageHeader
        title={t(purchaseOrderLine.TITLE)}
        isFullDialog
        rightArea={[
          {
            children: t(purchaseOrderCreation.DOCUMENT_LIST_BUTTON_LABEL),
            variant: "outlined",
            color: "primary",
            disabled: !order,
            startIcon: <VisibilityOutlinedIcon />,
            onClick: () => setOpenDocumentList(true),
          },
          {
            children: t(commons.CHANGE_STATUS),
            variant: "contained",
            color: "primary",
            disabled: !order,
            show: order?.status !== PurchaseOrderStatus.PAID,
            startIcon: <FactCheckOutlinedIcon />,
            onClick: () => openChangeStatus(),
          },
        ]}
      />

      {open && (
        <PurchaseOrderAccountChangeStatusDialog
          selectedItems={Array(order) as Purchase[]}
          onSuccess={() => {
            setOpen(false);
            reload();
            onUpdate();
          }}
          onClose={() => setOpen(false)}
          newStatus={newStatus}
        />
      )}

      {order && (
        <Box>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <SearchOnFullDialog onSearch={setSearch} />

              <PurchaseOrderLinePrincipal
                order={order}
                isConciliation
                isModify={false}
                reload={reload}
              />
            </Grid>
          </Grid>
          <PurchaseLineTable
            order={order}
            setOrder={setOrder}
            items={lines}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            loading={loading}
            isConciliation
            orderId={orderId}
            kitchenId={kitchenId}
          />

          <CommentDrawerWithFloatButton
            sourceId={order.orderId}
            type={CommentTypeEnum.PURCHASE_ORDER}
            kitchenId={order.kitchenId}
          />

          {openDocumentList && (
            <DocumentsViewer
              sourceId={sourceId ?? ""}
              origin={order.origin}
              type={AttachmentTypeEnum.PURCHASE_ORDER}
              title={order.vendorName}
              onClose={() => setOpenDocumentList(false)}
              kitchenId={kitchenId ?? ""}
              modeToAdd
            />
          )}
        </Box>
      )}
    </PageBox>
  );
};

export default PurchaseOrderAccountDetail;
