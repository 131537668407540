import { KOSEmptyState } from "@foodology-co/alejandria";
import { Close } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { commons, purchaseOrderModule } from "app/i18n/types";
import { ExternalInvoice } from "core/purchaseOrders/entities/Invoice";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import {
  getByExternalId,
  linkInvoice,
  searchInvoices,
  unLinkInvoice,
} from "core/purchaseOrders/repositories/http/purchase";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InvoiceItemCard } from "./InvoiceItemCard";

interface Props {
  purchase: Purchase;
  open: boolean;
  onClose: (reload: boolean) => void;
}
export const PurchaseOrderInvoiceSearch = (props: Props) => {
  const { purchase, open, onClose } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<ExternalInvoice[]>([]);
  const [selectedInvoice, setSelectedInvoice] =
    useState<ExternalInvoice | null>(null);

  const { t } = useTranslation();

  const getData = (id: string) => {
    if (purchase.legalDocumentNo) {
      getByExternalId(purchase.legalDocumentNo).then((response) => {
        setInvoices([response]);
      });
    } else {
      searchInvoices(id).then((response) => {
        setInvoices(response.invoices);
      });
    }
  };

  const link = () => {
    if (selectedInvoice) {
      setLoading(true);
      linkInvoice(purchase.orderId, selectedInvoice).then((response) => {
        setLoading(false);
        onClose(true);
      });
    }
  };

  const unLink = () => {
    if (selectedInvoice) {
      setLoading(true);
      unLinkInvoice(purchase.orderId, selectedInvoice.legalDocumentNo).then(
        () => {
          setLoading(false);
          onClose(true);
        }
      );
    }
  };

  useEffect(() => {
    getData(purchase.orderId);
  }, [purchase]);

  return (
    <Dialog
      open={open ?? true}
      onClose={() => onClose(false)}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "720px",
          },
        },
      }}
      fullWidth
    >
      <DialogTitle
        sx={{
          px: 3,
          py: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {t(purchaseOrderModule.INVOICES)}

        <IconButton
          aria-label="close"
          onClick={() => onClose(false)}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />

      <DialogContent>
        {invoices.length === 0 && (
          <KOSEmptyState
            icon={InfoOutlinedIcon}
            message={t(commons.TABLE_EMPTY)}
          />
        )}

        <Box display="ruby">
          {invoices.map((invoice) => (
            <InvoiceItemCard
              key={invoice.legalDocumentNo}
              invoice={invoice}
              selectInvoice={setSelectedInvoice}
              selectedInvoice={selectedInvoice}
            ></InvoiceItemCard>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <CardActions>
          <Button
            onClick={() => onClose(false)}
            variant="outlined"
            color="error"
            startIcon={<CloseOutlinedIcon />}
            sx={{ py: 1, px: 2, fontWeight: "600", borderRadius: 5 }}
          >
            {t(commons.CANCEL)}
          </Button>

          {purchase.legalDocumentNo ? (
            <Button
              onClick={unLink}
              variant="contained"
              color="primary"
              startIcon={
                loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <ArrowForwardIosIcon />
                )
              }
              autoFocus
              sx={{ py: 1, px: 2, fontWeight: "600", borderRadius: 5 }}
              disabled={loading || !selectedInvoice}
            >
              {t(commons.UNLINK)}
            </Button>
          ) : (
            <Button
              onClick={link}
              variant="contained"
              color="primary"
              startIcon={
                loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <ArrowForwardIosIcon />
                )
              }
              autoFocus
              sx={{ py: 1, px: 2, fontWeight: "600", borderRadius: 5 }}
              disabled={loading || !selectedInvoice}
            >
              {t(commons.LINK)}
            </Button>
          )}
        </CardActions>
      </DialogActions>
    </Dialog>
  );
};
