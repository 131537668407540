import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageHeader from "app/components/PageHeader";
import VendorSupplyBulkLoad from "app/components/Vendor/Supply/BulkLoad";
import CustomTabs from "app/components/common/Tabs";
import { commons, vendor } from "app/i18n/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";
import BySuppliesView from "./views/BySupplies";
import ByVendorsView from "./views/ByVendor";

const VendorPage = () => {
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [createVendor, setCreateVendor] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(-1);

  useEffect(() => {
    setCreateVendor(false);
  }, [tab]);

  return (
    <PageBox>
      <PageHeader
        title={vendor.VENDOR_SUPPLY_MODULE_TITLE}
        subtitle={vendor.VENDOR_SUPPLY_MODULE_DESCRIPTION}
        centerArea={
          <>
            {![2].includes(tab) && (
              <Box
                sx={{ display: "flex", flexDirection: "row-reverse", gap: 1 }}
              >
                <Box sx={{ width: tab !== 1 ? 184 : 440 }}>
                  <KitchenSelector
                    onCountryChange={setSelectedCountry}
                    onCityChange={setSelectedCity}
                    hideCitySelector={tab !== 1}
                    hideLocationSelector
                    size="small"
                  />
                </Box>
                {[0].includes(tab) && (
                  <Button
                    variant="outlined"
                    onClick={() => setCreateVendor(true)}
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    disabled={!selectedCountry}
                  >
                    {t(commons.ADD)} {t(commons.SUPPLIER)}
                  </Button>
                )}
              </Box>
            )}
          </>
        }
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTabs
            tabs={[
              {
                children: t(vendor.VIEW_BY_VENDORS),
                startIcon: <CalendarMonthOutlinedIcon />,
              },
              {
                children: t(vendor.VIEW_BY_SUPPLIES),
                startIcon: <FactCheckOutlinedIcon />,
              },
              {
                children: t(commons.BULK_LOAD),
                startIcon: <ArchiveOutlinedIcon />,
              },
            ]}
            onChange={setTab}
          />
        </Grid>
        <Grid item xs={12}>
          {tab === 0 && (
            <ByVendorsView
              selectedCountry={selectedCountry ?? ""}
              openCreate={createVendor}
              onCloseCreate={() => setCreateVendor(false)}
            />
          )}
          {tab === 1 && <BySuppliesView selectedCity={selectedCity ?? ""} />}
          {tab === 2 && <VendorSupplyBulkLoad />}
        </Grid>
      </Grid>
    </PageBox>
  );
};

export default VendorPage;
