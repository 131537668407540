import appConfig from "config/app";
import { PaymentMethodListResponse } from "core/payment/entities/PaymentMethod";
import { ApiVersion, getHttp } from "utils/http";

export const getAll = async (): Promise<PaymentMethodListResponse> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `payment/method/all`
    );
    const result: PaymentMethodListResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error in getAll | payment method | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const byCountry = async (
  country: string
): Promise<PaymentMethodListResponse> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `payment/method/by-country/${country}`
    );
    const result: PaymentMethodListResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error in byCountry | payment method | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};
