import { FormControl, Grid } from "@mui/material";
import NumberField from "app/components/common/Field/Number";
import { measureUnits } from "app/i18n/types";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  endAdornment?: ReactElement;
  label: string;
  quantity: number;
  unit: string;
  onChangeQuantity: (value: number) => void;
  disabled?: boolean;
}

export const ProductQuantityAndUnit = (props: Props) => {
  const { endAdornment, unit, quantity, onChangeQuantity, label, disabled } =
    props;
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={8}>
        <FormControl variant="outlined" fullWidth>
          <NumberField
            label={t(label)}
            value={quantity}
            endAdornment={endAdornment}
            onChangeValue={onChangeQuantity}
            disabled={disabled}
          />
        </FormControl>
      </Grid>

      <Grid
        item
        xs={4}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {t(measureUnits[unit as keyof typeof measureUnits]).toUpperCase()}
      </Grid>
    </>
  );
};
