import { Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import CommonDialog from "app/components/common/CommonDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commentArea, productionManagement } from "app/i18n/types";
import { clearDeleteProduction } from "app/store/slices/productions";
import { archiveProduction } from "app/store/slices/productions/thunks";
import { AmplitudeEvent, logEvent } from "core/common/utils/analytics";
import { ProductionRecord } from "core/productions/entities/Productions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  production: ProductionRecord;
  kitchenId: string | null;
  open: boolean;
  onClose: () => void;
}

const ProductionCardDeleteButton = (props: Props): React.ReactElement => {
  const { production, kitchenId, open, onClose } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [comment, setComment] = useState<string>("");

  const onArchiveProduction = async () => {
    logEvent(AmplitudeEvent.ConfirmDeleteProduction, {
      kitchenId: kitchenId,
      productionId: production.id,
      sku: production.sku,
      productName: production.name,
    });
    await dispatch(archiveProduction({ productionId: production.id, comment }));
    onClose();
    dispatch(clearDeleteProduction(production));
  };

  return (
    <>
      {open && (
        <CommonDialog
          open
          showCancelButton
          title={t(productionManagement.DELETE_DIALOG_TITLE)}
          handleConfirm={onArchiveProduction}
          handleClose={onClose}
          disabledConfirmButton={!comment}
          bodyElement={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t(productionManagement.DELETE_DIALOG_MESSAGE, {
                    productionName: production.name,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t(commentArea.COMMENT_LABEL)}
                  value={comment}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setComment(newValue);
                  }}
                  autoComplete="off"
                  multiline
                  fullWidth
                  maxRows={2}
                />
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
};

export default ProductionCardDeleteButton;
