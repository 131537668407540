import { commons } from "app/i18n/types";
import { Product as CatalogProduct } from "core/dailyAdjustments/entities/Catalog";
import {
  ProductReplacement,
  ProductToReplaceCreate,
  ProductToReplaceDTO,
} from "core/replacement/entities/Replacement";
import { number, object, string } from "yup";

export interface FormState extends Partial<ProductToReplaceCreate> {}

export interface BaseDialogProps {
  kitchenId: string;
  onClose: () => void;
  onSuccess: (dto: ProductToReplaceDTO) => void;
}

export const refinedCatalogProducts = (list?: CatalogProduct[]) =>
  list
    ?.reduce<CatalogProduct[]>((acc, product) => acc.concat(product), [])
    .map((product: CatalogProduct) => ({
      label: product.sku + "-" + product.name.toLocaleUpperCase(),
      value: product,
    })) ?? [];

export const refinedProductReplacements = (list?: ProductReplacement[]) =>
  list
    ?.reduce<ProductReplacement[]>((acc, product) => acc.concat(product), [])
    .map((product: ProductReplacement) => ({
      label: product.sku + "-" + product.name.toLocaleUpperCase(),
      value: product,
    })) ?? [];

export const handleValidations = object({
  sku: string().required(commons.REQUIRED_FIELD),
  quantity: number()
    .min(1)
    .typeError(commons.QUANTITY_ERROR)
    .required(commons.REQUIRED_FIELD),
  replacementSku: string().required(commons.REQUIRED_FIELD),
  replacementQuantity: number()
    .min(1)
    .typeError(commons.QUANTITY_ERROR)
    .required(commons.REQUIRED_FIELD),
});

export const getValidationData = (data?: FormState) => {
  return {
    ...data,
    replacementSku: data?.toReplace?.sku,
    replacementQuantity: data?.toReplace?.quantity,
  };
};

export interface GetQuantities {
  quantity?: number;
  replacementQuantity?: number;
}
