import { UseCase } from "core/common/interfaces/useCase";
import { ProductionPlanSave, UpdateDateAndSlotPayload } from "../entities/Plan";
import { ProductionPlanHTTPRepository } from "../repositories/http/Plan";

export class SendProductionsScheduleUseCase implements UseCase {
  constructor(private readonly repository: ProductionPlanHTTPRepository) {}

  public execute(kitchenId: string, body: ProductionPlanSave[]) {
    return this.repository.saveProductionSchedule(kitchenId, body);
  }
}

export class UpdateDateAndSlotUseCase implements UseCase {
  constructor(private readonly repository: ProductionPlanHTTPRepository) {}

  public execute(body: UpdateDateAndSlotPayload) {
    return this.repository.updateDateAndSlot(body);
  }
}

export class UpdateProductionPlanUseCase implements UseCase {
  constructor(private readonly repository: ProductionPlanHTTPRepository) {}

  public execute(productionPlanId: number, plan: ProductionPlanSave) {
    return this.repository.updateProductionPlan(productionPlanId, plan);
  }
}

export class ArchiveProductionPlanUseCase implements UseCase {
  constructor(private readonly repository: ProductionPlanHTTPRepository) {}

  public execute(productionPlanId: number, comment: string) {
    return this.repository.archiveProductionPlan(productionPlanId, comment);
  }
}
