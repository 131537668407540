import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import {
  getRemoteValueBoolean,
  RemoteConfigKey,
} from "core/common/utils/remoteConfig";
import {
  ProductionRecord,
  ProductionStatus,
} from "core/productions/entities/Productions";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SelectedProductionActionType,
  SelectedProductionAndAction,
} from "utils/ratatouille";
import { productionManagement } from "../../../i18n/types";
import ProductionCardContent from "./Content";
import ProductionCardDeleteButton from "./DeleteButton";
import ProductionCardFooterActions from "./FooterActions";
import ProductionCardHeader from "./Header";
import ProductionCardIngredients from "./Ingredients";
import ProductionCardLabelsList from "./LabelsList";

interface Props {
  production: ProductionRecord;
  kitchenId: string | null;
  setSelectedProductionAndAction: (
    selected?: SelectedProductionAndAction
  ) => void;
  onRefresh?: () => void;
}

const ProductionCard: FunctionComponent<Props> = (props) => {
  const { production, kitchenId, setSelectedProductionAndAction, onRefresh } =
    props;

  const { t } = useTranslation();

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const requiresValidation = getRemoteValueBoolean(
    RemoteConfigKey.VALIDATION_REQUIRED
  );

  const setSelectedProduction = (action: SelectedProductionActionType) => {
    setSelectedProductionAndAction({
      production,
      action: action,
    });
  };

  const getHeaderColor = (status: ProductionStatus): string => {
    switch (status) {
      case "SCHEDULED":
        return "info.main";
      case "FINISHED":
        return "success.main";
      case "APPROVED":
        return "success.main";
      case "APPROVED_WITH_NOVELTY":
        return "success.main";
      default:
        return "info.dark";
    }
  };

  return (
    <Card
      sx={{
        flexBasis: 320,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        maxHeight: "100%",
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: getHeaderColor(production.status),
        }}
        subheader={<ProductionCardHeader production={production} />}
      />
      <CardContent sx={{ pb: 1, overflowY: "auto" }}>
        <ProductionCardContent
          production={production}
          openDelete={() => setOpenDeleteDialog(true)}
          kitchenId={kitchenId}
          setSelectedProduction={setSelectedProduction}
        />
        {["IN_PROCESS", "SCHEDULED"].includes(production.status) && (
          <Box py={2}>
            <ProductionCardIngredients
              key={`ProductionCardIngredients${production.id}`}
              production={production}
            />
          </Box>
        )}

        <ProductionCardLabelsList
          key={`ProductionCardLabelsList${production.id}`}
          requiresValidation={requiresValidation}
          production={production}
        />

        {requiresValidation && production.status === "FINISHED" && (
          <Alert severity="info" sx={{ m: 2 }}>
            {t(productionManagement.VALIDATION_REQUIRED_INFO)}
          </Alert>
        )}

        <ProductionCardFooterActions
          key={`ProductionCardFooterActions${production.id}`}
          production={production}
          setSelectedProduction={setSelectedProduction}
          onRefresh={onRefresh}
          requiresValidation={requiresValidation}
          contract
        />
      </CardContent>
      <ProductionCardDeleteButton
        production={production}
        kitchenId={kitchenId}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      />
    </Card>
  );
};

export default React.memo(ProductionCard);
