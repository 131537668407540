import { ProductionPlanSelectedSlot } from "core/productions/entities/Plan";
import { useCallback, useState } from "react";
import { Actions } from "utils/modal";
import { HandleDialog } from ".";
import { FormState } from "./constants";

export interface ProductionDialogProps {
  action: Actions;
  selectedSlot?: ProductionPlanSelectedSlot;
  currentPlan?: FormState;
  onlyQuantity?: boolean;
  refreshCalendar?: () => void;
}

export const useHandleProductions = (props: ProductionDialogProps) => {
  const { action, selectedSlot, currentPlan, refreshCalendar, onlyQuantity } =
    props;
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return {
    handleOpen,
    handleClose,
    dialog: (
      <HandleDialog
        action={action}
        open={open}
        onClose={handleClose}
        selectedSlot={selectedSlot}
        isSlotAdded={action === Actions.add && !!selectedSlot}
        currentPlan={currentPlan}
        onlyQuantity={onlyQuantity}
        refreshCalendar={refreshCalendar}
      />
    ),
  };
};
