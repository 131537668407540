import Grid from "@mui/material/Grid";
import CreationResultDialog from "app/components/PurchaseOrder/CreationResultModal";
import UploadArea from "app/components/UploadArea";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { commons, purchaseOrderCreation, transferModule } from "app/i18n/types";
import { excelToJson } from "app/utils/excel";
import {
  CreatePurchaseOrderResponse,
  NewPurchaseOrder,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { createPurchaseOrders } from "core/purchaseOrders/repositories/http/purchase";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "read-excel-file/types";
import { replaceCommasWithPeriods, replaceLineBreak } from "utils/general";

interface Props {
  open: boolean;
  onClose: () => void;
  getPurchaseOrders: () => void;
  setFileErrors: (errors: string[]) => void;
}

const CreatePurchaseOrderMassiveDialog = (props: Props) => {
  const { open, onClose, getPurchaseOrders, setFileErrors } = props;

  const { t } = useTranslation();

  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
  const [response, setResponse] = useState<CreatePurchaseOrderResponse>();

  const onUpload = async (files: File[] | null) => {
    setIsLoadingExcel(true);
    setFileErrors([]);
    if (!files) return;

    const fileHeaderRows = await Promise.all(
      files.map((file) =>
        excelToJson(file, {
          skipRows: 3,
        })
      )
    );
    const fileLineRows = await Promise.all(
      files.map((file) =>
        excelToJson(file, {
          skipRows: 3,
          sheet: 2,
        })
      )
    );
    const purchaseOrders: Row[] = [];

    const purchaseLines: Row[] = [];

    let errors: string[] = [];

    fileHeaderRows.flat().forEach((row, index) => {
      let error = validateHeaderRow(row);
      if (error) {
        errors.push(`${t(transferModule.ERROR_LINE)} ${index + 1} : ${error}`);
        return;
      }

      purchaseOrders.push(replaceLineBreak(row));
    });

    fileLineRows.flat().forEach((row, index) => {
      let error = validateLineRow(row);
      if (error) {
        errors.push(`${t(transferModule.ERROR_LINE)} ${index + 1} : ${error}`);
        return;
      }
      purchaseLines.push(replaceLineBreak(row));
    });

    if (errors.length > 0) {
      setIsLoadingExcel(false);
      setFileErrors(errors);
      return;
    }

    const newPurchaseOrders: NewPurchaseOrder[] = purchaseOrders.map(
      (purchaseOrder) => {
        return {
          vendorCode: purchaseOrder[2].toString(),
          postingDate: dayjs(purchaseOrder[4].toString()).utc().format(),
          kitchenId: purchaseOrder[6].toString(),
          orderDate: dayjs(purchaseOrder[3].toString()).utc().format(),
          invoiceNo: purchaseOrder[7].toString(),
          expectedReceiptAt: dayjs(purchaseOrder[9].toString()).utc().format(),
          lines: purchaseLines
            .filter((line) => line[1] === purchaseOrder[1])
            .map((line) => ({
              sku: line[4].toString(),
              quantity: Number(line[5]),
              unitPrice: Number(replaceCommasWithPeriods(line[6].toString())),
            })),
        };
      }
    );

    const response = await createPurchaseOrders(newPurchaseOrders);
    if (response.ok) {
      getPurchaseOrders();
    }
    setIsLoadingExcel(false);
    setResponse(response);
    onClose();
  };

  const validateHeaderRow = (row: any[]) => {
    if (!row[2]) {
      return t(transferModule.ERROR_SKU);
    }
  };

  const validateLineRow = (row: any[]) => {
    if (!row[4]) {
      return t(transferModule.ERROR_SKU);
    }
    if (!row[5]) {
      return t(transferModule.ERROR_MEASURE_UNIT);
    }
  };

  return (
    <>
      {open && (
        <ManagerDialog
          title={purchaseOrderCreation.CREATE_MASSIVE_MODAL_TITLE}
          onClose={onClose}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <UploadArea
                  accept=".xlsx, .xls, .csv"
                  onUpload={onUpload}
                  label={t(commons.SELECT_MOVE_FILE)}
                  loading={isLoadingExcel}
                  width="100%"
                />
              </Grid>
            </Grid>
          }
        />
      )}
      {!!response && (
        <CreationResultDialog
          response={response}
          onClose={() => {
            setResponse(undefined);
          }}
        />
      )}
    </>
  );
};

export default CreatePurchaseOrderMassiveDialog;
