import { KOSSelectedResult } from "@foodology-co/alejandria";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import DoDisturbOutlinedIcon from "@mui/icons-material/DoDisturbOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Grid, Paper, Typography } from "@mui/material";
import CommonDialog from "app/components/common/CommonDialog";
import InputSearchCustom from "app/components/common/InputSearchCustom";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageHeader from "app/components/PageHeader/PageHeader";
import AurhorizeReplacementTable from "app/components/Replacement/Authorize/Table";
import { useAlert } from "app/hooks/useAlert";
import { commons, productionDialog, replacementModule } from "app/i18n/types";
import { ProductToReplaceDTO } from "core/replacement/entities/Replacement";
import {
  authorizeProductReplacement,
  getReplacementsByCountry,
  getReplacementsByKitchen,
  rejectProductReplacement,
} from "core/replacement/repositories/replacement";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import { PageBox } from "utils/generalUI";

interface Props {}

const AuthorizeReplacementPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const alert = useAlert();
  const [items, setItems] = useState<ProductToReplaceDTO[]>([]);
  const [filteredItems, setFilteredItems] = useState<ProductToReplaceDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedKitchenId, setSelectedKitchenId] = useState<string>("");
  const [productSearch, setProductSearch] = useState<string>("");
  const [productReplaceSearch, setProductReplaceSearch] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [dialogType, setDialogType] = useState<string>();

  const getDataByCountry = (country: string) => {
    if (!country) return;
    if (loading) return;
    setLoading(true);
    getReplacementsByCountry(country)
      .then((response) => {
        setItems(response);
      })
      .finally(() => setLoading(false));
  };

  const getDataByKitchenId = (kitchenId: string) => {
    if (!kitchenId) return;
    if (loading) return;
    setLoading(true);
    getReplacementsByKitchen(kitchenId)
      .then((response) => {
        setItems(response);
      })
      .finally(() => setLoading(false));
  };

  const getData = (country: string, kitchenId: string) => {
    setProductSearch("");
    setProductReplaceSearch("");
    if (country && kitchenId) {
      getDataByKitchenId(kitchenId);
      return;
    }
    if (country) {
      getDataByCountry(country);
    }
  };

  useEffect(() => {
    getData(selectedCountry, selectedKitchenId);
  }, [selectedCountry, selectedKitchenId]);

  const authorize = useCallback(() => {
    if (!selectedItems.length) return;
    const ids = selectedItems.map((el) => Number(el.id));
    authorizeProductReplacement(ids)
      .then((response) => {
        if (response.data) {
          alert.success();
          setItems(items.filter((item) => !ids.includes(item.id)));
        } else {
          alert.error();
        }
      })
      .finally(() => setDialogType(undefined));
  }, [selectedItems, items]);

  const reject = useCallback(() => {
    if (!selectedItems.length) return;
    const ids = selectedItems.map((el) => Number(el.id));
    rejectProductReplacement(ids)
      .then((response) => {
        if (response.data) {
          alert.success();
          setItems(items.filter((item) => !ids.includes(item.id)));
        } else {
          alert.error();
        }
      })
      .finally(() => setDialogType(undefined));
  }, [selectedItems, items]);

  const renderItem = (item: KOSSelectedResult) => {
    const a = `
      ${item.quantity} ${item.unit} 
      ${t(commons.OF)} 
      ${item.name} (${item.sku})
    `;
    const b = `
      ${item.replaceQuantity} ${item.replaceUnit} 
      ${t(commons.OF)} 
      ${item.replaceName} (${item.replaceSku})
    `;
    return <li key={`selectedItem${item.id}`}>{`${a} -> ${b}`}</li>;
  };

  const searchProductValidator = (
    order: ProductToReplaceDTO,
    toSearch: string
  ): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(order.sku, toSearch) ||
      compareSearchText(order.name, toSearch)
    );
  };

  const searchProductReplaceValidator = (
    order: ProductToReplaceDTO,
    toSearch: string
  ): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(order.replaceSku, toSearch) ||
      compareSearchText(order.replaceName, toSearch)
    );
  };

  useEffect(() => {
    setFilteredItems(
      items.filter((item) => {
        return (
          searchProductValidator(item, productSearch) &&
          searchProductReplaceValidator(item, productReplaceSearch)
        );
      })
    );
  }, [items, productSearch, productReplaceSearch]);

  return (
    <PageBox>
      <PageHeader
        title={t(replacementModule.AUTHORIZE_TITLE)}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            color: "info",
            startIcon: <CachedOutlinedIcon />,
            onClick: () => getData(selectedCountry, selectedKitchenId),
          },
          {
            children: t(commons.DECLINE),
            variant: "outlined",
            color: "error",
            startIcon: <DoDisturbOutlinedIcon />,
            disabled: !selectedItems.length,
            onClick: () => setDialogType(commons.DECLINE),
          },
          {
            children: t(commons.AUTHORIZE),
            variant: "contained",
            startIcon: <FactCheckOutlinedIcon />,
            disabled: !selectedItems.length,
            onClick: () => setDialogType(commons.AUTHORIZE),
          },
        ]}
      />

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(commons.FILTERS)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <KitchenSelector
                  onCountryChange={(country) =>
                    setSelectedCountry(country ?? "")
                  }
                  onKitchenChange={(kitchen) =>
                    setSelectedKitchenId(kitchen?.kitchenId ?? "")
                  }
                  hideCitySelector
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <InputSearchCustom
                  label={`${t(commons.SEARCH)} ${t(
                    productionDialog.NAME
                  ).toLowerCase()}`}
                  setSearch={setProductSearch}
                  search={productSearch}
                  compact
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <InputSearchCustom
                  label={`${t(commons.SEARCH)} ${t(
                    productionDialog.NAME
                  ).toLowerCase()} ${t(
                    replacementModule.REPLACE
                  ).toLowerCase()}`}
                  setSearch={setProductReplaceSearch}
                  search={productReplaceSearch}
                  compact
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <AurhorizeReplacementTable
            items={filteredItems}
            loading={loading}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </Grid>
      </Grid>

      {!!dialogType && (
        <CommonDialog
          open
          showCancelButton
          title={t(commons.CONFIRM)}
          bodyElement={
            <>
              <Typography variant="body1">
                {t(dialogType)} {t(replacementModule.REPLACE).toLowerCase()}:
              </Typography>
              <ul>
                {selectedItems.map((item) => renderItem(item))}
                {!selectedItems.length && <li>{t(commons.TABLE_EMPTY)}</li>}
              </ul>
            </>
          }
          handleConfirm={() => {
            if (dialogType === commons.AUTHORIZE) {
              authorize();
            } else {
              reject();
            }
          }}
          handleClose={() => setDialogType(undefined)}
        />
      )}
    </PageBox>
  );
};

export default AuthorizeReplacementPage;
