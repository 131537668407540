import appConfig from "config/app";
import { ApiVersion, getHttp, postHttp } from "utils/http";
import {
  ProductReplaceArchive,
  ProductReplaceArchiveResponse,
  ProductReplaceAuthorizeResponse,
  ProductReplacement,
  ProductReplaceRejectResponse,
  ProductToReplaceCreate,
  ProductToReplaceCreateResponse,
  ProductToReplaceDTO,
} from "../entities/Replacement";

export const getProductReplacements = async (
  country: string,
  sku: string
): Promise<ProductReplacement[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `product/replacements/${country}/${sku}`
    );

    const responseJson = await response.json();

    return responseJson.replacements as ProductReplacement[];
  } catch (err) {
    console.error("getProductReplacements error", err);
    return [];
  }
};

export const registerProductReplacement = async (
  data: ProductToReplaceCreate
): Promise<ProductToReplaceCreateResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `replacement/register`,
      data
    );

    const responseJson = await response.json();

    return responseJson as ProductToReplaceCreateResponse;
  } catch (err) {
    console.error("registerProductReplacement error", err);
    return { ok: false, message: "Error" };
  }
};

export const archiveProductReplacement = async (
  data: ProductReplaceArchive
): Promise<ProductReplaceArchiveResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `replacement/archive`,
      data
    );

    const responseJson = await response.json();

    return responseJson as ProductReplaceArchiveResponse;
  } catch (err) {
    console.error("archiveProductReplacement error", err);
    return { ok: false, message: "Error", data: false };
  }
};

export const getReplacementsByKitchen = async (
  kitchenId: string
): Promise<ProductToReplaceDTO[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `replacement/by-kitchen/${kitchenId}/pending`
    );

    const responseJson = await response.json();

    return responseJson.data as ProductToReplaceDTO[];
  } catch (err) {
    console.error("getReplacementsByKitchen error", err);
    return [];
  }
};

export const getReplacementsByCountry = async (
  country: string
): Promise<ProductToReplaceDTO[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `replacement/by-country/${country}/pending`
    );

    const responseJson = await response.json();

    return responseJson.data as ProductToReplaceDTO[];
  } catch (err) {
    console.error("getReplacementsByCountry error", err);
    return [];
  }
};

export const authorizeProductReplacement = async (
  ids: number[]
): Promise<ProductReplaceAuthorizeResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `replacement/authorize`,
      { ids }
    );

    const responseJson = await response.json();

    return responseJson as ProductReplaceAuthorizeResponse;
  } catch (err) {
    console.error("authorizeProductReplacement error", err);
    return { ok: false, message: "Error", data: false };
  }
};

export const rejectProductReplacement = async (
  ids: number[]
): Promise<ProductReplaceRejectResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `replacement/reject`,
      { ids }
    );

    const responseJson = await response.json();

    return responseJson as ProductReplaceRejectResponse;
  } catch (err) {
    console.error("rejectProductReplacement error", err);
    return { ok: false, message: "Error", data: false };
  }
};
