import { CoreBaseResponse } from "core/common/interfaces/core";

export enum TypeOfDocument {
  NUMERIC = "NUMERIC",
  ALPHANUMERIC = "ALPHANUMERIC",
  ALPHABETIC = "ALPHABETIC",
}

export interface DocumentType {
  code: string;
  description: string;
  country: string;
  type: `${TypeOfDocument}`;
  mask?: string;
  minLength?: number;
  maxLength?: number;
}

export interface DocumentTypeListResponse extends CoreBaseResponse {
  data?: DocumentType[];
}
