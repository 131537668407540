import appConfig, { AppConfig } from "config/app";
import {
  ProductionPlan,
  ProductionPlanSave,
  ProductionPlanWithDetailResponse,
  UpdateDateAndSlotPayload,
} from "core/productions/entities/Plan";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export const baseControllerUrl = "production/plan";

export class ProductionPlanHTTPRepository {
  constructor(private readonly config: AppConfig) {}

  public async saveProductionSchedule(
    kitchenId: string,
    body: ProductionPlanSave[]
  ): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `${baseControllerUrl}/kitchen/${kitchenId}/save`,
        body
      );
      return response.ok;
    } catch (err) {
      console.error("sendProductionsPrintEvent error", err);
      throw err;
    }
  }

  public async updateDateAndSlot(
    body: UpdateDateAndSlotPayload
  ): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `${baseControllerUrl}/update/date-and-slot`,
        body
      );
      return (await response.text()) === "true";
    } catch (err) {
      console.error("sendProductionsPrintEvent error", err);
      throw err;
    }
  }

  public async updateProductionPlan(
    productionPlanId: number,
    plan: ProductionPlanSave
  ): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `${baseControllerUrl}/${productionPlanId}`,
        plan
      );
      return (await response.text()) === "true";
    } catch (err) {
      console.error("updateProductionPlan error", err);
      throw err;
    }
  }

  public archiveProductionPlan = async (
    id: number,
    comment: string
  ): Promise<boolean> => {
    try {
      const response = await postHttp(
        appConfig.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `${baseControllerUrl}/archive`,
        { id, comment }
      );
      return (await response.text()) === "true";
    } catch (ex: any) {
      console.error("archiveProductionPlan error", ex);
      throw ex;
    }
  };
}

export const findByKitchenAndScheduledDate = async (
  kitchenId: string,
  date: string
): Promise<ProductionPlan[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `${baseControllerUrl}/kitchen/${kitchenId}/${date}`
    );
    const responseJSON: ProductionPlan[] = await response.json();
    return responseJSON;
  } catch (ex: any) {
    console.error(`Error findByKitchenAndScheduledDate | ${ex.message}`);
    return [];
  }
};

export const findDetail = async (
  planId: number
): Promise<ProductionPlanWithDetailResponse> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `${baseControllerUrl}/detail/${planId}`
    );
    const responseJSON: ProductionPlanWithDetailResponse =
      await response.json();
    return responseJSON;
  } catch (ex: any) {
    console.error(`Error findDetail | ${ex.message}`);
    return { data: undefined };
  }
};
