import { useNavigator } from "app/hooks/useNavigator";
import { FunctionComponent } from "react";
import PurchaseOrderLine from "..";

interface Props {}

const PurchaseOrderLineReview: FunctionComponent<Props> = () => {
  const navigator = useNavigator();
  const { kitchenId, orderId } = navigator.params();

  return (
    <PurchaseOrderLine
      kitchenId={kitchenId!!}
      orderId={orderId!!}
      isFullDialog={false}
      onUpdate={() => {}}
    />
  );
};

export default PurchaseOrderLineReview;
