import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Grid, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CommonDialog from "app/components/common/CommonDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commentArea, productionSchedule } from "app/i18n/types";
import { archiveProductionPlan } from "app/store/slices/productions/plan/thunks";
import { ProductionPlan } from "core/productions/entities/Plan";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  productionPlan: ProductionPlan;
  removeCard: () => void;
  reloadDay: () => void;
}

const ProductionScheduleCardDeleteButton = (
  props: Props
): React.ReactElement => {
  const { productionPlan, removeCard, reloadDay } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  const remove = async () => {
    if (!comment) return;
    const response = await dispatch(
      archiveProductionPlan({
        productionPlanId: productionPlan.id,
        comment,
      })
    );
    const archived = response.payload as boolean;
    if (archived) {
      removeCard();
    } else {
      reloadDay();
    }
  };

  return (
    <>
      <IconButton size="small" onClick={() => setOpenDeleteDialog(true)}>
        <DeleteOutlineIcon fontSize="small" />
      </IconButton>
      {openDeleteDialog && (
        <CommonDialog
          open
          showCancelButton
          title={t(productionSchedule.DELETE_TITLE)}
          handleConfirm={remove}
          handleClose={() => setOpenDeleteDialog(false)}
          disabledConfirmButton={!comment}
          bodyElement={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {t(productionSchedule.DELETE_MESSAGE, {
                    productionName: productionPlan.name,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t(commentArea.COMMENT_LABEL)}
                  value={comment}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setComment(newValue);
                  }}
                  autoComplete="off"
                  multiline
                  fullWidth
                  maxRows={2}
                />
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
};

export default ProductionScheduleCardDeleteButton;
