import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Button, Chip, Typography } from "@mui/material";
import {
  commons,
  transferModule,
  transferOrdersPackedReviewTable,
  transferPackingDialog,
} from "app/i18n/types";
import { PickingDetailResponse } from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTransferItemServiceLevel } from "utils/general";

interface TransferPickingTableProps {
  lines: Array<PickingDetailResponse>;
  loading: boolean;
  searchTerm: string;
  onEdit: (line: PickingDetailResponse) => void;
}

const TransferPickingTable: FunctionComponent<TransferPickingTableProps> = (
  props
) => {
  const { lines, loading, onEdit, searchTerm } = props;
  const { t } = useTranslation();

  const filteredLines = useMemo(() => {
    if (searchTerm === "") {
      return lines;
    }

    return lines.filter(
      (line) =>
        line.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        line.sku.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [lines, searchTerm]);

  const isStatusSuccess = (line: PickingDetailResponse): boolean => {
    if (line.status === "COMPLETE") {
      return true;
    }
    return false;
  };

  const serviceLevelComponent = (item: PickingDetailResponse) => {
    const status = isStatusSuccess(item);
    if (status) {
      const level = getTransferItemServiceLevel(
        item.packedQuantity,
        item.quantity
      );
      return (
        <Typography color={level.color}>
          {level.serviceLevelPercentage}
        </Typography>
      );
    }
    return <></>;
  };

  const measureUnitComponent = (item: PickingDetailResponse) => {
    return (
      <Typography
        textTransform="uppercase"
        fontWeight={700}
        color="text.secondary"
      >
        {t(`measureUnits.${item.measureUnit.toLowerCase()}`)}
      </Typography>
    );
  };

  const statusComponent = (item: PickingDetailResponse) => {
    const isComplete = isStatusSuccess(item);
    return (
      <Chip
        icon={
          isComplete ? (
            <CheckCircleOutlineOutlinedIcon />
          ) : (
            <CircleOutlinedIcon />
          )
        }
        label={t(isComplete ? transferModule.ENLISTED : transferModule.PENDING)}
        color={isComplete ? "success" : undefined}
      />
    );
  };
  const actionsComponent = (item: PickingDetailResponse) => {
    const isComplete = isStatusSuccess(item);
    return (
      <Button
        variant="outlined"
        color={isComplete ? "warning" : "info"}
        onClick={() => onEdit(item)}
      >
        {t(
          isComplete
            ? transferModule.EDIT_TRANSFER
            : transferModule.PREPARE_TRANSFER
        )}
      </Button>
    );
  };

  const getPickingTableHeader = (): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.SKU),
        field: "sku",
      },
      {
        label: t(commons.SUPPLY),
        field: "name",
      },
      {
        label: t(transferPackingDialog.TOTAL_QUANTITY),
        field: "quantity",
        type: "number",
        weight: "bold",
      },
      {
        label: t(transferOrdersPackedReviewTable.PACKED_QUANTITY),
        type: "number",
        weight: "bold",
        text: (item: KOSRowData) => {
          return (
            isStatusSuccess(item as PickingDetailResponse)
              ? item.packedQuantity
              : 0
          ).toString();
        },
      },
      {
        label: t(transferOrdersPackedReviewTable.SERVICE_LEVEL),
        weight: "bold",
        component: (item: KOSRowData) =>
          serviceLevelComponent(item as PickingDetailResponse),
      },
      {
        label: t(commons.UNIT),
        weight: "bold",
        component: (item: KOSRowData) =>
          measureUnitComponent(item as PickingDetailResponse),
      },

      {
        label: t(commons.GROUP),
        text: (item: KOSRowData) => t(item.itemGroup),
      },
      {
        label: t(transferModule.STORAGE),
        text: (item: KOSRowData) =>
          t(item.storage ?? transferModule.UNSPECIFIED),
      },
      {
        label: t(commons.STATUS),
        component: (item: KOSRowData) =>
          statusComponent(item as PickingDetailResponse),
      },
      {
        label: t(commons.ACTIONS),
        component: (item: KOSRowData) =>
          actionsComponent(item as PickingDetailResponse),
      },
    ];
  };

  return (
    <KOSBaseTable
      columns={getPickingTableHeader()}
      rows={{
        data: filteredLines ?? [],
        total: filteredLines.length,
        loading,
      }}
    />
  );
};

export default TransferPickingTable;
