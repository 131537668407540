import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, replacementModule } from "app/i18n/types";
import { Product as CatalogProduct } from "core/dailyAdjustments/entities/Catalog";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFinishedProductsByCountry } from "utils/ratatouille";
import { BaseDialogProps } from "./constants";
import { HandleForm } from "./form";

export const ReplacementRegisterDialog = (props: BaseDialogProps) => {
  const { onClose, kitchenId } = props;

  const kitchens = useAppSelector((state) => state.global.kitchens.data);
  const products = useAppSelector((state) => state.global.products.data);
  let loadingFinishedProducts = false;

  const [finishedProducts, setFinishedProducts] = useState<CatalogProduct[]>(
    []
  );

  const getFinishedProducts = (kitchens: Kitchen[]) => {
    if (loadingFinishedProducts) return;
    loadingFinishedProducts = true;

    const currentKitchen = kitchens.find(
      (kitchen) => kitchen.kitchenId === kitchenId
    );

    if (!currentKitchen) return;

    getFinishedProductsByCountry(currentKitchen?.country).then((response) => {
      setFinishedProducts(response);
    });
  };

  useEffect(() => {
    getFinishedProducts(kitchens);
  }, [kitchens]);

  const { t } = useTranslation();

  return (
    <ManagerDialog
      title={`${t(commons.REPORT)} ${t(replacementModule.TITLE).toLowerCase()}`}
      onClose={onClose}
      content={
        <HandleForm
          {...props}
          products={products}
          finishedProducts={finishedProducts}
        />
      }
    />
  );
};
