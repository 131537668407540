import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { commons, productionDialog } from "app/i18n/types";
import { ProductionLabelResponse } from "core/productions/entities/Productions";
import dayjs from "dayjs";
import { FunctionComponent } from "react";
import Barcode from "react-barcode";
import { useTranslation } from "react-i18next";

const ProductionLabelCard: FunctionComponent<ProductionLabelResponse> = (
  label
) => {
  const { t } = useTranslation();

  const conservation = (conservation: string) => {
    const index = ["Freezing", "Refrigeration", "RoomTemperature"].findIndex(
      (val) => val === conservation
    );
    if (index !== -1) {
      return t(`commons.${conservation}`);
    } else {
      return conservation;
    }
  };

  return (
    <Card
      sx={{
        flexShrink: 0,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <CardContent>
        <Box
          sx={{
            displayPrint: "block",
            width: "120mm",
            height: "100mm",
          }}
        >
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              alignItems="center"
            >
              <img
                src="https://images-kos.s3.amazonaws.com/logo_Foodology/logo-foodology.png"
                alt="Foodology SAS"
                width="50%"
              />
              <Barcode
                value={`PROD-${label.id.toString()}`}
                displayValue={false}
                height={50}
                width={1.5}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              alignItems="center"
            >
              <Typography textAlign="left" variant="caption" display="block">
                <strong>{t(commons.SKU)}:</strong> {label.sku}
              </Typography>
              <Typography variant="caption" display="block">
                <strong>{t(productionDialog.WEIGHT)}:</strong>{" "}
                {`${label.quantity} ${label.measureUnit}`}
              </Typography>
            </Box>

            <Box sx={{ textTransform: "uppercase" }}>
              <Typography textAlign="left" variant="caption" display="block">
                <strong>{t(productionDialog.TECHNICAL_NAME)}:</strong>{" "}
                {label.technicalName}
              </Typography>

              <Typography textAlign="left" variant="caption" display="block">
                <strong>{t(productionDialog.NAME)}:</strong> {label.productName}
              </Typography>
              <Typography textAlign="left" variant="caption" display="block">
                <strong>{t(productionDialog.BATCH)}:</strong> {label.batch}
              </Typography>
            </Box>
            <Box sx={{ textTransform: "uppercase", mt: "10px" }}>
              <Typography textAlign="left" variant="caption" display="block">
                <strong>{t(productionDialog.PRODUCTION_DATE)}:</strong>{" "}
                {dayjs(label.finishedAt).format("YYYY-MM-DD hh:mm A")}
              </Typography>
              <Typography textAlign="left" variant="caption" display="block">
                <strong>{t(productionDialog.EXPIRATION_DATE)}:</strong>{" "}
                {label.expirationDate ? label.expirationDate : ""}
              </Typography>
            </Box>
            <Box sx={{ textTransform: "uppercase", mt: "10px" }}>
              <Typography textAlign="left" variant="caption" display="block">
                <strong>{t(productionDialog.CONSERVATION)}:</strong>{" "}
                {conservation(label.conservation)}
              </Typography>
              <Typography
                textAlign="left"
                fontWeight={700}
                variant="caption"
                display="block"
              >
                <strong>{t(productionDialog.MAXIMUM_DURATION)}:</strong>{" "}
                {label.maxDuration !== ""
                  ? `${label.maxDuration} ${t(productionDialog.DAYS)}`
                  : ""}
              </Typography>
            </Box>
            <Box sx={{ textTransform: "uppercase", mt: "10px" }}>
              <Typography
                textAlign="center"
                fontWeight={700}
                textTransform="uppercase"
              >
                {t(commons.INGREDIENTS)}:
              </Typography>
              <Typography textAlign="center" textTransform="uppercase">
                {label.ingredients}
              </Typography>
            </Box>
            <Box sx={{ textTransform: "uppercase", mt: "10px" }}>
              <Typography textAlign="left" variant="caption" display="block">
                <strong>{t(productionDialog.RESPONSIBLE)}:</strong>{" "}
                {label.userName}
              </Typography>
              <Typography textAlign="left" variant="caption" display="block">
                <strong>{t(productionDialog.MANUFACTURER)}:</strong> Foodology,
                SAS
              </Typography>
              <Typography textAlign="left" variant="caption" display="block">
                <strong>{t(productionDialog.ADDRESS)}:</strong> {label.address}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductionLabelCard;
