import { Alert } from "@mui/material";
import { productionManagement } from "app/i18n/types";
import { useTranslation } from "react-i18next";
import { Actions } from "utils/modal";
import { FormState } from "./constants";
import { HandleForm } from "./form";

interface Props {
  action: Actions;
  onClose: () => void;
  currentPlan?: FormState;
  onlyQuantity?: boolean;
  refreshCalendar?: () => void;
}

export const EditDialogContent = (props: Props) => {
  const { action, onClose, currentPlan, refreshCalendar, onlyQuantity } = props;
  const { t } = useTranslation();
  return (
    <>
      <Alert
        severity="info"
        sx={{ mb: 3, display: "flex", alignItems: "center" }}
      >
        {t(productionManagement.VALIDATE_ADDED_DATA)}
      </Alert>

      <HandleForm
        action={action}
        onClose={onClose}
        initialValues={currentPlan}
        refreshCalendar={refreshCalendar}
        onlyQuantity={onlyQuantity}
      />
    </>
  );
};
