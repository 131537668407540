import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import { OutboxOutlined } from "@mui/icons-material";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { commons, productionLiberation } from "app/i18n/types";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SelectedProductionActionType,
  SelectedProductionAndAction,
} from "utils/ratatouille";
import ProductionLabelHistoryDialog from "../Label/HistoryDialog";
import ProductionLiberationApprovementModal from "./ApprovementModal";
import OrganolepticModal from "./OrganolepticModal";

interface Props {
  productions: ProductionRecord[];
  loading: boolean;
}

const ProductionLiberationTable: FunctionComponent<Props> = (props: Props) => {
  const { productions, loading } = props;

  const { t } = useTranslation();

  const [selectedProductionAndAction, setSelectedProductionAndAction] =
    useState<SelectedProductionAndAction>();

  const getActions = (item: KOSRowData) => {
    const production = item as ProductionRecord;
    return (
      <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<HistoryOutlinedIcon />}
          onClick={() =>
            setSelectedProductionAndAction({
              production,
              action: SelectedProductionActionType.LABEL_HISTORY,
            })
          }
          disabled={!production.labels.length}
        >
          {t(commons.LABELS)}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<OutboxOutlined />}
          onClick={() => {
            setSelectedProductionAndAction({
              production,
              action: SelectedProductionActionType.LIBERATION_DIALOG,
            });
          }}
        >
          {t(productionLiberation.LIBERATE_ACTION_BUTTON)}
        </Button>
      </Box>
    );
  };

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.ID),
      field: "id",
      align: "center",
    },
    {
      label: t(commons.SKU),
      field: "sku",
    },
    {
      label: t(commons.PRODUCTION),
      field: "name",
    },
    {
      label: t(commons.QUANTITY),
      field: "producedQuantity",
      weight: "bold",
      align: "right",
    },
    {
      label: t(commons.UNIT),
      text: (item: KOSRowData) => t(`measureUnits.${item.unit.toLowerCase()}`),
      align: "center",
    },
    {
      label: t(productionLiberation.FINISH_DATE),
      field: "finishedAt",
      align: "center",
      type: "date",
    },
    {
      label: t(commons.ACTIONS),
      align: "center",
      component: (item: KOSRowData) => getActions(item),
    },
  ];

  return (
    <>
      <KOSBaseTable
        columns={getHeader()}
        rows={{ data: productions, total: productions.length, loading }}
        pagination={{ enable: true }}
      />
      {selectedProductionAndAction && (
        <>
          {selectedProductionAndAction.action ===
            SelectedProductionActionType.LIBERATION_DIALOG && (
            <ProductionLiberationApprovementModal
              production={selectedProductionAndAction.production}
              onClose={() => setSelectedProductionAndAction(undefined)}
              onEvaluate={() => {
                setSelectedProductionAndAction({
                  ...selectedProductionAndAction,
                  action: SelectedProductionActionType.ORGANOLEPTIC_DIALOG,
                });
              }}
            />
          )}
          {selectedProductionAndAction.action ===
            SelectedProductionActionType.ORGANOLEPTIC_DIALOG && (
            <OrganolepticModal
              production={selectedProductionAndAction.production}
              onClose={() => setSelectedProductionAndAction(undefined)}
            />
          )}
          {selectedProductionAndAction.action ===
            SelectedProductionActionType.LABEL_HISTORY && (
            <ProductionLabelHistoryDialog
              production={selectedProductionAndAction.production}
              onClose={() => setSelectedProductionAndAction(undefined)}
              onlyView
            />
          )}
        </>
      )}
    </>
  );
};

export default ProductionLiberationTable;
