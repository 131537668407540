import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { commons } from "app/i18n/types";
import { DocumentType } from "core/document/entities/DocumentType";
import { byCountry as byCountryDocumentTypes } from "core/document/repositories/http/documentType";
import { PaymentMethod } from "core/payment/entities/PaymentMethod";
import { byCountry as byCountryPaymentMethods } from "core/payment/repositories/http/paymentMethod";
import { Vendor } from "core/vendors/entities/Vendor";
import { findVendorById } from "core/vendors/repositories/http/vendor";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Actions } from "utils/modal";
import { BaseDialogProps } from "./constants";
import { HandleForm } from "./form";

export const VendorManagerDialog = (props: BaseDialogProps) => {
  const { onClose, action, itemId, country: countryParam } = props;

  const { t } = useTranslation();

  const [vendor, setVendor] = useState<Vendor>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [vendorId, setVendorId] = useState<number>();
  const [country, setCountry] = useState<string>();

  const dialogTitle = {
    [Actions.add]: t(commons.ADD),
    [Actions.edit]: t(commons.MODIFY),
    [Actions.view]: t(commons.VENDOR_INFO),
  };

  const getPaymentMethods = (country: string) => {
    byCountryPaymentMethods(country).then((response) => {
      response.data && setPaymentMethods(response.data);
    });
  };

  const getDocumentTypes = (country: string) => {
    byCountryDocumentTypes(country).then((response) => {
      response.data && setDocumentTypes(response.data);
    });
  };

  const getData = (id: number) => {
    findVendorById(id).then((response) => {
      response && setVendor(response);
    });
  };

  useEffect(() => {
    countryParam && setCountry(countryParam);
  }, [countryParam]);

  useEffect(() => {
    setVendorId(itemId ?? -1);
  }, [itemId]);

  useEffect(() => {
    vendorId && vendorId > 0 && getData(vendorId);
  }, [vendorId]);

  useEffect(() => {
    if (!country) return;
    getPaymentMethods(country);
    getDocumentTypes(country);
  }, [country]);

  return (
    <ManagerDialog
      title={dialogTitle[action ?? Actions.add]}
      onClose={onClose}
      content={
        <>
          {!!props.action && (
            <HandleForm
              {...props}
              item={vendor ?? props.item}
              paymentMethods={paymentMethods}
              documentTypes={documentTypes}
            />
          )}
        </>
      }
    />
  );
};
