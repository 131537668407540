import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { commons } from "app/i18n/types";
import { convertDate } from "app/utils/dateUtils";
import { ExternalInvoice } from "core/purchaseOrders/entities/Invoice";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import { openUrl } from "utils/general";

interface Props {
  invoice: ExternalInvoice;
  selectInvoice: (invoice: ExternalInvoice) => void;
  selectedInvoice: ExternalInvoice | null;
}
export const InvoiceItemCard = (props: Props) => {
  const { invoice, selectInvoice, selectedInvoice } = props;

  const { t } = useTranslation();

  const flexColumn = {
    display: "flex",
    flexDirection: "column",
    gap: 0.5,
  };

  return (
    <Card
      onClick={() => selectInvoice(invoice)}
      sx={{
        flexBasis: 320,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        maxHeight: "100%",
        marginBottom: "10px",
        cursor: "pointer",
        width: 320,
        mx: 1,
        backgroundColor:
          selectedInvoice?.legalDocumentNo === invoice.legalDocumentNo
            ? "#f0f0f0"
            : "",
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: "info.main",
        }}
        subheader={
          <Box display="flex" justifyContent="space-between" color="white">
            <Typography fontWeight={700}>
              # {invoice.legalDocumentNo.substring(0, 8).toUpperCase()}
            </Typography>
            <Box display="flex" gap={1}>
              <PictureAsPdfIcon onClick={() => openUrl(invoice.url)} />
            </Box>
          </Box>
        }
      />
      <CardContent sx={{ pb: 1, overflowY: "auto" }}>
        <Box>
          <Grid container spacing={0.5}>
            <Grid item xs={6} sx={flexColumn}>
              <Typography variant="caption" color="text.secondary">
                {t(commons.DATE)}
              </Typography>
              <Typography>
                {convertDate(invoice.issueDate, "dd/MM/YYY")}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={flexColumn}>
              <Typography variant="caption" color="text.secondary">
                {t(commons.INVOICE)}
              </Typography>
              <Typography>{invoice.invoice}</Typography>
            </Grid>
            <Grid item xs={12} sx={flexColumn}>
              <Typography variant="caption" color="text.secondary">
                {t(commons.SUBTOTAL)}
              </Typography>
              <Typography>
                {formatPrice(invoice.subtotal, localeData[invoice.country])}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box py={2}>
          <Box key={`invoice-${invoice.legalDocumentNo}`}>
            <Typography
              fontWeight={700}
              component="div"
              bgcolor="#eeeeee"
              color="primary.main"
              width="100%"
              pl={1}
              mb={1}
            >
              {t(commons.ITEMS)}
            </Typography>

            <Box>
              {invoice.items.map((item, i) => (
                <Box
                  key={`itemdetail-${item.id}`}
                  display="flex"
                  justifyContent="space-between"
                  px={0.5}
                  alignItems="center"
                  bgcolor={i % 2 === 0 ? "background.paper" : "#eeeeee"}
                >
                  <Typography fontSize={14}>{item.description}</Typography>
                  <Typography
                    fontSize={14}
                    sx={{ flexShrink: 0 }}
                    fontWeight={700}
                  >
                    {item.invoicedQuantity}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
