import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import NumberField from "app/components/common/Field/Number";

import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAlert } from "app/hooks/useAlert";
import {
  commons,
  purchaseOrderCreation,
  purchaseOrderModule,
} from "app/i18n/types";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import { savePurchaseOrderAdjustment } from "core/purchaseOrders/repositories/http/purchase";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";

interface Props {
  open: boolean;
  onClose: () => void;
  order: Purchase;
  onSuccess: () => void;
}

const PurchaseOrderConcilliationAdjustmentDialog = (props: Props) => {
  const { open, onClose, order, onSuccess } = props;

  const { t } = useTranslation();
  const [errorAdjustment, setErrorAdjustment] = useState<string | null>(null);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [adjustment, setAdjustment] = useState<number>(0);
  const [savingAdjustment, setSavingAdjustment] = useState<boolean>(false);

  const fdyAlert = useAlert();

  const adjustmentValidate = (value: number) => {
    setAdjustment(0);
    setTotalValue(value);
    setErrorAdjustment(null);
    if (order && value > 0) {
      const difference = value - order.totalGross;
      const percentage = (Math.abs(difference) * 100) / order.totalGross;
      const max = maxAdjustment(order.country);
      if (percentage > max) {
        setErrorAdjustment(
          t(purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT_MAX, {
            percentage: max,
          })
        );
      } else {
        setAdjustment(Number(difference.toFixed(2)));
      }
    }
  };

  const saveAdjustment = async () => {
    if (order && adjustment !== 0) {
      setSavingAdjustment(true);
      savePurchaseOrderAdjustment(adjustment, order.orderId)
        .then(() => {
          fdyAlert.success();
          onSuccess();
          onClose();
        })
        .finally(() => {
          setSavingAdjustment(false);
        });
    }
  };

  const maxAdjustment = (country: string) => {
    switch (country) {
      case "COL":
        return 3;
      case "MEX":
        return 2;
      default:
        return 2;
    }
  };

  return (
    <ManagerDialog
      title={purchaseOrderCreation.ADD_LINE_MODAL_TITLE}
      onClose={() => onClose()}
      open={open}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {t(purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT_DESCRIPTION)}
          </Grid>
          {errorAdjustment && (
            <Grid item xs={12}>
              <Alert severity="error">{errorAdjustment}</Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <NumberField
              variant="outlined"
              value={totalValue}
              label={t(commons.TOTAL_PRICE)}
              onChangeValue={(newValue) => adjustmentValidate(newValue)}
              fullWidth
              autoComplete="off"
            />
          </Grid>
          {adjustment !== 0 && (
            <Grid item xs={12}>
              <Alert severity="info">
                {t(purchaseOrderModule.CONCILLIATION_CREATE_ADJUSTMENT_VALUE, {
                  value: formatPrice(adjustment, localeData[order.country]),
                })}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <ManagerDialogFooter
              mainButton={{
                children: t(commons.SAVE),
                onClick: () => saveAdjustment(),
                disabled:
                  adjustment === 0 ||
                  errorAdjustment !== null ||
                  savingAdjustment,
              }}
              onCancel={() => onClose()}
              loading={savingAdjustment}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default PurchaseOrderConcilliationAdjustmentDialog;
