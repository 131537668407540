import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { Box, Button, Chip } from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  commons,
  supplyingManagement,
  theoricalInventoryModule,
} from "app/i18n/types";
import { TheoreticalInventoryComparisonByCity } from "core/theoricalInventory/entities/ThereticalInventory";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorType } from "utils/generalUI";
import MonitoringIcon from "../../../utils/icons/MonitoringIcon";
import CustomTabs from "../common/Tabs";

interface Props {
  items: TheoreticalInventoryComparisonByCity[];
  loading: boolean;
  handleChangeKitchen: (kitchenId: string) => void;
}

const TheoreticalInventoryComparisonCitiesTable: FunctionComponent<Props> = (
  props
) => {
  const { items, loading, handleChangeKitchen } = props;
  const { t } = useTranslation();

  const [tab, setTab] = useState<number>(-1);
  const [filteredItems, setFilteredItems] = useState<
    TheoreticalInventoryComparisonByCity[]
  >([]);

  const accuracyCounts = items.reduce(
    (acc, item) => {
      if (item.accuracy < 50) acc.low += 1;
      else if (item.accuracy >= 50 && item.accuracy < 85) acc.medium += 1;
      else acc.high += 1;

      return acc;
    },
    { low: 0, medium: 0, high: 0 }
  );

  const {
    low: numberLowAccuracy,
    medium: numberMediumAccuracy,
    high: numberHighAccuracy,
  } = accuracyCounts;

  const statusChip = (accuracy: number): React.ReactElement => {
    const chipProps = {
      icon: <LegendToggleIcon />,
      color: "warning",
      label: accuracy + " %",
    };
    if (accuracy >= 85) {
      chipProps.icon = <MonitoringIcon color="white" />;
      chipProps.color = "success";
    }
    if (accuracy < 50) {
      chipProps.icon = <TrendingDownIcon />;
      chipProps.color = "error";
    }

    return (
      <Chip
        sx={{
          pl: "12px",
          fontSize: "15px",
        }}
        icon={chipProps.icon}
        label={chipProps.label}
        color={chipProps.color as ColorType}
      />
    );
  };

  useEffect(() => {
    const newItems = items.filter((item) => {
      switch (tab) {
        case 0:
          return true;
        case 1:
          return item.accuracy >= 85;
        case 2:
          return item.accuracy > 50 && item.accuracy < 85;
        case 3:
          return item.accuracy < 50;
      }
    });
    setFilteredItems(newItems);
  }, [items, tab]);

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.CODE),
      field: "id",
    },
    {
      label: t(commons.KITCHEN),
      field: "name",
    },
    {
      label: t(commons.ACCURACY),
      align: "center",
      component: (item: KOSRowData) => statusChip(Number(item.accuracy)),
    },
    {
      label: t(commons.DETAILS),
      align: "center",
      component: (item: KOSRowData) => (
        <Button
          onClick={() => handleChangeKitchen(String(item.id))}
          variant="outlined"
          startIcon={<ListAltIcon />}
          sx={{
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
          }}
        >
          {t(commons.SHOW_DETAILS)}
        </Button>
      ),
    },
  ];

  return (
    <Box>
      <Paper elevation={0}>
        <CustomTabs
          tabs={[
            {
              childrenWithChip: {
                startIcon: <QueryStatsIcon />,
                text: t(supplyingManagement.ALL_SLOTS),
                chipLabel: items.length,
              },
            },
            {
              childrenWithChip: {
                startIcon: <MonitoringIcon color="#011A5B" />,
                text: t(theoricalInventoryModule.HIGH_ACCURACY),
                chipLabel: numberHighAccuracy,
              },
            },
            {
              childrenWithChip: {
                startIcon: <LegendToggleIcon />,
                text: t(theoricalInventoryModule.MEDIUM_ACCURACY),
                chipLabel: numberMediumAccuracy,
              },
            },
            {
              childrenWithChip: {
                startIcon: <TrendingDownIcon />,
                text: t(theoricalInventoryModule.LOW_ACCURACY),
                chipLabel: numberLowAccuracy,
              },
            },
          ]}
          onChange={setTab}
        />
      </Paper>
      <Box sx={{ mt: 1 }}>
        <KOSBaseTable
          columns={getHeader()}
          rows={{
            data: filteredItems,
            total: filteredItems.length,
            loading,
          }}
          pagination={{
            enable: true,
          }}
        />
      </Box>
    </Box>
  );
};

export default TheoreticalInventoryComparisonCitiesTable;
