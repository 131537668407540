import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import { Box, Grid } from "@mui/material";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { commons } from "app/i18n/types";
import { PurchaseInvoice } from "core/purchaseOrders/entities/Invoice";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";

interface Props {
  invoice: PurchaseInvoice;
  open: boolean;
  onClose: () => void;
}
export const InvoiceItemsDialog = (props: Props) => {
  const { open, invoice, onClose } = props;
  const { t } = useTranslation();

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.NAME),
        text: (item: KOSRowData) => item.description,
      },
      {
        label: t(commons.QUANTITY),
        text: (item: KOSRowData) => item.invoicedQuantity,
      },
      {
        label: t(commons.SUBTOTAL),
        text: (item: KOSRowData) =>
          formatPrice(item.subtotal, localeData[invoice.country]),
      },
    ];
  }, []);

  return (
    <Box>
      <ManagerDialog
        title={invoice?.invoice ?? ""}
        open={open}
        onClose={onClose}
        size="md"
        content={
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
              }}
            >
              <KOSBaseTable
                columns={getHeader()}
                rows={{
                  data: invoice.items ?? [],
                  total: invoice.items.length ?? 0,
                  loading: false,
                }}
                pagination={{ enable: true }}
              />
            </Grid>
          </Grid>
        }
        actions={<ManagerDialogFooter onCancel={onClose} loading={false} />}
      />
    </Box>
  );
};
