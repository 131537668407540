import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSPageSize,
  KOSRowData,
} from "@foodology-co/alejandria";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import Button from "@mui/material/Button";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, transferModule, transferOrderModule } from "app/i18n/types";
import { getTransferOrdersByKitchenId } from "app/store/slices/transfer/thunks";
import {
  PaginatedTransferResponse,
  TransferResponse,
} from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataFilterTransferOrder, compareSearchText } from "utils/general";
import TransferChipStatus from "./TransferChipStatus";

interface Props {
  kitchenId: string;
  search?: string;
  status?: DataFilterTransferOrder;
}

const TransferOrdersTable: FunctionComponent<Props> = (props) => {
  const { kitchenId, search, status: statusTmp } = props;
  const status =
    !statusTmp || statusTmp === DataFilterTransferOrder.ALL ? "" : statusTmp;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigator = useNavigator();

  const kitchens = useAppSelector((state) => state.global.kitchens.data);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<KOSPageSize>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [items, setItems] = useState<TransferResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = () => {
    if (kitchenId) {
      setLoading(true);
      const searchKitchenId = kitchens.find((el) => {
        if (!search) return false;
        return compareSearchText(el.name, search);
      })?.kitchenId;
      dispatch(
        getTransferOrdersByKitchenId({
          kitchenId,
          params: {
            page: page,
            rows: rowsPerPage,
            search: searchKitchenId ?? search,
            searchBy: ["external_id", "destination_kitchen_id"],
            extra: {
              status,
            },
          },
        })
      )
        .then((response) => {
          const resp = response.payload as PaginatedTransferResponse;
          setTotalItems(resp.totalRows);
          setItems(resp.items);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, search, kitchenId, status]);

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(transferOrderModule.TRANSFER_ID),
      field: "id",
    },
    {
      label: t(transferModule.ORDER_NO),
      field: "externalId",
    },
    {
      label: t(transferModule.DESTINATION),
      field: "destinationKitchenName",
    },
    {
      label: t(commons.SEND_DATE),
      field: "sendAt",
      type: "date",
    },
    {
      label: t(commons.TYPE),
      text: (item: KOSRowData) => t(`transferModule.type.${item.type}`),
    },
    {
      label: t(commons.STATUS),
      align: "center",
      component: (item: KOSRowData) => (
        <TransferChipStatus status={item.status as string} />
      ),
    },
    {
      label: t(commons.ACTIONS),
      align: "center",
      component: (item: KOSRowData) => (
        <Button
          onClick={() =>
            navigator.toByLocationType(`/transfer-order/${item.id}`)
          }
          variant="outlined"
          startIcon={<FactCheckOutlinedIcon />}
          sx={{
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
          }}
        >
          {t(commons.SHOW_DETAILS)}
        </Button>
      ),
    },
  ];

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{
        data: items,
        total: totalItems,
        loading,
      }}
      pagination={{
        enable: true,
        page: {
          size: rowsPerPage,
          number: page,
          onNumberChange: setPage,
          onSizeChange: setRowsPerPage,
        },
      }}
    />
  );
};

export default TransferOrdersTable;
