import { KOSSelectedResult } from "@foodology-co/alejandria";
import { Add, DeleteOutline, InfoOutlined } from "@mui/icons-material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CommentDrawerWithFloatButton from "app/components/CommentArea/DrawerWithFloatButton";
import PageHeader from "app/components/PageHeader/PageHeader";
import AddLineModal from "app/components/PurchaseOrder/AddLineModal";
import DeleteLinesModal from "app/components/PurchaseOrder/DeleteLinesModal";
import PurchaseLineTable from "app/components/PurchaseRecord/PurchaseLineTable";
import VendorSupplyPriceRangeInPOValidator from "app/components/Vendor/Supply/PriceRange/PurchaseOrder/Validator";
import CommonDialog, {
  CommonDialogContent,
} from "app/components/common/CommonDialog";
import SearchOnFullDialog from "app/components/common/SearchOnFullDialog";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import {
  commons,
  purchaseOrderCreation,
  purchaseOrderLine,
  purchaseOrderModule,
} from "app/i18n/types";
import { updateLinesQuantityAndUnitPrice } from "app/store/slices/purchase/purchase.slice";
import { getSuppliesByCountry } from "app/store/slices/supplies/thunks";
import appConfig from "config/app";
import { AttachmentTypeEnum } from "core/attachment/entity";
import { viewPurchaseOrderPdf } from "core/attachment/repository/http";
import { CommentTypeEnum } from "core/comment/entity";
import { Country } from "core/common/entities/Country";
import {
  LineModify,
  ModifyPayload,
  NewIndividualPurchaseOrderLine,
  Purchase,
  PurchaseLine,
  PurchaseOrderStatus,
} from "core/purchaseOrders/entities/PurchaseOrder";
import {
  createPurchaseOrderLine,
  deletePurchase,
  deletePurchaseOrderLines,
  getPurchaseOrderLines,
  updateStatusOfPurchaseOrder,
} from "core/purchaseOrders/repositories/http/purchase";
import { PurchaseOrdersHTTPRepository } from "core/purchaseOrders/repositories/http/purchaseOrders";
import { MarkPurchaseOrderAsReceivedUseCase } from "core/purchaseOrders/useCases/purchaseOrder/markPurchaseOrderAsReceivedUseCase";
import { VendorSupply } from "core/vendors/entities/VendorSupply";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataFilterPurchaseOrder,
  clearSpecialCharacters,
  compareSearchText,
  getPurchaseLineStatus,
} from "utils/general";
import { PageBox, sxModalIcon } from "utils/generalUI";
import {
  converterPurchaseOrderLineToPurchaseLine,
  converterPurchaseToPurchaseOrder,
  getIsEditionMode,
} from "utils/purchase";
import DocumentsViewer from "../../components/DocumentViewer";
import PurchaseOrderLinePrincipal from "./Principal";
import PurchaseOrderLineSummary from "./Summary";

interface Props {
  kitchenId: string;
  orderId: string;
  isFullDialog: boolean;
  onUpdate: (close: boolean) => void;
}

const PurchaseOrderLine: FunctionComponent<Props> = (props: Props) => {
  const { kitchenId, orderId, isFullDialog, onUpdate } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [order, setOrder] = useState<Purchase>();
  const [lines, setLines] = useState<PurchaseLine[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openMarkReceived, setOpenMarkReceived] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [modal, setModal] = useState<CommonDialogContent>();
  const [openDocumentList, setOpenDocumentList] = useState<boolean>(false);
  const [dataFilter, setDataFilter] = useState<DataFilterPurchaseOrder>("all");
  const [editionMode, setEditionMode] = useState<boolean>(false);
  const [saveData, setSaveData] = useState<boolean>(false);
  const isEditionMode = getIsEditionMode(order?.status);
  const [openAddLine, setOpenAddLine] = useState<boolean>(false);
  const [openDeleteLines, setOpenDeleteLines] = useState<boolean>(false);
  const [checkedLines, setCheckedLines] = useState<number[]>([]);
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [vendorSupplies, setVendorSupplies] = useState<VendorSupply[]>([]);
  const [savingStatus, setSavingStatus] = useState<boolean>(false);

  const suppliesRequest = useAppSelector(
    (state) => state.supplies.suppliesByCountry
  );

  const getSupplies = (country?: string) => {
    if (!country) return;
    dispatch(getSuppliesByCountry(Country[country as keyof typeof Country]));
  };

  const getData = (kitchenId: string, orderId: string) => {
    setLoading(true);
    setDataFilter("all");
    setOrder(undefined);
    getPurchaseOrderLines(kitchenId, orderId, "KIS")
      .then((response) => {
        setOrder(response);
        getSupplies(response?.country);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (kitchenId && orderId) {
      getData(kitchenId, orderId);
    }
  }, [kitchenId, orderId]);

  const filterValidate = (line: PurchaseLine): boolean => {
    const status = getPurchaseLineStatus(line);
    switch (dataFilter) {
      case "all":
        return true;
      case "complete":
        return status === commons.COMPLETE;
      case "pending":
        return status === commons.PENDING;
      case "partial":
        return status === commons.PARTIAL;
      default:
        return false;
    }
  };

  const searchValidate = (line: PurchaseLine, toSearch: string): boolean => {
    if (!toSearch) return true;
    const skuValidate = compareSearchText(line.sku, toSearch);
    const vendorValidate = compareSearchText(line.description, toSearch);
    return skuValidate || vendorValidate;
  };

  useEffect(() => {
    const toSearch = clearSpecialCharacters(search);
    setLines(
      order?.lines?.filter((line) => {
        return searchValidate(line, toSearch) && filterValidate(line);
      }) ?? []
    );
    if (editionMode) {
      setSaveData(true);
    }
  }, [order, search, dataFilter]);

  const handleDeletePurchaseOrder = () => {
    setModal({
      title: t(purchaseOrderCreation.DELETE_PURCHASE_ORDER_TITLE),
      message: `${t(purchaseOrderCreation.DELETE_PURCHASE_ORDER_MESSAGE)} ${
        order?.vendorName
      }`,
      icon: (
        <DeleteOutlineOutlinedIcon
          sx={{
            ...sxModalIcon,
            color: "red",
          }}
        />
      ),
      handleConfirm: archivePurchaseOrder,
      showCancelButton: true,
    });
    setOpen(true);
  };

  const archivePurchaseOrder = async () => {
    if (order) {
      deletePurchase(Number(order.orderId)).then((response) => {
        if (response.ok) {
          onUpdate(true);
        }
      });
    }
  };

  const errors = [
    PurchaseOrderStatus.ERROR_IN_QUANTITY,
    PurchaseOrderStatus.ERROR_IN_PRICE,
    PurchaseOrderStatus.ERROR_IN_PRICE_AND_QUANTITY,
    PurchaseOrderStatus.WITHOUT_INVOICE,
  ] as string[];

  const withError = errors.includes(order?.status ?? "");

  const handleCorrected = async () => {
    if (orderId) {
      setSavingStatus(true);
      await updateStatusOfPurchaseOrder(orderId, PurchaseOrderStatus.CORRECTED);
      setSavingStatus(false);
      reload();
    }
  };

  const onMarkReceived = async () => {
    try {
      await markPurchaseOrderAsRecieved()
        .then((response) => {
          if (response?.ok) {
            reload();
          }
        })
        .finally(() => {
          setOpenMarkReceived(false);
        });
    } catch (e) {
      console.error("onMarkReceived", e);
      throw e;
    }
  };

  const markPurchaseOrderAsRecieved = async () => {
    if (kitchenId && order?.orderId) {
      const httpRepository = new PurchaseOrdersHTTPRepository(appConfig);
      const useCase = new MarkPurchaseOrderAsReceivedUseCase(httpRepository);
      const response = await useCase.execute(Number(order?.orderId));
      return response;
    }
  };

  useEffect(() => {
    if (order && !editionMode && saveData) {
      const modifyPayload: ModifyPayload = {
        id: Number(order.orderId),
        lines:
          order.lines?.map((line) => {
            const { id, quantity, unitCost, quantityReceive } = line;
            return {
              id,
              quantity,
              quantityReceive,
              unitPrice: unitCost,
            } as LineModify;
          }) ?? [],
      };
      dispatch(updateLinesQuantityAndUnitPrice(modifyPayload));
      setSaveData(false);
    }
  }, [editionMode]);

  const handleAddLine = useCallback(
    async (newPurchaseOrderLine: NewIndividualPurchaseOrderLine) => {
      const response = await createPurchaseOrderLine(newPurchaseOrderLine);
      if (order && response.ok) {
        setOrder({
          ...order,
          lines: order.lines && [
            ...order.lines,
            converterPurchaseOrderLineToPurchaseLine({
              ...newPurchaseOrderLine,
              id: response.id,
            }),
          ],
        });
        setOpenAddLine(false);
      }
    },
    [order]
  );

  const handleDeleteSelectedLines = useCallback(async () => {
    const response = await deletePurchaseOrderLines(checkedLines);
    if (order && response.ok) {
      setOrder({
        ...order,
        lines:
          order?.lines?.filter((el) => !checkedLines.includes(el.id)) ?? [],
      });
      setOpenDeleteLines(false);
      setSelectedItems([]);
    }
  }, [order, checkedLines]);

  useEffect(() => {
    setCheckedLines(selectedItems.map((el) => Number(el.id)));
  }, [selectedItems]);

  const reload = () => {
    if (kitchenId && orderId) {
      getData(kitchenId, orderId);
      onUpdate(false);
    }
  };

  return (
    <PageBox>
      <PageHeader
        title={t(purchaseOrderLine.TITLE)}
        isFullDialog={isFullDialog}
        rightArea={[
          {
            children: t(commons.DELETE),
            variant: "outlined",
            color: "error",
            startIcon: <DeleteOutlineOutlinedIcon />,
            onClick: handleDeletePurchaseOrder,
            show:
              order?.status === "RELEASED" ||
              (order?.status === "PENDING" && !editionMode),
          },
          {
            children: t(commons.MODIFY),
            variant: "outlined",
            color: "warning",
            startIcon: <EditOutlinedIcon />,
            onClick: () => setEditionMode(true),
            show: isEditionMode && !editionMode,
          },
          {
            children: t(commons.SAVE),
            variant: "outlined",
            color: "success",
            startIcon: <CheckOutlinedIcon />,
            onClick: () => setEditionMode(false),
            show: isEditionMode && editionMode,
          },
          {
            children: t(purchaseOrderCreation.DOCUMENT_LIST_BUTTON_LABEL),
            variant: "outlined",
            color: "primary",
            disabled: !order,
            startIcon: <VisibilityOutlinedIcon />,
            onClick: () => setOpenDocumentList(true),
          },
          {
            children: t(commons.VIEW_PDF),
            variant: "outlined",
            color: "primary",
            startIcon: <InsertDriveFileOutlinedIcon />,
            onClick: () => {
              if (order) {
                viewPurchaseOrderPdf(order);
              }
            },
          },
          {
            children: t(commons.MARK_RECEIVED),
            variant: "outlined",
            onClick: () => setOpenMarkReceived(true),
            color: "info",
            startIcon: <FactCheckOutlinedIcon />,
            show: order?.status === "PARTIAL_RECEIVED" && !editionMode,
          },
          {
            children: t(commons.CORRECTED),
            variant: "contained",
            color: "primary",
            disabled: !order || savingStatus,
            startIcon: <FactCheckOutlinedIcon />,
            onClick: handleCorrected,
            show: (withError || order?.status === "RECEIVED") && !editionMode,
          },
        ]}
      />
      {order && (
        <>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {isFullDialog ? (
              <SearchOnFullDialog onSearch={setSearch} />
            ) : (
              <SearchOnTopBar onSearch={setSearch} />
            )}
            <Grid item xs={12}>
              <PurchaseOrderLinePrincipal
                order={order}
                showReceiptNro
                isModify={editionMode}
                reload={reload}
              />
            </Grid>
            <PurchaseOrderLineSummary
              lines={order.lines ?? []}
              dataFilter={dataFilter}
              setDataFilter={setDataFilter}
            />
            {isEditionMode && editionMode && (
              <Grid item xs={12}>
                <Box display={"flex"} gap={1} flexWrap={"wrap"}>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteOutline />}
                    disabled={!checkedLines.length}
                    onClick={() => setOpenDeleteLines(true)}
                  >
                    {t(purchaseOrderCreation.DELETE_LINE_MODAL_TITLE)}
                  </Button>
                  <Button
                    variant="outlined"
                    color="info"
                    startIcon={<Add />}
                    onClick={() => setOpenAddLine(true)}
                  >
                    {t(purchaseOrderCreation.ADD_LINE_MODAL_TITLE)}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          <PurchaseLineTable
            order={order}
            setOrder={setOrder}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            items={lines}
            loading={loading}
            editionMode={editionMode}
            orderId={orderId}
            kitchenId={kitchenId}
          />

          <VendorSupplyPriceRangeInPOValidator
            kitchenId={order.kitchenId}
            vendorId={Number(order.vendorId)}
            supplyToValidate={[]}
            onVendorSupplies={setVendorSupplies}
          />

          <CommentDrawerWithFloatButton
            sourceId={order.orderId}
            type={CommentTypeEnum.PURCHASE_ORDER}
            kitchenId={order.kitchenId}
          />

          {openAddLine && (
            <AddLineModal
              purchaseOrder={converterPurchaseToPurchaseOrder(order, kitchenId)}
              supplies={suppliesRequest.supplies ?? []}
              vendorSupplies={vendorSupplies}
              onClose={() => setOpenAddLine(false)}
              handleAddLine={handleAddLine}
            />
          )}
          <DeleteLinesModal
            open={openDeleteLines}
            purchaseOrder={converterPurchaseToPurchaseOrder(order, kitchenId)}
            checkedLines={checkedLines}
            onClose={() => setOpenDeleteLines(false)}
            handleDeleteSelectedLines={handleDeleteSelectedLines}
          />
          {openDocumentList && (
            <DocumentsViewer
              sourceId={order.code ?? ""}
              origin={order.origin}
              type={AttachmentTypeEnum.PURCHASE_ORDER}
              title={order?.vendorName}
              onClose={() => setOpenDocumentList(false)}
              kitchenId={kitchenId ?? ""}
              modeToAdd
            />
          )}
        </>
      )}
      <CommonDialog
        open={openMarkReceived}
        handleClose={() => setOpenMarkReceived(false)}
        handleConfirm={onMarkReceived}
        title={t(purchaseOrderModule.MARK_ASRECEIVED_TITLE)}
        message={t(purchaseOrderModule.MARK_ASRECEIVED_MESSAGE)}
        icon={
          <InfoOutlined
            sx={{
              height: "80px",
              width: "80px",
              paddingRight: "5px",
              color: "#ED6C02",
            }}
          />
        }
        showCancelButton={true}
      />
      <CommonDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={() => {
          modal?.handleConfirm ? modal?.handleConfirm() : setOpen(false);
        }}
        title={modal?.title ?? ""}
        message={modal?.message ?? ""}
        icon={modal?.icon ?? <></>}
        showCancelButton={!!modal?.showCancelButton}
      />
    </PageBox>
  );
};

export default PurchaseOrderLine;
