import { CoreBaseResponse } from "core/common/interfaces/core";
import { VendorSupply } from "./VendorSupply";

export interface Vendor {
  id: number;
  name: string;
  externalCode: string;
  country: string;
  address?: string;
  phone?: string;
  paymentDays?: number;
  email?: string;
  purchaseEmail?: string;
  document?: string;
  paymentMethod?: string;
  documentType?: string;
}

export interface VendorWithSupplies {
  id: number;
  name: string;
  document?: string;
  externalCode: string;
  address: string;
  phone: string;
  country: string;
  paymentDays?: number;
  supplies: VendorSupply[];
}

export interface VendorJoinWithSupply {
  vendor: Vendor;
  supply: VendorSupply;
}

export interface VendorWithSuppliesCount {
  id: number;
  name: string;
  externalCode: string;
  country: string;
  address?: string;
  phone?: string;
  paymentDays?: number;
  email?: string;
  purchaseEmail?: string;
  suppliesCount: number;
}

export enum PayDayEnum {
  "1D" = "1D",
  "2D" = "2D",
  "3D" = "3D",
  "4D" = "4D",
  "5D" = "5D",
  "7D" = "7D",
  "8D" = "8D",
  "10D" = "10D",
  "15D" = "15D",
  "20D" = "20D",
  "30D" = "30D",
  "60D" = "60D",
  "90D" = "90D",
}

export type PayDay = `${PayDayEnum}`;

export interface CreateOrUpdateVendorResponse extends CoreBaseResponse {
  data?: {
    code: string;
    id: number;
  };
}
