import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Button } from "@mui/material";
import {
  commons,
  countingTypeSelector,
  physicalCountSchedule,
} from "app/i18n/types";
import { CountingResponse } from "core/physicalCount/entities/Counting";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  loading: boolean;
  items: CountingResponse[];
  selectedItems: KOSSelectedResult[];
  setSelectedItems: (selectedItems: KOSSelectedResult[]) => void;
  onArchive: (items: KOSSelectedResult[]) => void;
  archiving: boolean;
}

const ArchiveCountsDialogTable: FunctionComponent<Props> = (props) => {
  const {
    loading,
    items,
    selectedItems,
    setSelectedItems,
    onArchive,
    archiving,
  } = props;

  const { t } = useTranslation();

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.KITCHEN),
      field: "kitchenId",
    },
    {
      label: t(countingTypeSelector.LABEL),
      text: (item: KOSRowData) => {
        const comment = item.comment ? ` (${item.comment})` : ``;
        return `${t(item.type)}${comment}`;
      },
    },
    {
      label: t(commons.STATUS),
      text: (item: KOSRowData) => t(`module.physicalCount.${item.status}`),
    },
    {
      label: t(physicalCountSchedule.COUNT_DATE),
      field: "scheduledDate",
      type: "date",
    },
    {
      label: t(commons.DATETIME),
      field: "date",
      type: "datetime_utc",
    },
    {
      label: t(commons.ACTIONS),
      align: "center",
      component: (item: KOSRowData) => (
        <Button
          onClick={() => onArchive([item])}
          variant="outlined"
          color="error"
          size="small"
          sx={{ height: "100%" }}
          disabled={archiving}
        >
          <DeleteOutlineOutlinedIcon />
        </Button>
      ),
    },
  ];

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{ data: items, total: items.length, loading, showNumber: true }}
      selectable={{
        resultHeader: ["id"],
        items: selectedItems,
        onChange: setSelectedItems,
      }}
    />
  );
};

export default ArchiveCountsDialogTable;
