import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button } from "@mui/material";
import FullDialog from "app/components/common/FullDialog";
import {
  commons,
  purchaseOrderCreation,
  purchaseOrderLine,
} from "app/i18n/types";
import PurchaseOrderAccountDetail from "app/pages/PurchaseOrder/Account/detail";
import {
  OriginType,
  Purchase,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import PurchaseOrderStatusChip from "../Status/Chip";
import WithInvoiceChip from "../WithInvoiceChip";

interface Props {
  items: Purchase[];
  loading: boolean;
  selectedItems: KOSSelectedResult[];
  setSelectedItems: (selectedItems: KOSSelectedResult[]) => void;
  canPagination?: boolean;
  onUpdate: () => void;
}

const PurchaseOrderAcountTable: FunctionComponent<Props> = (props) => {
  const {
    items,
    loading,
    selectedItems,
    setSelectedItems,
    canPagination,
    onUpdate,
  } = props;

  const { t } = useTranslation();

  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [selectedPurchase, setSelectedPurchase] = useState<Purchase | null>(
    null
  );

  const statusChip = (purchase: Purchase): React.ReactElement => {
    return <PurchaseOrderStatusChip purchase={purchase} />;
  };

  const withInvoice = (purchase: Purchase): React.ReactElement => {
    return <WithInvoiceChip purchaseOrder={purchase} />;
  };

  const detailButton = (item: KOSRowData) => (
    <Button
      onClick={() => onShowDetail(item)}
      variant="outlined"
      startIcon={<FactCheckOutlinedIcon color="primary" />}
    >
      {t(commons.DETAIL)}
    </Button>
  );

  const onShowDetail = (item: KOSRowData) => {
    setSelectedPurchase(item as Purchase);
    setOpenDetail(true);
  };

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.ORDER_ID),
        text: (item: KOSRowData) =>
          item.origin === OriginType.KIS ? item.code : item.orderId,
      },
      {
        label: t(commons.SUPPLIER),
        field: "vendorName",
      },
      {
        label: t(commons.KITCHEN),
        field: "kitchenId",
      },
      {
        label: t(commons.TOTAL_PRICE),
        align: "right",
        text: (item: KOSRowData) =>
          formatPrice(
            item.totalNet + (item.adjustmentAmount ?? 0),
            localeData[item.country]
          ),
      },
      {
        label: t(commons.ORDER_DATE),
        type: "date",
        align: "center",
        field: "dateDocument",
      },
      {
        label: t(purchaseOrderCreation.RECEIPT_DATE_LABEL),
        type: "date",
        align: "center",
        field: "receiptDate",
      },
      {
        label: t(commons.WITH_INVOICE),
        align: "center",
        component: (item: KOSRowData) => withInvoice(item as Purchase),
      },
      {
        label: t(commons.STATUS),
        align: "center",
        component: (item: KOSRowData) => statusChip(item as Purchase),
      },
      {
        label: t(commons.ACTIONS),
        align: "center",
        component: (item: KOSRowData) => detailButton(item),
      },
    ];
  }, []);

  return (
    <Box>
      <KOSBaseTable
        columns={getHeader()}
        rows={{
          data: items,
          total: items.length ?? 0,
          loading: !!loading,
        }}
        pagination={{ enable: !!canPagination }}
        selectable={{
          resultHeader: [
            "country",
            "orderId",
            "code",
            "kitchenId",
            "vendorName",
            "status",
          ],
          items: selectedItems,
          onChange: setSelectedItems,
          canSelectAll: true,
        }}
        emptyState={
          <KOSEmptyState
            icon={InfoOutlinedIcon}
            message={t(commons.TABLE_EMPTY)}
          />
        }
      />

      {selectedPurchase && (
        <FullDialog
          open={openDetail}
          onClose={() => setOpenDetail(false)}
          title={t(purchaseOrderLine.TITLE)}
          content={
            <PurchaseOrderAccountDetail
              kitchenId={selectedPurchase.kitchenId}
              orderId={selectedPurchase.orderId}
              onUpdate={onUpdate}
            />
          }
        />
      )}
    </Box>
  );
};

export default PurchaseOrderAcountTable;
