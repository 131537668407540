export const measureUnitsConvert = (
  amount: number,
  fromUnit: string,
  toUnit: string
): number => {
  if (fromUnit === toUnit) {
    return amount;
  }

  const measureUnitsFactors: { [name: string]: string } = {
    g: "weight",
    gr: "weight",
    kg: "weight",
    l: "volume",
    ml: "volume",
    uni: "unit",
    un: "unit",
  };

  const conversionFactors: { [name: string]: { [name: string]: number } } = {
    weight: {
      kg: 1000,
      g: 1,
      gr: 1,
    },
    volume: {
      l: 1000,
      ml: 1,
    },
    unit: {
      uni: 1,
      un: 1,
    },
  };

  // get conversion factor for the given units
  const fromConversionFactor =
    conversionFactors[measureUnitsFactors[fromUnit.toLowerCase()]][
      fromUnit.toLowerCase()
    ];

  const toConversionFactor =
    conversionFactors[measureUnitsFactors[toUnit.toLowerCase()]][
      toUnit.toLowerCase()
    ];
  // convert the amount
  return (amount * fromConversionFactor) / toConversionFactor;
};

export const getSupplyQuantity = (
  quantityToProduce: number | null,
  supplyQuantity: number
): number => {
  if (quantityToProduce === null || quantityToProduce === 0) {
    return 0;
  }

  const total = quantityToProduce * supplyQuantity;

  return Math.ceil(total);
};

export enum VendorMeasureUnit {
  KG = "KG",
  PZ = "PZ",
  BOX = "BOX",
  BAG = "BAG",
  BUCKET = "BUCKET",
  CAN = "CAN",
  GALLON = "GALLON",
  PACKAGE = "PACKAGE",
  DRUM = "DRUM",
  TIN = "TIN",
  ML = "ML",
  LITER = "LITER",
  BAG_SMALL = "BAG_SMALL",
  UN = "UN",
  BED = "BED",
  THOUSAND = "THOUSAND",
  G = "G",
  ROLL = "ROLL",
  JUG = "JUG",
  JAR = "JAR",
  SACK = "SACK",
  BOTTLE = "BOTTLE",
  CRATE = "CRATE",
  DOME = "DOME",
  COVER = "COVER",
  TETRAPACK = "TETRAPACK",
  ATADO = "ATADO",
}

export type VendorMeasureUnitType = `${VendorMeasureUnit}`;
