import { CancelOutlined, TaskAlt } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { MultipleFieldWithChips } from "app/components/common/Field/Multiple";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAlert } from "app/hooks/useAlert";
import { commons, vendor } from "app/i18n/types";
import { DocumentType } from "core/document/entities/DocumentType";
import { PaymentMethod } from "core/payment/entities/PaymentMethod";
import { PayDayEnum, Vendor } from "core/vendors/entities/Vendor";
import { create, updateById } from "core/vendors/repositories/http/vendor";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactInputMask from "react-input-mask";
import { Actions } from "utils/modal";
import { ValidationError } from "yup";
import { BaseDialogProps, FormState } from "./constants";
import {
  getDocumentChange,
  getRefinedMask,
  handleValidations,
} from "./formValidations";

interface Props extends BaseDialogProps {
  paymentMethods: PaymentMethod[];
  documentTypes: DocumentType[];
}

export const HandleForm = (props: Props) => {
  const {
    item: initialValues,
    onClose,
    onSuccess,
    country,
    paymentMethods,
    documentTypes,
  } = props;
  const { t } = useTranslation();
  const alert = useAlert();

  const toFormState = (): FormState => ({
    id: initialValues?.id ?? 0,
    name: initialValues?.name ?? "",
    address: initialValues?.address ?? "",
    phone: initialValues?.phone ?? "",
    externalCode: initialValues?.externalCode ?? `${t(commons.AUTOGENERATE)}`,
    paymentDays: initialValues?.paymentDays ?? 0,
    email: initialValues?.email ?? "",
    purchaseEmail: initialValues?.purchaseEmail ?? "",
    document: initialValues?.document ?? "",
    paymentMethod: initialValues?.paymentMethod ?? "",
    documentType: initialValues?.documentType ?? "",
    country,
  });

  const [formState, setFormState] = useState<FormState>(toFormState());

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [purchaseEmails, setPurchaseEmails] = useState<string[]>([]);
  const [documentType, setDocumentType] = useState<DocumentType>();

  const handleValidator = handleValidations(documentType);

  useEffect(() => {
    if (initialValues?.purchaseEmail) {
      setPurchaseEmails(initialValues.purchaseEmail.split(";"));
    }
    if (initialValues?.documentType) {
      setDocumentType(
        documentTypes.find((el) => el.code === initialValues.documentType)
      );
    }
    setFormState(toFormState());
  }, [initialValues]);

  useEffect(() => {
    setErrors({});
    handleValidator
      .validate({ ...formState }, { abortEarly: false })
      .catch((error: ValidationError) => {
        let modifiedErrors: Record<string, boolean> = {};
        error.inner.forEach((errorDetail) => {
          if (errorDetail.path) modifiedErrors[errorDetail.path] = true;
        });
        setErrors(modifiedErrors);
      });
  }, [formState]);

  const getResponse = async (data: FormState) => {
    if (props.action === Actions.edit) {
      return await updateById(data.id, data as Vendor);
    }

    return await create(data as Vendor);
  };

  const handleSubmit = useCallback(async () => {
    if (!handleValidator.isValidSync(formState)) {
      return;
    }
    setIsLoading(true);
    const response = await getResponse(formState);
    const title = t(`common.${response.message}`);
    if (response.ok) {
      alert.successWithMsg({ title });
      onSuccess({
        ...formState,
        externalCode: response.data?.code ?? formState.externalCode,
        id: response.data?.id ?? formState.id,
      } as Vendor);
      onClose();
    } else {
      alert.errorWithMsg({ title });
    }
    setIsLoading(false);
  }, [formState, props.action]);

  const handleIcon = (isError: boolean) => {
    if (isError) {
      return <CancelOutlined color="warning" />;
    }

    return <TaskAlt color="success" />;
  };

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      purchaseEmail: purchaseEmails.join(";"),
    }));
  }, [purchaseEmails]);

  const disableInputs = props.action === Actions.view;
  const refinedMask = getRefinedMask(documentType);

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t(commons.CODE)}</InputLabel>
          <OutlinedInput
            type="text"
            label={t(commons.CODE)}
            value={formState.externalCode}
            disabled
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{t(vendor.PAYMENT_METHOD)}</InputLabel>
          <Select
            label={t(vendor.PAYMENT_METHOD)}
            value={formState.paymentMethod}
            endAdornment={
              <>{!disableInputs && handleIcon(errors["paymentMethod"])}</>
            }
            onChange={(event: SelectChangeEvent<string>) => {
              const value = event.target.value;
              setFormState((prev) => ({
                ...prev,
                paymentMethod: value,
              }));
            }}
            disabled={disableInputs}
          >
            {paymentMethods.map((option) => (
              <MenuItem
                key={`payment-method-${option.code}`}
                value={option.code}
              >
                {option.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{t(vendor.DOCUMENT_TYPE)}</InputLabel>
          <Select
            label={t(vendor.DOCUMENT_TYPE)}
            value={formState.documentType}
            endAdornment={
              <>{!disableInputs && handleIcon(errors["documentType"])}</>
            }
            onChange={(event: SelectChangeEvent<string>) => {
              const value = event.target.value;
              setDocumentType(documentTypes.find((el) => el.code === value));
              setFormState((prev) => ({
                ...prev,
                documentType: value,
              }));
            }}
            disabled={disableInputs}
          >
            {documentTypes.map((option) => (
              <MenuItem
                key={`document-type-${option.code}`}
                value={option.code}
              >
                {option.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          {!!documentType?.mask && (
            <ReactInputMask
              mask={refinedMask}
              value={formState.document}
              onChange={(event) => {
                const newValue = event.target.value;
                setFormState((prev) => ({
                  ...prev,
                  document: newValue,
                }));
              }}
              maskChar="_"
              disabled={disableInputs}
            >
              {/* @ts-ignore */}
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  label={t(commons.DOCUMENT)}
                  variant="outlined"
                  placeholder={documentType.mask}
                  InputProps={{
                    endAdornment: (
                      <>{!disableInputs && handleIcon(errors["document"])}</>
                    ),
                  }}
                  disabled={disableInputs}
                />
              )}
            </ReactInputMask>
          )}
          {!documentType?.mask && (
            <>
              <InputLabel>{t(commons.DOCUMENT)}</InputLabel>
              <OutlinedInput
                type="text"
                label={t(commons.DOCUMENT)}
                value={formState.document}
                endAdornment={
                  <>{!disableInputs && handleIcon(errors["document"])}</>
                }
                onChange={(event) => {
                  const newValue = event.target.value;
                  if (getDocumentChange(documentType, newValue)) {
                    setFormState((prev) => ({
                      ...prev,
                      document: newValue,
                    }));
                  }
                }}
                disabled={disableInputs}
              />
            </>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t(commons.NAME)}</InputLabel>
          <OutlinedInput
            type="text"
            label={t(commons.NAME)}
            value={formState.name}
            endAdornment={<>{!disableInputs && handleIcon(errors["name"])}</>}
            onChange={(event) => {
              setFormState((prev) => ({
                ...prev,
                name: event.target.value,
              }));
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t(commons.ADDRESS)}</InputLabel>
          <OutlinedInput
            type="text"
            label={t(commons.ADDRESS)}
            value={formState.address}
            endAdornment={
              <>{!disableInputs && handleIcon(errors["address"])}</>
            }
            onChange={(event) => {
              setFormState((prev) => ({
                ...prev,
                address: event.target.value,
              }));
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t(commons.PHONE)}</InputLabel>
          <OutlinedInput
            type="text"
            label={t(commons.PHONE)}
            value={formState.phone}
            endAdornment={<>{!disableInputs && handleIcon(errors["phone"])}</>}
            onChange={(event) => {
              const newValue = event.target.value;
              if (/^\d*$/.test(newValue)) {
                setFormState((prev) => ({
                  ...prev,
                  phone: event.target.value,
                }));
              }
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{t(vendor.PAYMENT_DAYS)}</InputLabel>
          <Select
            label={t(vendor.PAYMENT_DAYS)}
            value={`${formState.paymentDays}D`}
            endAdornment={
              <>{!disableInputs && handleIcon(errors["paymentDays"])}</>
            }
            onChange={(event: SelectChangeEvent<string>) => {
              const value = event.target.value;
              setFormState((prev) => ({
                ...prev,
                paymentDays: Number(value.replace("D", "")),
              }));
            }}
            disabled={disableInputs}
          >
            {Object.keys(PayDayEnum).map((day) => (
              <MenuItem key={`pay-day-${day}`} value={day}>
                {day.replace("D", "")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>{t(vendor.ACCOUNTING_EMAIL)}</InputLabel>
          <OutlinedInput
            type="text"
            label={t(vendor.ACCOUNTING_EMAIL)}
            value={formState.email}
            endAdornment={<>{!disableInputs && handleIcon(errors["email"])}</>}
            onChange={(event) => {
              setFormState((prev) => ({
                ...prev,
                email: event.target.value,
              }));
            }}
            disabled={disableInputs}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        {!disableInputs && (
          <MultipleFieldWithChips
            label={vendor.PURCHASE_EMAIL}
            items={purchaseEmails}
            setItems={setPurchaseEmails}
            endAdornment={
              <>{!disableInputs && handleIcon(errors["purchaseEmail"])}</>
            }
          />
        )}

        {disableInputs && (
          <>
            <Typography variant="body1">{t(vendor.PURCHASE_EMAIL)}</Typography>
            <ul>
              {purchaseEmails.map((email) => (
                <li key={email}>{email}</li>
              ))}
              {!purchaseEmails.length && <li>{t(commons.TABLE_EMPTY)}</li>}
            </ul>
          </>
        )}
      </Grid>

      {props.action !== Actions.view && (
        <Grid item xs={12}>
          <ManagerDialogFooter
            onCancel={props.onClose}
            mainButton={{
              children: t(commons.SAVE),
              onClick: handleSubmit,
              disabled: isLoading || !!Object.keys(errors).length,
            }}
            loading={isLoading}
          />
        </Grid>
      )}
    </Grid>
  );
};
