import { commons } from "app/i18n/types";
import {
  DocumentType,
  TypeOfDocument,
} from "core/document/entities/DocumentType";
import { number, object, string } from "yup";

export const handleValidations = (documentType: DocumentType | undefined) => {
  let document = string().required(commons.REQUIRED_FIELD);

  const patterns = generateRegex(documentType);
  if (patterns.length) {
    document = document.test((value) => {
      return patterns.some((pattern) => pattern.test(value));
    });
  }

  return object({
    purchaseEmail: string().required(commons.REQUIRED_FIELD),
    email: string().required(commons.REQUIRED_FIELD),
    paymentMethod: string().required(commons.REQUIRED_FIELD),
    documentType: string().required(commons.REQUIRED_FIELD),
    paymentDays: number()
      .min(1)
      .typeError(commons.QUANTITY_ERROR)
      .required(commons.REQUIRED_FIELD),
    name: string().required(commons.REQUIRED_FIELD),
    address: string().required(commons.REQUIRED_FIELD),
    phone: string().required(commons.REQUIRED_FIELD),
    document,
  });
};

export const getRefinedMask = (
  documentType: DocumentType | undefined
): string => {
  if (!documentType?.mask) return "";
  if (documentType.type === TypeOfDocument.NUMERIC) {
    return documentType.mask.replace(/_/g, "9");
  }
  if (documentType.type === TypeOfDocument.ALPHABETIC) {
    return documentType.mask.replace(/_/g, "A");
  }
  return documentType.mask.replace(/_/g, "*");
};

export const getDocumentChangeLengthValidator = (
  documentType: DocumentType | undefined,
  value: string
): boolean => {
  const minLength = documentType?.minLength ?? -1;
  const maxLength = documentType?.maxLength ?? -1;
  const valueLength = value.length;
  if (minLength > 0 && maxLength > 0) {
    return valueLength <= minLength || valueLength <= maxLength;
  }
  return true;
};

export const getDocumentChange = (
  documentType: DocumentType | undefined,
  value: string
): boolean => {
  const lengthValidation = getDocumentChangeLengthValidator(
    documentType,
    value
  );
  const isDigit = /^\d*$/.test(value);
  if (documentType?.type === TypeOfDocument.NUMERIC) {
    return isDigit && lengthValidation;
  }
  if (documentType?.type === TypeOfDocument.ALPHABETIC) {
    return !isDigit && lengthValidation;
  }
  return lengthValidation;
};

export const generateRegexLength = (documentType: DocumentType | undefined) => {
  if (!documentType) return "";
  const { minLength, maxLength } = documentType;
  if (minLength && maxLength) {
    return `{${minLength},${maxLength}}`;
  }
  if (minLength) {
    return `{${minLength}}`;
  }
  if (maxLength) {
    return `{${maxLength}}`;
  }
  return "";
};

export const generateRegexNumericPattern = (
  documentType: DocumentType | undefined
): string[] => {
  if (!documentType) return [];
  const { mask } = documentType;
  return [
    mask?.replace(/_/g, "\\d") ?? `[0-9]`,
    mask?.replace(/_/g, "\\d").replace(/\./g, "") ?? `[0-9]`,
  ];
};

export const generateRegex = (
  documentType: DocumentType | undefined
): RegExp[] => {
  if (!documentType) return [];
  const { type } = documentType;
  const length = generateRegexLength(documentType);
  if (type === TypeOfDocument.NUMERIC) {
    const patterns = generateRegexNumericPattern(documentType);
    return patterns.map((el) => new RegExp(`^${el}${length}$`));
  }
  if (type === TypeOfDocument.ALPHANUMERIC) {
    return [new RegExp(`^[a-zA-Z0-9]${length}$`)];
  }
  return [];
};
