import { KOSBaseTableHeader } from "@foodology-co/alejandria";
import { commons, replacementModule } from "app/i18n/types";
import { TFunction } from "i18next";

export const getCommonHeader = (t: TFunction): KOSBaseTableHeader[] => [
  {
    label: t(commons.SKU),
    field: "sku",
    align: "center",
  },
  {
    label: t(commons.PRODUCT_NAME),
    field: "name",
  },
  {
    label: t(commons.QUANTITY),
    text: (item) => `${item.quantity} ${item.unit}`,
  },
  {
    label: `${t(commons.SKU)} (${t(replacementModule.REPLACE)})`,
    field: "replaceSku",
    align: "center",
  },
  {
    label: `${t(commons.PRODUCT_NAME)} (${t(replacementModule.REPLACE)})`,
    field: "replaceName",
  },
  {
    label: `${t(commons.QUANTITY)} (${t(replacementModule.REPLACE)})`,
    text: (item) => `${item.replaceQuantity} ${item.replaceUnit}`,
  },
];

export const resultHeader = [
  "id",
  "sku",
  "name",
  "unit",
  "quantity",
  "replaceSku",
  "replaceName",
  "replaceUnit",
  "replaceQuantity",
];
